.announcement-banner {
  display: block;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  z-index: 10;

  &--blue {
    background-color: @navy;
    color: @white;
  }
  &--red {
    background: #ce0e2d;
    color: @white;
  }
  &--beige {
    background:#E9E9E9;
    color:  @navy;
  }

  &:hover {
    .announcement-banner__text {
      svg {
        transform: translateX(10px);
      }
    }
  }
  &:focus-visible {
    .announcement-banner__text {
      svg {
        transform: translateX(10px);
      }
    }
  }

  @media (min-width: @sm) {
    padding: 0.7rem 0;
  }
}

.announcement-banner__slide {
  width: 100%;

  // Make it so it only shows the first slide on load
  &:not(:first-child) {
    position: absolute;
    opacity: 0;
    .flickity-enabled & {
      opacity: 1;
    }
  }

  // Slide height fix
  .flickity-resize & {
    min-height: 100%;
    display: flex;
    align-items: center;
  }
}

.announcement-banner__text {
  font-family: @scriptSecondaryFont;
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.072rem;
  line-height: 1.5;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 0.8rem;

  svg {
    display: inline-block;
    height: 0.772rem;
    width: 1.034rem;
    margin-left: 0.3rem;
    transition: transform 0.3s ease;

    path {
      stroke: @white;
    }
  }

  .announcement-banner--beige & {
    svg path {
      stroke: @navy;
    }
  }

  @media (min-width: @sm) {
    svg {
      height: 0.805rem;
      width: 1.451rem;
    }
  }
}

// Flickity controls
.announcement-banner__controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}

// Individual buttons
.announcement-banner__control {
  .btn-reset();
  border-radius: 0.4rem;
  z-index: 1;

  width: 1.4rem;
  height: 100%;

  svg {
    height: 1.8rem;
    aspect-ratio: 0.5;
    path {
      stroke: @white;
    }
  }

  &:focus-visible {
    outline: 1px solid @white;
  }

  .announcement-banner--beige & {
    &:focus-visible {
      outline: 1px solid @navy;
    }
    svg path {
      stroke: @navy;
    }
  }
}
