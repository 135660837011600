.product-list {
  padding-top: 5rem;
  text-align: center;

  @media screen and (min-width: @sm) {
    padding-top: 6rem;
  }

  .flickity-page-dots {
    display: none;
  }
}

.product-list__grid {
  list-style: none;
  margin: 0;
  padding: 0 var(--pagePadding);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.4rem;
  text-align: left;

  @media screen and (min-width: @sm) {
    border-top: 1px solid #e3e3e3;
    padding: 0;
    margin: 0;
  }

  &--slider {
    padding: 0;
    display: block;
    margin: 0;

    @media screen and (min-width: @sm) {
      display: flex;
      flex-wrap: wrap;
    }
    &::after {
      content: 'flickity';
      display: none;

      @media screen and (min-width: @sm) {
        content: '';
      }
    }
  }
}

.product-list__grid-item {
  width: calc(100% / 3);
  padding: 0 .4rem;
  margin-bottom: 3.8rem;

  @media screen and (min-width: @sm) {
    width: 50%;
    height: 50vw;
    border-right: 1px solid #E3E3E3;
    border-bottom: 1px solid #e3e3e3;
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: @md) {
    width: 25%;
    height: 25vw;
  }

  &:nth-of-type(2n) {
    @media screen and (min-width: @sm) and (max-width: @md-neg) {
      border-right: none;
    }
  }

  &:nth-of-type(4n) {
    @media screen and (min-width: @md) {
        border-right: none;
      }
  }

  .product-list__grid--slider & {
    width: 30%;
    padding: 0 .4rem;
    margin: 0;

    @media screen and (min-width: @sm) {
      width: 50%;
      height: 50%;
      padding: 0;
    }

    @media screen and (min-width: @md) {
      width: 25%;
      height: 25vw;
    }

    &:first-child {
        @media screen and (max-width: @sm-neg) {
          padding-left: 2.4rem;
          width: calc(30% + 2rem);
        }
      }

      &:last-child {
        @media screen and (max-width: @sm-neg) {
          padding-right: 2.4rem;
          width: calc(30% + 2rem);
        }
      }
  }
}

.product-list__title {
  text-align: center;
  color: @navy;
  font-family: @scriptPrimaryFont;
  font-size: 2.4rem;
  letter-spacing: -0.01em;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.333;
  text-transform: none;
  margin-bottom: 1.6rem;

  @media screen and (min-width: @sm) {
    font-size: 3.2rem;
    line-height: 1.25;
    margin-bottom: 3.5rem;
  }

  .product-list--title-sm & {
    .h6;
    margin-bottom: 2.4rem;
  }
}

.product-list__grid .product-card {
  @media screen and (max-width: @sm-neg) {
    height: auto;

    .product-card__btns {
      display: none;
    }

    .product-card__details {
      position: static;
      width: 100%;
      padding: 0;
    }

    .product-card__price {
      display: none;
    }

    .product-card__title {
      font-size: 1rem;
    }

    .product-card__img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .product-card__images {
      border: 1px solid #E3E3E3;
      border-radius: .4rem;
      overflow: hidden;
      margin-bottom: 1.2rem;
      padding-bottom: 100%;
      position: relative;
    }

    .product-card__badge {
      display: none;
    }
  }

  @media screen and (min-width: @sm) {
    .product-card__details {
      width: 65%;
    }
  }
}

.product-list__btn {
  margin-top: 2rem;

  @media screen and (min-width: @sm) {
    margin-top: 4rem;
  }
}
