.share-btn {
  display: flex;
  align-items: center;
}

.share-btn__btn {
  color: @chocolate;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: .1rem;
  transition: text-decoration .2s ease;

  @media(hover: hover) and (pointer: fine) {
  
    &:hover {
      text-decoration-color: currentColor;
    }

    &:focus-visible {
      text-decoration-color: currentColor;
    }
  }

  svg {
    display: block;
    width: 2.2rem;
    height: auto;
    fill: currentColor;
    margin-left: .6rem;
    display: block;
  }
}

.share-btn__list {
  list-style: none;
  margin: 0 0 0 2.2rem;
  padding: 0;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.share-btn__item {
  & + & {
    margin-left: 2rem;
  }
}

.share-btn__link {

  svg {
    height: 1.5rem;
    width: auto;
    display: block;
    transform: none;
    transition: transform .2s ease;
  }

  @media(hover: hover) and (pointer: fine) {

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    &:focus-visible {
      svg {
        transform: scale(1.1);
      }
    }
  }
  
}