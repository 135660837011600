.slider-logos {
    text-align: center;

    .flickity-page-dots {
        display: none;
    }
}

.slider-logos__title {
    .h5;
    margin-bottom: 2rem;

    @media screen and (min-width: @sm) {
        margin-bottom: 4.8rem;
    }
}

.slider-logos__slider {
    margin: 0 -0.8rem;

    @media screen and (min-width: @sm) {
        margin: 0 -1.5rem;
    }
}

.slider-logos__slide {
    width: calc(100% / 3);
    padding: 0 0.8rem;

    @media screen and (min-width: @sm) {
        width: calc(100% / 4);
        padding: 0 1.5rem;
    }

    @media screen and (min-width: @md) {
        width: calc(100% / 5);
    }

    @media screen and (min-width: @lg) {
        width: calc(100% / 6);
    }
}

.slider-logos__slide-link {
    display: block;
    position: relative;

    &:before {
        content: '';
        padding-bottom: 60%;
        display: block;
    }
    @media(hover: hover) and (pointer: fine) {

        &:hover {
            .slider-logos__logo {
                transform: scale(1.1);
            }
        }

        &:focus-visible {
            .slider-logos__logo {
                transform: scale(1.1);
            }
        }
    }
}

.slider-logos__logo {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    transform: none;
    transition: transform .2s ease;

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
        object-position: center;
    }
}

.slider-logos__btn {
    margin-top: 3rem;

    @media screen and (min-width: @sm) {
        margin-top: 5rem;
    }
}

.slider-logos__nav-btn {
    opacity: .5;
    width: .8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    transition: opacity .2s ease;

    @media screen and (min-width: @sm) {
        opacity: .4;
        width: 1.4rem;
    }
    
    svg {
        display: block;
        stroke: @slate;
        width: 100%;
        height: auto;
    }

    @media(hover: hover) and (pointer: fine) {

        &:hover {
            opacity: 1;
        }
        
        &:focus-visible {
            opacity: 1;
        }
    }

    &--prev {
        left: -1.8rem;

        @media screen and (min-width: 1360px) {
            left: -5.2rem;
        }
    }

    &--next {
        right: -1.8rem;

        @media screen and (min-width: 1360px) {
            right: -5.2rem;
        }
    }
}