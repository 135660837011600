input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000000s ease-in-out 0s;
}

.form-group .text-help {
  color: @error;
}

.pristine-error {
  display: table;
  font-size: 1.3rem;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding-top: 2px;
}

// Used for the password input eye toggle icon
.password-toggle {
  background-size: 24px 15px;
  background-position: center;
  background-repeat: no-repeat;

  background-image: url('../../img/icons/eye-show-dark.svg');
  &--hide {
    background-image: url('../../img/icons/eye-hide-dark.svg');
  }
}

.form__grid {
	display: grid;

	&:not(:first-child) {
		margin-top: 3.2rem;
	}

	@media (min-width: @sm) {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 2.2rem;
	}

	@media (min-width: @md) {
		grid-column-gap: 4.4rem;
	}

	& .form__input-full {

		@media (min-width: @sm) {
			grid-column: 1/-1;
		}
	}
}

.form--dark {
	input:not([type="checkbox"]):not([type="radio"]):not(.numInput),
	textarea,
	.input__select {
		color: @white;
		border-bottom: 1px solid @raffi-grey;
		background-color: rgba(255, 255, 255, 0.1);

		&::-webkit-input-placeholder { /* Edge */
			color: @white;
			opacity: 1;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: @white;
			opacity: 1;
		}

		&::placeholder {
			color: @white;
			opacity: 1;
		}
	}

	.input__select {
		border: none;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.919' height='7.88' viewBox='0 0 14.919 7.88'%3E%3Cpath id='Path_879' data-name='Path 879' d='M110.31,2047.935l7.113,6.826,7.113-6.826' transform='translate(-109.964 -2047.575)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E ");
	}

	input[type="checkbox"] {
		background-color: rgba(255, 255, 255, 0.1);
		background-repeat: no-repeat;
		background-size: 1.6116rem 1.3937rem;
		background-position: center;
	}

	input[type="checkbox"]:checked {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.116' height='13.937' viewBox='0 0 16.116 13.937'%3E%3Cpath id='Path_874' data-name='Path 874' d='M-7116.7-6094.073l4.641,5.656,9.939-11.729' transform='translate(7117.474 6100.792)' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E ");
	}

	.form__input.has-danger {

		input:not(.numInput),
		textarea,
		.input__select {
			border-color: @error;
			color: @error;

			&::-webkit-input-placeholder {
				color: @error;
			}

			&:-ms-input-placeholder {
				color: @error;
			}

			&::placeholder {
				color: @error;
			}
		}
	}

	.pristine-error {
		color: @error;
	}

	& .errors-wrapper ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		color: @error;
	}

	.form__input.has-success {

		input:not(.numInput),
		textarea,
		.input__select {
			background-color: rgba(255, 255, 255, 0.05);
		}

		input:not(.input__date):not([type="checkbox"]):not(.numInput) {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.617' height='8.79' viewBox='0 0 9.617 8.79'%3E%3Cg id='Group_3309' data-name='Group 3309' transform='translate(0.54 0.458)'%3E%3Cpath id='Path_203' data-name='Path 203' d='M2488.761-2060.245l2.931,3.043,5.553-7.186' transform='translate(-2488.761 2064.388)' fill='none' stroke='%23172045' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: 0.9617rem 0.879rem;
			background-position: center right 1.7rem;
		}
	}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			color: @white;
			-webkit-text-fill-color: @white;
		}
}

.form--style-dark {
	color: @white;
}

.form--light {
	input:not([type="checkbox"]):not([type="radio"]),
	textarea,
	.input__select {
		color: @navy;
		border-bottom: 1px solid @navy;
		background-color: transparent;

		&::-webkit-input-placeholder { /* Edge */
			color: @navy;
			opacity: 1;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: @navy;
			opacity: 1;
		}

		&::placeholder {
			color: @navy;
			opacity: 1;
		}
	}

	.form__input.has-danger input {
		border-color: @burgundy;
	}

	.pristine-error {
		color: @burgundy;
	}
}

.form--newsletter {
	input {
		color: @slate;
		border-bottom: 1px solid @slate !important;
		background-color: transparent;
		padding: 1.2rem 0 1.2rem 0 !important;

		&::-webkit-input-placeholder { /* Edge */
			color: @slate;
			opacity: 1;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: @slate;
			opacity: 1;
		}

		&::placeholder {
			color: @slate;
			opacity: 1;
		}
	}

	.form__submit-arrow {
		position: absolute;
		right: 0;
		top: 1.6rem;

		svg {
			display: block;
			height: 1.2rem;
			width: 1.637rem;
		}
	}

	.form__input--results {
		margin: -1.5rem 0;

		@media (min-width: @sm) {
			margin: -2.5rem 0 -1.5rem;
		}
	}

	.form__input.has-danger {

		input {
			border-color: @burgundy !important;
			color: @burgundy;

			&::-webkit-input-placeholder {
				color: @burgundy;
			}

			&:-ms-input-placeholder {
				color: @burgundy;
			}

			&::placeholder {
				color: @burgundy;
			}
		}
	}

	& .errors-wrapper ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		color: @burgundy;
	}

	.pristine-error {
		color: @burgundy;
	}

	&.form--dark{
		input {
			color: @white!important;
			border-color: @white!important;
			background: transparent!important;

			&::-webkit-input-placeholder {
					/* Edge */
					color: @white!important;
					opacity: 1;
				}

				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: @white!important;
					opacity: 1;
				}

				&::placeholder {
					color: @white!important;
					opacity: 1;
				}
		}

		.form__input.has-danger input {
			border-color: @error!important;
			color: @white;
		}

		.pristine-error {
			color: @error;
		}
	}
}

.form label {
	display: block;
	margin-bottom: 0.7rem;

	@media (min-width: @sm) {
		margin-bottom: 0.4rem;
	}
}

.form__input {
	position: relative;
	margin-bottom: 2.2rem;

	@media (min-width: @sm) {
		margin-bottom: 3.5rem;
	}
}

.form__input--split {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1.8rem;
	margin-bottom: 0 !important;
}

.form .form__input {
	margin-bottom: 2.6rem;

	@media (min-width: @sm) {
		margin-bottom: 3.6rem;
	}
}

.form,
.form--newsletter {
	input:not([type="checkbox"]):not([type="radio"]):not(.numInput),
		textarea,
		.input__select {
			appearance: none;
			-webkit-appearance: none;
			border-radius: 0;
			border: 0;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			font-size: 1.6rem;
			padding: 1.88rem 1.6rem;
			resize: none;
			width: 100%;
			text-overflow: ellipsis;

			@media (min-width: @sm) {
				font-size: 1.5rem;
				line-height: 1.6;
				letter-spacing: normal;
				padding: 1.6rem 2.1rem;
			}
		}
}

.form {
	input.input__date {
			border-radius: 4px;
			border: none !important;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_1836' data-name='Rectangle 1836' width='23.993' height='24' fill='%23cbaa85'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_3312' data-name='Group 3312' transform='translate(-864 -1504)'%3E%3Crect id='Rectangle_1837' data-name='Rectangle 1837' width='40' height='40' rx='4' transform='translate(864 1504)' fill='%23172045'/%3E%3Cg id='Group_3311' data-name='Group 3311' transform='translate(872.008 1512)'%3E%3Cg id='Group_3310' data-name='Group 3310' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_878' data-name='Path 878' d='M6.432,0a.545.545,0,0,0-.486.554V2.148H3.05A3.065,3.065,0,0,0,0,5.2V8.469c0,.014,0,.029,0,.043V20.95A3.064,3.064,0,0,0,3.05,24h17.9a3.057,3.057,0,0,0,3.042-3.05V8.546h0c0-.011,0-.023,0-.034a.527.527,0,0,0,0-.06V5.2a3.057,3.057,0,0,0-3.042-3.05h-2.99V.554h0a.545.545,0,1,0-1.09,0V2.148H7.038V.554h0A.545.545,0,0,0,6.484,0H6.432ZM3.05,3.238h2.9V4.831a.545.545,0,1,0,1.09,0V3.238h9.832V4.831h0a.545.545,0,1,0,1.09,0V3.238h2.99A1.957,1.957,0,0,1,22.9,5.2V7.966H1.091V5.2a1.964,1.964,0,0,1,1.96-1.96ZM1.091,9.057H22.9V20.95a1.958,1.958,0,0,1-1.951,1.96H3.05a1.965,1.965,0,0,1-1.96-1.96Z' transform='translate(0)' fill='%23cbaa85' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			background-size: 4rem;
			background-position: center right 0.9rem;
			padding: 1.6rem 5.3rem 1.6rem 1.5rem !important;

			@media (max-width: @sm-neg) {
				height: 5.599rem;
			}
		}
}

.input__select {
	border-radius: 4px;
	background-size: 1.4919rem 0.788rem;
	background-position: center right 1.5rem;
	background-repeat: no-repeat;
	padding-right: 4rem;
}

.form {
	input[type="checkbox"] {
			appearance: none;
			-webkit-appearance: none;
			height: 2.4rem;
			width: 2.4rem;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;

			&+label {
				padding-left: 3.7rem;
				margin: 0;

				& a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}

					&:focus-visible {
						text-decoration: none;
					}
				}
			}

			&~.pristine-error {
				padding-left: 3.7rem;
			}
		}
}

.input--lg {
	font-size: 1.6rem;
	letter-spacing: -0.019rem;
	padding: 1rem 0;

	@media (min-width: @sm) {
		font-size: 1.9rem;
		padding: 0.5rem 0;
	}
}

.form__legend {
	opacity: 0.5;
	padding-left: 1.3rem;
}

.form__eyebrow {
	margin-bottom: 1.6rem;
}

.form {
	button[type="submit"] {
			&:disabled {
				background-color: @light-gold;
				border-color: @light-gold;
				cursor: default;
			}
		}
}

.form__disclaimer {
	font-size: 1.1rem;
	letter-spacing: 0;
	font-weight: 400;

	a {
		text-decoration: underline;
		transition: text-decoration .2s ease;
		@media(hover: hover) and (pointer: fine) {

			&:hover {
				text-decoration-color: transparent;
			}

			&:focus-visible {
				text-decoration-color: transparent;
			}
		}
	}
}

// Fillout seems to add 3rem to either side, so this counteracts it
// NOTE: The Fillout forms need to have their styles inputted in Fillout itself,
// so I've created a fillout.scss (SASS) file that you can edit and compile (https://jsonformatter.org/scss-to-css)
// and then paste the compiled CSS into the Fillout website
.fillout-embed-iframe-container {
	width: calc(100% + 6rem) !important;
	margin-left: -3rem !important;
}

.fillout__form-wrapper {
  width: 100%;

  @media screen and (min-width: @md) {
    max-width: 76.8rem;
    margin: 0 auto;
  }
}
