.hero-slider {
  position: relative;
  background: @light-sand;

  .flickity-page-dots {
    justify-content: flex-start;
    bottom: 2.7rem;
    left: 0;
    padding: 0 var(--pagePadding-lg);
    // width: 100%;
    // max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

    @media screen and (min-width: @sm) {
      flex-direction: column;
      padding: 0;
      left: auto;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      right: var(--pagePadding);
      width: auto;
    }
  }

  .flickity-page-dot {
    background: @chocolate;
    opacity: 0.3;
    margin: 0;
    width: 0.8rem;
    height: 0.8rem;
    transition: opacity 0.2s ease;

    @media screen and (min-width: @sm) {
      background: @white;
    }

    & + .flickity-page-dot {
      margin-left: 0.8rem;

      @media screen and (min-width: @sm) {
        margin-left: 0;
        margin-top: 0.8rem;
      }
    }

    &.is-selected {
      opacity: 1;
    }
  }
}

// The div/a tag that wraps the picture/image element
.hero-slider__bkg {
  background-color: @slate;
  order: -1;
  position: relative;

  @media screen and (min-width: @sm) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: 81%;
    [data-aspect-ratio="square"] & {
      padding-bottom: 100%;
    }

    @media screen and (min-width: @sm) {
      display: none;
    }
  }

  // The img tag itself
  .hero-slider__bkg-img {
    object-fit: cover;
    object-position: center;

    // Make it fill the container
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hero-slider__slide--products & {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent
        linear-gradient(2deg, rgba(36, 36, 36, 0.65) 0%, rgba(0, 0, 0, 0) 100%)
        0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
    }
  }
}

.hero-slider__slide {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: @sm) {
    height: calc(100vh - var(--header-height));
    min-height: 62.5rem;
    align-items: center;
    justify-content: center;
  }

  &--light-text {
    @media screen and (min-width: @sm) {
      color: @white;
      background: @navy;
    }
  }

  &--products {
    @media screen and (min-width: @sm) {
      justify-content: flex-end;
    }
  }
}

.hero-slider__content {
  position: relative;
  padding: 2.5rem 0 6rem;
  width: 100%;

  @media screen and (min-width: @sm) {
    display: flex;
    align-items: center;
    padding: 4rem 0;

    // The entire slide is clickable for rolex slides, and this fixes the centre part not being clickable
    .hero-slider__slide--rolex & {
      pointer-events: none;
    }
  }

  .hero-slider__slide--products & {
    @media screen and (min-width: @sm) {
      padding-bottom: 5%;
    }

    @media screen and (min-width: 1440px) {
      padding-bottom: 8rem;
    }
  }
}

.hero-slider__title {
  .h1;

  .hero-slider__slide--rolex & {
    font-family: 'Helvetica', sans-serif;
  }
}

.hero-slider__eyebrow {
  font-family: @scriptSecondaryFont;
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: @sm) {
    font-size: 2.4rem;
    letter-spacing: 0.02em;
    line-height: 1.3333;
    margin-bottom: 1rem;
  }

  .hero-slider__slide--rolex & {
    font-family: 'Helvetica', sans-serif;
  }
}

.hero-slider__btn {
  margin-top: 2rem;

  @media screen and (min-width: @sm) {
    margin-top: 5rem;
  }
}

@product-slide-height: 11.2rem;
@product-slide-height-md: 15rem;

.hero-slider__products {
  position: relative;
  /* When Flickity is disabled for large devices with less than 4 slides */
  @media screen and ( min-width: @md ) {
    &[data-enable-on-desktop='false'] {
      display: flex;
    }
  }

  // Move it over 1px so you don't see the left border on the first one
  width: calc(100% + 1px);
  margin-left: -1px;
  border-top: 1px solid fade(@white, 50%);
  order: -1;
  margin-top: -@product-slide-height;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media screen and (min-width: @sm) {
    order: 0;
    margin-top: 0;
  }

  @media screen and (min-width: @md) {
    overflow: hidden;
    height: @product-slide-height-md;
    white-space: normal;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // Make the focus states use focus-visible
  .flickity-button,
  .flickity-page-dot {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 5px @light-sand;
    }
  }

  // Buttons for products sliders
  .flickity-prev-next-button {
    background-color: transparent;
    width: 2.4rem;
    height: 5.3rem;
    .flickity-button-icon {
      fill: @light-sand;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &.previous {
      left: 0;
      @media screen and (min-width: 1300px) { left: 1rem; }
      @media screen and (min-width: @xl) { left: 2rem; }
    }
    &.next {
      right: 0;
      @media screen and (min-width: 1300px) { right: 1rem; }
      @media screen and (min-width: @xl) { right: 2rem; }
    }
    @media screen and (max-width: @md-neg) {
      display: none;
    }
  }

  /* Enable Flickity by default */
  &::after {
    content: 'flickity';
    display: none; /* hide :after */
  }

  @media screen and ( min-width: @md ) {
    /* Disable Flickity for large devices */
    &[data-enable-on-desktop='false']::after {
      content: '';
    }
  }

  // Modified version for a solid background on the product slider
  // There's technically also a --transparent, but it's the default behaviour
  &--white {
    background-color: @white;
    .hero-slider__product {
      color: @slate;
      border-color: @grey;
    }

    .flickity-prev-next-button {
      .flickity-button-icon {
        fill: @dark-grey;
      }
    }

    .flickity-button,
    .flickity-page-dot {
      &:focus-visible {
        box-shadow: 0 0 0 5px @dark-grey;
      }
    }
  }
}

.hero-slider__product {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29.5rem;
  padding: 1.6rem;
  flex-shrink: 0;
  color: @white;
  white-space: normal;
  user-select: initial;
  height: @product-slide-height;

  @media screen and (min-width: @sm) {
    width: 40%;
  }

  @media screen and (min-width: @md) {
    width: 33.3333%;
    padding: 1.6rem 2.4rem;

    height: @product-slide-height-md;
  }

  border-left: 1px solid fade(@white, 50%);
}

// Hide the dots on desktop if there's only a single slide
.hero-slider .flickity-viewport:not(:has(.js-slide:nth-child(2))) + .flickity-page-dots {
  display: none;
}

.hero-slider__product-img-link {
  display: block;
  background: @white;
  position: relative;
  overflow: hidden;
  border-radius: 0.4rem;
  width: 8.4rem;
  margin-right: 1.6rem;
  flex-shrink: 0;

  &::before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
}

.hero-slider__product-img {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

.hero-slider__product-eyebrow {
  font-size: 0.9rem;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
  font-weight: 500;

  @media screen and (min-width: @sm) {
    font-size: 1.1rem;
    letter-spacing: 0.11rem;
  }
}

.hero-slider__product-title {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.23;
}

.hero-slider__product-details {
  width: 100%;
  max-width: 25rem;
}

.hero-slider__product-details-link {
  font-size: 1.3rem;

  span {
    text-decoration: underline;
    transition: text-decoration 0.2s ease;
  }

  &:hover,
  &:focus-visible {
    span {
      text-decoration-color: transparent;
    }
  }
}
