// TWO COLUMN

.fc-two-col {
  padding-bottom: 6.4rem;

  @media screen and (min-width: @sm) {
    padding-bottom: 9.6rem;
  }
}
.fc-two-col__title {
  .h6;
  text-align: center;
  margin-bottom: 1.4rem;
}

.fc-two-col__item-link {
  background-size: cover;
  display: block;
  width: 100%;
  margin-top: 1.6rem;
  border-radius: .4rem;
  overflow: hidden;
  color: @white;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    display: block;
    padding-bottom: 49%;

    @media screen and (min-width: @sm) {
      padding-bottom: 64%;
    }
  }

    @media screen and (min-width: @sm) and (hover: hover) and (pointer: fine) {
      &:hover,
      &:focus-visible {
        .fc-two-col__item-title {
          transform: translateY(-2rem);
        }

        .fc-two-col__item-btn {
          opacity: 1;
        }
      }
    }
}

.fc-two-col__item-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: @sm) {
    padding: 2.7rem 3.6rem;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background:transparent linear-gradient(205deg, #EBEBEB00 0%, #767676 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
  }
}

.fc-two-col__item-title {
  display: block;
  font-family: @scriptPrimaryFont;
  font-size: 2.4rem;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.286;
  text-transform: none;
  position: relative;

  @media (min-width: @sm) {
    font-size: 4rem;
    line-height: 1.25;
  }

  @media screen and (min-width: @sm) and (hover: hover) and (pointer: fine) {
    transition: transform .3s cubic-bezier(.215, .61, .355, 1);
  }
}

.fc-two-col__item-btn {
  color: @white;
  position: relative;
  text-decoration: none;
  margin-top: .4rem;

  @media screen and (min-width: @sm) and (hover: hover) and (pointer: fine) {
    opacity: 0;
    position: absolute;
    bottom: 2.7rem;
    left: 3.6rem;
    transition: opacity .2s cubic-bezier(.215, .61, .355, 1);
  }
}

// FOUR COLUMN

.fc-four-col {
  padding-bottom: 8rem;

  @media screen and (min-width: @sm) {
    padding-bottom: var(--modulePadding);
  }

  .flickity-page-dots {
      justify-content: center;
      bottom: -3.2rem;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 var(--pagePadding);
      width: 100%;
      max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

    }

    .flickity-page-dot {
      background: @gold;
      opacity: .3;
      margin: 0;
      width: .8rem;
      height: .8rem;
      transition: opacity .2s ease;

      &+.flickity-page-dot {
        margin-left: .8rem;
      }

      &.is-selected {
        opacity: 1;
      }
    }
}

.fc-four-col__title {
  .h6;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 3rem;
  }
}

.fc-four-col__row {
  @media screen and (min-width: @sm) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.8rem;
  }

  &::after {
    content: 'flickity';
    display: none;

    @media screen and (min-width: @sm) {
      content: '';
    }
  }
}

.fc-four-col__item {
  @media screen and (min-width: @sm) {
    flex-basis: 50%;
    padding: 0 .8rem;
    margin-top: 1.6rem;
  }

  @media screen and (min-width: @md) {
    flex-basis: 25%;
    margin-top: 0;
  }
}

.fc-four-col__item-wrapper {
  border-radius: .4rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;

  &--hoverable {
    @media screen and (min-width: @sm) and (hover: hover) and (pointer: fine) {

        &:hover,
        &:focus-visible {
          .fc-four-col__item-content {
            opacity: 1;
            transform: none;
          }

          .fc-four-col__image {
            transform: scale(1.1);
          }
        }
      }
  }
}

.fc-four-col__image {
  display: block;
  transform: none;
  transition: transform .3s cubic-bezier(.215, .61, .355, 1);
}

.fc-four-col__item-content {
  position: absolute;
  width: calc(100% - 1.6rem);
  bottom: .8rem;
  left: .8rem;
  border-radius: .4rem;
  background: @slate;
  background-blend-mode: mulitply;
  color: @white;
  padding: 2.2rem 1.6rem 1.9rem;

  @media screen and (min-width: @sm) and (hover: hover) and (pointer: fine) {
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity .3s cubic-bezier(.215, .61, .355, 1), transform .3s cubic-bezier(.215, .61, .355, 1);
  }
}

.fc-four-col__item-btn {
  color: @light-gold;
  margin-top: 1rem;
}

.fc-four-col__item-text {
    font-size: 1.3rem;
    letter-spacing: normal;
    line-height: 1.3571;
    text-transform: none;
}

// ROW WITH LABELS

.fc-row-w-labels {
  padding: 5rem 0;
  text-align: center;

  @media screen and (min-width: @sm) {
    padding: 7rem 0;
  }
}

.fc-row-w-labels__title {
  .h7;
  color: @chocolate;
  margin-bottom: 3.6rem;
}

.fc-row-w-labels__collections {
  margin: -0.8rem -0.8rem;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fc-row-w-labels__collection {
  padding: 0 0.8rem;
  width: 33.33%;
  text-align: left;
  max-width: 19.6rem;
  margin: 0.8rem 0;

  @media screen and (min-width: @sm) {
    flex-basis: 20%;
  }
}

.fc-row-w-labels__collection-link {
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      .fc-row-w-labels__collection-img-wrap {
        transform: scale(1.04);
      }

      .fc-row-w-labels__collection-name {
        color: @chocolate;
      }
    }

    &:focus-visible {
      outline: none;
      .fc-row-w-labels__collection-img-wrap {
          transform: scale(1.04);
        }

      .fc-row-w-labels__collection-name {
        color: @chocolate;
      }
    }
  }
}

.fc-row-w-labels__collection-img-wrap {
  display: block;
  background: @white;
  position: relative;
  margin-bottom: 1.7rem;
  border-radius: .4rem;
  overflow: hidden;
  transform: none;
  transition: transform .2s ease;

  &::before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
}

.fc-row-w-labels__collection-img {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
}

.fc-row-w-labels__collection-name {
  .p-sm;
  font-weight: 500;
  text-align: center;
  transition: color .2s ease;
}

// TWO TALL
.fc-two-tall {
  padding-bottom: 6.4rem;

  @media screen and (min-width: @sm) {
    padding-bottom: 9.6rem;
  }
}

.fc-two-tall__grid {
  row-gap: 8rem;
}

.fc-two-tall__item-image {
  width: 100%;
  aspect-ratio: 0.86; // 648/752
  object-fit: cover;
  object-position: center;

  margin-bottom: 3rem;
  @media screen and (min-width: @sm) {
    margin-bottom: 5.4rem;
  }
}

.fc-two-tall__item-title {
  .h3;
  display: inline-block;
  text-align: center;
  margin-bottom: 2rem;
}

.fc-two-tall__item-text {
  .p-lg;
  max-width: 47.5rem;
}

.fc-two-tall__item-btn {
  margin-top: 2rem;
}
