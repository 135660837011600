@selected-bg: #2E3657;

.ring-assistant__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  min-height: 100%;
}

.ring-assistant__form-wrapper {
  width: 100%;
  min-height: 100%;
}

.ring-assistant {
  min-height: 100%;
  text-align: center;

  .form__title {
    margin-bottom: 1.2rem;
  }

  // Mobile version
  @media screen and (max-width: @xs-neg) {
    // Add more space to the bottom of steps so it's not as close to the "clear selection" button
    .step__wrapper {
      margin-bottom: 2rem;
    }

    .form-header {
      margin-bottom: 0;
    }

    .form-footer {
      flex-direction: column;
    }

    .form-footer__prev-next {
      justify-content: space-between;
      margin-top: 1.6rem;
      width: 100%;

      button {
        flex-basis: 50%;
      }
    }
  }

  .step {
    width: 100%;
  }

  .step__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
  }

  // Kill default form padding
  input[type="checkbox"] + label {
    padding-left: 0;
  }
}

.ring-assistant-options {
  display: flex;
  gap: 3.5rem;
  justify-content: center;

  &--wrap {
    flex-wrap: wrap;
  }

  label.radio-square {
    flex-basis: 50%;
  }
}

label.radio-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 3.2rem;
  border-radius: 4px;
  background-color: @selected-bg;
  font-size: 1.9rem;
  cursor: pointer;

  @media screen and (min-width: @sm) {
    width: 25rem;
  }
}

.radio-square__input {
  // Selected options
  &:checked + .radio-square {
    // border: 1px solid fade(@white, 50%);
    box-shadow: 0 0 0 1px fade(@white, 50%);
  }

  &:hover + .radio-square {
    background-color: #4E5674;
  }

  // TODO: Do I need a focus state?
  &:focus-visible + .radio-square {
    box-shadow: 0 0 0 4px fade(@white, 50%);
  }
}

label.radio-circle {
  text-align: center;
  cursor: pointer;
}

.radio-circle__shape {
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
  background-color: @selected-bg;
  overflow: hidden;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radio-circle__img {
  width: 60%;
  aspect-ratio: 1;

  &--bg {
    width: 100%;
    opacity: 0.4;
  }
}

.radio-circle__input {
  // Selected options
  &:checked + .radio-circle {
    .radio-circle__shape {
      box-shadow: 0 0 0 1px fade(@white, 50%);
    }
    .radio-circle__img--bg {
      opacity: 1;
    }
  }

  &:hover + .radio-circle .radio-circle__shape {
    background-color: #4E5674;
  }

  // TODO: Do I need a focus state?
  &:focus-visible + .radio-circle .radio-circle__shape {
    box-shadow: 0 0 0 4px fade(@white, 50%);
  }
}

// The "no results" screen
.ring-assistant__end-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 3.2rem;

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
  }
}

.ring-assistant__info {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.info-icon {
  width: 1.8rem;
  height: 1.8rem;

  svg {
    g {
      stroke: @white;
    }
    path {
      stroke: @white;
      fill: @white;
    }
  }
}

// Review screen
.review-selections-output {
  width: 100%;
  max-width: 56rem;
  display: flex;
  flex-direction: column;
}

// Single review selection
.review-selection {
  text-align: start;
  margin-bottom: 2rem;
}

// The header/button
.review-selection__header {
  .btn-reset();

  position: relative;
  width: 100%;
  background-color: @selected-bg;
  padding: 1.8rem 2.3rem;
  border-radius: 4px 4px 0px 0px;

  h4 {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.42;
    font-weight: 400;
    letter-spacing: 0.084rem;
    color: @white;
    text-transform: uppercase;
  }

  &::after {
    content: '';
    width: 1.4rem;
    height: .7rem;
    position: absolute;
    right: 2.3rem;
    top: 50%;
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.919' height='7.88' viewBox='0 0 14.919 7.88'%3E%3Cpath d='M110.31,2047.935l7.113,6.826,7.113-6.826' transform='translate(-109.963 -2047.575)' fill='none' stroke='%23FFF' stroke-width='1'/%3E%3C/svg%3E") center / contain no-repeat;
  }

  &.open {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

// The expanding part
.review-selection__panel {
  background-color: #4E567412;
  border-radius: 0px 0px 4px 4px;

  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;
}

.review-selection__padding {
  padding: 3.2rem 2.4rem 1.6rem;
}

// The wrapper for the two columns
.review-selection__layout {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: @sm) {
    flex-direction: row;
    gap: 1.6rem;
  }
}

// The two columns
.review-selection__col {
  flex-basis: 50%;

  ul {
    margin: 0 0 2.4rem;
  }

  // The text (left side)
  &--text {
    font-size: 1.2rem;
    line-height: 2;
    font-weight: 500;
    // font: normal normal medium 12px/24px Indivisible Variable;
    letter-spacing: 0.096rem;
    color: #FFFFFF5A;
    text-transform: uppercase;

    ul {
      list-style: none;
      padding: 0;

      li {
        display: inline;

        &:not(:last-child)::after {
          content: ", ";
        }
      }
    }
  }

  // The icons (right side)
  &--icons {
    @selectionCircleSize: 7.2rem;

    ul {
      display: grid;
      grid-template-columns: repeat(3, @selectionCircleSize);
      list-style: none;
      padding: 0;
      align-items: center;
      gap: 1rem;

      li {
        background-color: @selected-bg;
        width: @selectionCircleSize;
        height: @selectionCircleSize;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .review-selection__icon {
          width: 60%;
          aspect-ratio: 1;

          &--bg {
            width: 100%;
          }
        }
      }
    }
  }
}
