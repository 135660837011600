// https://w3sniff.com/code?id=60&title=Multi-Step-Form-with-Progress-Bar-using-bootstrap-5

// TODO: Update hh_adonis with this better @dot-size stuff...

.js-multi-step-form {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 8rem;

  @dot-size: 20px;
  @line-height: 3px;
  @outline-size: @line-height;

  .form-header {
    display: flex;
    gap: 0.5rem;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 5.8rem;
    color: @very-light-gold;
    @media screen and (min-width: @sm) {
      margin-bottom: 3rem;
    }

    .step-indicator {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8;
      letter-spacing: 0.083rem;
      text-transform: uppercase;

      position: relative;
      flex: 1;
      // Can't add padding-bottom because it messes with the ::after line
      // padding-bottom: 4rem;
      padding-top: 1.6rem;
      @media screen and (max-width: (@sm - 1px)) {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
        z-index: 9;
        width: @dot-size;
        height: @dot-size;
        background-color: @navy;
        border-radius: 50%;
        border: @outline-size solid @very-light-gold;
        visibility: visible;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        // TODO: change the `left` and `width` to use math to make sure it fits...
        bottom: calc(100% + (@dot-size / 2));
        width: 100%;
        height: @line-height;
        background-color: @very-light-gold;;
        visibility: visible;
      }

      &.active {
        font-weight: 600;

        &::before {
          background-color: @very-light-gold;
          border: @outline-size solid @very-light-gold;
        }
        &::after {
          background-color: @very-light-gold;
        }
      }
      &.finish {
        font-weight: 600;
        // color: @gold;

        &::before {
          background-color: @gold;
          border: @outline-size solid @gold;
        }
        &::after {
          background-color: @gold;
        }
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  .step__wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .step {
    display: none;
    // display: block !important;

    h3 {
      text-align: center;
      margin-bottom: 3.2rem;
    }
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    &__prev-next {
      display: flex;
      gap: 2rem;
    }
  }
}
