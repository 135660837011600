@font-face {
	font-family: "sangblue";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/sangbleu/SangBleuOGSans-Regular-WebS.woff2");
	font-display: swap;
}

@font-face {
	font-family: "sangblue";
	font-style: normal;
	font-weight: 300;
	src: url("../../fonts/sangbleu/SangBleuOGSans-Light-WebS.woff2");
	font-display: swap;
}

@font-face {
	font-family: "sangblue";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/sangbleu/SangBleuOGSans-Bold-WebS.woff2");
	font-display: swap;
}

@font-face {
	font-family: "sangblue";
	font-style: italic;
	font-weight: 700;
	src: url("../../fonts/sangbleu/SangBleuOGSans-Bold-WebXL.woff2");
	font-display: swap;
}


/* Break points */
@xs: 450px;
@xs-neg: 451px;
@sm-neg: 767px;
@sm: 768px;
@md-neg: 991px;
@md: 992px;
@lg-neg: 1199px;
@lg: 1200px;
@xl-neg: 1499px;
@xl: 1500px;
@xxl-neg: 1699px;
@xxl: 1700px;

@nav-bp: 1200px;
@nav-neg: 1199px;


/* Colours */
@black: #000;
@white: #FFF;
@gold: #9A6B36;
@slate: #242424;
@navy: #172045;
@darkNavy: #09102F;
@chocolate: #65450A;
@sand: #F4F0EA;
@light-sand: #FAF8F5;
@light-blue: #C1CED5;
@warm-grey: #CBC9C5;
@raffi-grey: #A7A8A9;
@text-grey: #555555;
@light-grey: #F2F2F2;
@grey: #E3E3E3;
@dark-grey: #707070;
@darker-grey: #818181;
@burgundy: #6E260B;
@err-bkg: #F7E8DF;
@error: #CBAA85;
@rolex-error: #f00;
@light-gold: #CBAA85;
@very-light-gold: #CEBFAF;
@spinColour: @navy;
@green: #158056;
@tudor-red: #BE0200;


/* Fonts */
@primaryFont: 'indivisible', sans-serif;
@scriptPrimaryFont: 'scotch-display', serif;
@scriptSecondaryFont: 'sangblue', sans-serif;

/* Other */
@productCardListViewHeight: 14rem;
