.author-card {
  background: @sand;
  padding: 2.4rem 1.6rem;
  border-radius: .6rem;

  @media screen and (min-width: @sm) {
    display: flex;
    align-items: flex-start;
    padding: 3.6rem 3.2rem 3.6rem 4rem;
  }
}

.author-card__img {
  display: block;
  max-width: 15.6rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2rem;

  @media screen and (min-width: @sm) {
    flex-shrink: 0;
    margin-right: 5.4rem;
    margin-bottom: 0;
  }
}

.author-card__title {
  font-family: @scriptSecondaryFont;
  font-size: 1.4rem;
  line-height: 1.4286;
  font-weight: normal;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.author-card__bio {
  font-size: 1.5rem;

  p + P {
    margin-top: 1.6rem;
  }
}