@tippyArrowWidth: 34px;
@tippyArrowHeight: 21px;

@tippySizeX: @tippyArrowWidth / 2;
@tippySizeY: @tippyArrowHeight;

@tippyRadius: 4px;
@tippyBgColor: @sand;
@tippyTextColor: #1A1818;

@tippyPaddingX: 3.5rem;
@tippyPaddingY: 3.5rem;

[data-tippy-root] {
  max-width: ~'calc(100vw - 10px)';
}

.tippy-box {
  position: relative;
  background-color: @tippyBgColor;
  color: @tippyTextColor;
  border-radius: @tippyRadius;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;

  &[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
  }

  &[data-placement^='top']>.tippy-arrow {
    bottom: 0;
  }

  &[data-placement^='top']>.tippy-arrow::before {
    bottom: -(@tippySizeY);
    left: 0;
    border-width: @tippySizeY @tippySizeX 0;
    border-top-color: initial;
    transform-origin: center top;
  }

  &[data-placement^='bottom']>.tippy-arrow {
    top: 0;
  }

  &[data-placement^='bottom']>.tippy-arrow::before {
    top: -(@tippySizeY);
    left: 0;
    border-width: 0 @tippySizeX @tippySizeY;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }

  &[data-placement^='left']>.tippy-arrow {
    right: 0;
  }

  &[data-placement^='left']>.tippy-arrow::before {
    border-width: @tippySizeY 0 @tippySizeY @tippySizeX;
    border-left-color: initial;
    right: -(@tippySizeX);
    transform-origin: center left;
  }

  &[data-placement^='right']>.tippy-arrow {
    left: 0;
  }

  &[data-placement^='right']>.tippy-arrow::before {
    left: -(@tippySizeX);
    border-width: @tippySizeY @tippySizeX @tippySizeY 0;
    border-right-color: initial;
    transform-origin: center right;
  }

  &[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
  }
}

.tippy-arrow {
  width: @tippyArrowWidth;
  height: @tippyArrowHeight;
  color: @tippyBgColor;

  &::before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
}

.tippy-content {
  position: relative;
  padding: @tippyPaddingY @tippyPaddingX;
  z-index: 1;

  // Add a fake close icon to the top right of the tooltip
  i.close-icon {
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    right: 1rem;
    top: 1rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}
