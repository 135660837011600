/* ==========================================================================
   Modal
   ========================================================================== */

 /**
  * Basic modals with full keyboard support.
  */

.modal {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  height: ~"calc(var(--vh, 1vh) * 100)";
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: fade(@slate, 20%);
  // background-blend-mode: multiply;
  padding: 0;

  @media screen and (min-width: @sm) {
    padding: 2rem var(--pagePadding-lg);
  }

  @media screen and (min-width: @md) {
    padding: 2rem var(--pagePadding);
  }

  //hide modal
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s, visibility .3s;
  overflow: hidden;

  &.modal--is-visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}

.modal__content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: .4rem;
}

.modal__close {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border: 1px solid currentColor;
  position: absolute;
  top: 2.4rem;
  right: 2.2rem;
  color: inherit;
  opacity: 1;
  transition: opacity .2s ease;

  &:before {
    content: '';
    width: .7rem;
    height: 1px;
    position: absolute;
    left: .4rem;
    top: .7rem;
    background: currentColor;
  }

  @media(hover: hover) and (pointer: fine) {
  
    &:not([disabled]):hover {
      opacity: .6;
    }

    &:not([disabled]):focus-visible {
      opacity: .6;
    }
  }
}

.modal--dark {
  .modal__content {
    background: @navy;
    color: @white;
  }
}

.modal--form {
  .modal__content {
    padding: 5rem 0 6.5rem;

    @media screen and (min-width: @sm) {
      padding: 7.2rem 0 9rem;
    }
  }
}

.modal--image {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .modal__close {
    width: 2.4rem;
    height: 2.4rem;
    background: fade(@white, 50%);
    border: none;
    top: -3.4rem;
    right: 1rem;

    @media screen and (min-width: @sm) {
      right: 0;
    }

    svg {
      width: .8rem;
      height: .8rem;
      stroke: @navy;
    }

    &:before {
      display: none;
    }

    @media(hover: hover) and (pointer: fine) {

      &:not([disabled]):hover {
        svg {
          stroke: @chocolate;
        }
      }

      &:not([disabled]):focus-visible {
        svg {
          stroke: @chocolate;
        }
      }
    }
  }

  .modal__content {
    width: auto;
    height: auto;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: initial;
  }

  .modal__img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
