/* Overlay CTA - General Styles */

.cta__bkg {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	display: flex;

	&:not(.cta__bkg--imgOnly)::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	&--rounded {
		border-radius: 4px;
		overflow: hidden;
	}
}

.cta__btn {
	display: block;
	width: fit-content;
}

/* Overlay Center CTA */
.cta--overlay.cta--center {
	&.cta--col-light {
		color: @light-sand;

		.cta__bkg::after {
			background: @navy;
		}
	}

	&.cta--col-dark {
		color: @navy;

		.cta__bkg::after {
			background: @sand;
		}
	}

	.cta__bkg {
		min-height: 35rem;
		justify-content: center;
		align-items: center;
		text-align: center;

		&::after {
			width: 100%;
			opacity: 0.69;
		}

		@media (min-width: @lg) {
			min-height: 46.5rem;
		}
	}

	.cta__content-wrapper {
		max-width: calc(83.5rem + (var(--pagePadding) * 2));
		position: relative;
		z-index: 1;
	}

	.cta__eyebrow:not(:last-child) {
		margin-bottom: 1.5rem;

		@media (min-width: @sm) {
			margin-bottom: 0.7rem;
		}
	}

	.cta__title:not(:last-child) {
		margin-bottom: 3.6rem;
	}

	.cta__btn:not(:first-child) {
		margin-top: 4rem;
	}

	.cta__btn {
		margin-left: auto;
		margin-right: auto;
	}
}

/* Overlay Left CTA */
.cta--overlay.cta--left {

	&.cta--col-light {
		color: @white;

		.cta__bkg::after {
			background: linear-gradient(65deg, rgba(36,36,36,1) 0%, rgba(36,36,36,0.69) 15%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
		}
	}

	&.cta--col-dark {
		color: @navy;

		.cta__bkg::after {
			background: linear-gradient(65deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.69) 15%, rgba(255,255,255,0) 85%, rgba(255,255,255,0) 100%);
		}
	}

	.cta__bkg {
		min-height: 42rem;
		justify-content: flex-start;
		align-items: flex-end;

		&::after {
			width: 100%;
		}

		@media (min-width: @sm) {
			&::after {
				width: 80%;
			}
		}

		@media (min-width: @lg) {
			min-height: 59.7rem;
		}
	}

	.cta__content-wrapper {
		width: 100%;
		margin: 0 auto;
		max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));
		position: relative;
		z-index: 1;

		.cta__bkg--rounded & {
			max-width: calc(var(--pageWidth) + ((var(--pagePadding) - var(--pagePadding-sm)) * 2));

			@media (min-width: @sm) {
				padding-left: calc(var(--pagePadding) - var(--pagePadding-sm));
				padding-right: calc(var(--pagePadding) - var(--pagePadding-sm));
			}
		}
	}

	.cta__eyebrow:not(:last-child) {
		margin-bottom: 1.5rem;

		@media (min-width: @sm) {
			margin-bottom: 0.7rem;
		}
	}

	.cta__title:not(:last-child) {
		margin-bottom: 3.6rem;
	}

	.cta__text:not(:last-child) {
		margin-bottom: 4rem
	}

	.cta__btn:not(:first-child) {
		margin-top: 3.6rem;
	}
}

/* Overlay Partial CTA */
.cta--overlay.cta--partial {

	@media (min-width: @sm) {
		&.cta--inset {
			padding-left: var(--pagePadding);
			padding-right: var(--pagePadding);
		}
	}

	&.cta--col-light {
		color: @white;

		.cta__bkg::after {
			background: linear-gradient(65deg, rgba(36,36,36,1) 0%, rgba(36,36,36,0.69) 15%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
		}
	}

	&.cta--col-dark {
		color: @navy;

		.cta__text {
			color: @slate;
		}

		.cta__bkg::after {
			//background: linear-gradient(65deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.69) 15%, rgba(255,255,255,0) 85%, rgba(255,255,255,0) 100%);
			background: transparent linear-gradient(233deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.92) 100%) 0% 0% no-repeat padding-box;

			@media screen and (min-width: @sm) {
				background: transparent linear-gradient(252deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.63) 100%) 0% 0% no-repeat padding-box;
			}
		}
	}

	.cta__bkg {
		min-height: 41rem;
		justify-content: flex-start;
		align-items: flex-end;

		@media screen and (min-width: @sm) {
			min-height: 25rem;
		}

		&::after {
			width: 100%;
		}

		@media (min-width: @sm) {

			&--rounded {
				max-width: var(--pageWideWidth);
				margin: 0 auto;
			}

			&::after {
				width: 80%;
			}
		}

		@media (min-width: @lg) {
			min-height: 36.8rem;
		}
	}

	.cta__content-wrapper {
		width: 100%;
		margin: 0 auto;
		max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));
		position: relative;
		z-index: 1;
	}

	.cta__eyebrow:not(:last-child) {
		margin-bottom: 1.5rem;

		@media (min-width: @sm) {
			margin-bottom: 0.7rem;
		}
	}

	.cta__title,
	.cta__text {
		max-width: 21.2rem;

		@media screen and (min-width: @sm) {
			max-width: 33.4rem;
		}
	}

	.cta__text {
		font-size: 1.3rem;
		line-height: 1.46;
		font-weight: 400;

		@media screen and (min-width: @sm) {
			font-size: 1.5rem;
		}
	}

	.cta__title:not(:last-child) {
		margin-bottom: 2rem;
	}

	.cta__text:not(:last-child) {
		margin-bottom: 2rem
	}

	.cta__btn:not(:first-child) {
		margin-top: 2rem;
	}
}

/* Cards CTA */
.cta--cards {
	display: grid;
	grid-row-gap: var(--pagePadding-sm);
	color: @white;

	@media (min-width: @sm) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1.6rem;
	}

	.cta__bkg {
		background-color: @grey;
		align-items: flex-end;
		min-height: 30rem;

		&::after {
			width: 100%;
			background: linear-gradient(65deg, rgba(36,36,36,0.48) 0%, rgba(0,0,0,0.17) 50%, rgba(0,0,0,0.17) 100%);
		}

		@media (min-width: @sm) {
			min-height: 38rem;
		}

		@media (min-width: @lg) {
			min-height: 44rem;
		}
	}

	.cta__content-wrapper {
		position: relative;
		z-index: 1;
		max-width: 39.6rem;

		@media (min-width: @sm) {
			max-width: 45rem;
			padding: 5.6rem 4rem;
		}
	}

	.cta__eyebrow {

		&:not(:last-child) {
			margin-bottom: 1.1rem;
		}

		@media (min-width: @sm) {
			font-size: 1.3rem;
			line-height: 2.4rem;
			letter-spacing: 0.052rem;
		}
	}

	.cta__title:not(:last-child) {
		margin-bottom: 1.2rem;
	}

	.cta__btn:not(:first-child) {
		margin-top: 1.5rem;
	}
}

/* Text with Image CTA */
.cta--text-with-image {
	margin: 0 auto;

	width: 90rem;
	max-width: 100%;
	@media screen and (min-width: @xl) {
		width: 65%;
	}

	&.cta--col-light { color: @white; }

	&.cta--col-dark { color: @navy; }

	// Wrapper for text and image
	.cta__wrapper {
		background-color: @navy;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		padding: 0 !important;
	}

	.cta__content-wrapper {
		width: 100%;
		padding-top: 4.2rem;
		padding-bottom: 4.2rem;
		margin: 0 auto;
		max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));
		position: relative;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: @sm) {
			align-items: flex-start;
		}
	}

	@text-max-width: 65rem;

	.cta__image {
		display: flex;
		width: 33%;
		flex-shrink: 0;

		// Hide image on mobile
		@media screen and (max-width: @sm-neg) {
			display: none;
		}

		@media screen and (min-width: @xxl) {
			width: calc(100% - @text-max-width);
		}

		img {
			display: block;
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: auto;
		}
	}

	.cta__eyebrow:not(:last-child) {
		margin-bottom: 1.5rem;

		@media (min-width: @sm) {
			margin-bottom: 0.7rem;
		}
	}

	.cta__title:not(:last-child) {
		margin-bottom: 1.6rem;
	}

	.cta__text {
		max-width: @text-max-width;
		&:not(:last-child) {
			margin-bottom: 1.6rem
		}
	}

	.cta__btn:not(:first-child) {
		margin-top: 1rem;
	}
}
