.search-results__hero {
  background: @navy;
  color: @white;
  text-align: center;
  min-height: 18.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: @sm) {
    min-height: 32.8rem;
  }
}

.search-results__hero-eyebrow {
  .h6;
  margin-bottom: 1.5rem;
}

.search-results__hero-title {
  font-family: @scriptSecondaryFont;
  font-weight: 300;
  letter-spacing: normal;
  font-size: 3.2rem;
  line-height: 1.25;

  @media screen and (min-width: @sm) {
    font-size: 6.4rem;
    line-height: 1.125;
  }
}

.search-results__section {
  + .search-results__section {
    background: @light-sand;
  }

  + .search-results__section + .search-results__section {
    background: @white;
  }

  // Search section on 404 page
  &--not-found {
    .search-results__section {
      padding-top: 7.9rem;

      @media screen and (min-width: @sm) {
        padding-top: 9.5rem;
      }
    }
  }
  &--hidden {
    display: none;
  }
}

.search-results__section-header {
  padding: 3.9rem 0 2.5rem;

  @media screen and (min-width: @sm) {
    padding: 5.5rem 0;
  }
}

.search-results__section-title {
  text-align: center;
  font-family: @scriptSecondaryFont;
  font-weight: 300;
  letter-spacing: normal;
  text-transform: none;
  font-size: 2.4rem;
  line-height: 1.333;

  @media screen and (min-width: @sm) {
    line-height: 1.25;
    font-size: 3.2rem;
  }
}

.search-results__section-footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 3rem;

  @media screen and (min-width: @sm) {
    padding: 0 0 6.4rem;
  }

  .btn {
    margin-top: 3rem;

    @media screen and (min-width: @sm) {
      margin-top: 4.8rem;
    }
  }
}

.search-results__post-grid {
  border-top: 1px solid #E3E3E3;

  @media screen and (min-width: @sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.search-results__grid-item {
  width: 100%;
  border-bottom: 1px solid #E3E3E3;
  background-color: @white;

  @media screen and (min-width: @sm) {
    flex-basis: calc(100%/3);
    max-width: calc(100%/3);
    border-right: 1px solid #E3E3E3;
  }

  &:nth-of-type(2n) {
    @media screen and (min-width: @sm) and (max-width: @md-neg) {
      border-right: none;
    }
  }

  &:nth-of-type(3n) {
    @media screen and (min-width: @md) {
      border-right: none;
    }
  }
}

.search-results__page-grid {
  padding-top: 1.5rem;

  @media screen and (min-width: @sm) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3rem;
    padding: 0;
  }

  .page-card {
    margin-bottom: 3.2rem;

    @media screen and (min-width: @sm) {
      flex-basis: 50%;
      max-width: 60rem;
      padding: 0 3rem;
    }

    &:nth-of-type(even) {
      @media screen and (min-width: @sm) {
        margin-left: auto;
      }
    }
  }
}

// ==============================
// "Load more" section on search page (Algolia infiniteHits widget)
// ==============================

.search-results__load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.8rem 0 6.4rem;
}

.search-results__load-more-btn {
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
