/* Full-Width Split  */

.slider-fullwidth {
  background: @sand;
}

.slider-fullwidth__slide {
  width: 100%;
  overflow: hidden;

  // If it's a link, make it display flex
  &--clickable {
    display: flex;
  }

  // Make it position relative if it's a single slide, not in a Flickity
  &:not(.flickity-enabled &) {
    position: relative;
  }
}

.slider-fullwidth__content-col {
  position: relative;

  @media screen and (min-width: @sm) {
    flex-basis: 60%;
    max-width: 60%;
  }

  @media screen and (min-width: @md) {
    flex-basis: 41%;
    max-width: 41%;
  }

  // Fix click + drag if whole slide is clickable
  .slider-fullwidth__slide--clickable & {
    pointer-events: none;
  }
}

.slider-fullwidth__image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  &::before {
    @media screen and (min-width: @md) {
      content: '';
      display: block;
      padding-bottom: 73%;
    }
  }
}

.slider-fullwidth__image {
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
      object-fit: cover;
      object-position: right top;
      width: 100%;
      height: auto;
  }

  // Add dark style
  .slider-fullwidth--dark & {
    // Add gradient overlay
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      // 50% black to transparent. Bottom to top. Stops at 49%.
      background: transparent linear-gradient(180deg, #00000000 0%, #00000000 49%, #00000080 100%) 0% 0% no-repeat padding-box;

      // Same gradient, but left to right.
      @media screen and (min-width: @sm) {
        background: transparent linear-gradient(270deg, #00000000 0%, #00000000 49%, #00000080 100%) 0% 0% no-repeat padding-box;
      }
    }
  }
}

.slider-fullwidth__content-wrapper {
  padding: 123% 0 calc(var(--modulePadding) + 5rem);

  @media screen and (min-width: @sm) {
    padding-top: var(--modulePadding);
  }

  @media screen and (min-width: @md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 14%;
    height: 100%;
  }
}

.slider-fullwidth__eyebrow {
  .h6;
  color: @navy;
  margin-bottom: 1.2rem;

  // Add dark style
  .slider-fullwidth--dark & {
    color: @white;
  }

  @media screen and (min-width: @sm) {
    margin-bottom: 2.5rem;
    color: @chocolate;
  }
}

.slider-fullwidth__title {
  .h2;
  color: @navy;

  // Add dark style
  .slider-fullwidth--dark & {
    color: @white;
  }

  margin-bottom: 3rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 6rem;
  }
}

.slider-fullwidth__text {
  .p;

  @media screen and (min-width: @sm) {
    .p-lg;
  }

  p + p {
    margin-top: 1.5rem;
  }
}

.slider-fullwidth__link {
  .slider-fullwidth__text + & {
    margin-top: 3rem;

    @media screen and (min-width: @sm) {
      margin-top: 4rem;
    }
  }

  // Add dark style
  .slider-fullwidth--dark & {
    color: @white;
  }
}

.slider-fullwidth {
  .flickity-page-dots {
    justify-content: flex-start;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 var(--pagePadding-lg);
    width: 100%;
    max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

    @media screen and (min-width: @md) {
      bottom: 6.6rem;
      padding: 0 var(--pagePadding);
    }
  }

  .flickity-page-dot {
    background: @gold;


    opacity: .3;
    margin: 0;
    width: .8rem;
    height: .8rem;
    transition: opacity .2s ease;

    & + .flickity-page-dot {
      margin-left: .8rem;
    }

    &.is-selected {
      opacity: 1;
    }
  }
}

// Add dark flickity page dot style
.slider-fullwidth--dark .flickity-page-dot {
  background: @white;
}

// Slide height fix
.slider-fullwidth__slide {
  .flickity-resize & {
    min-height: 100%;
    display: flex;
    align-items: stretch;
  }
}
