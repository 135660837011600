.checkerboard {
	color: @navy;
	width: 100%;
	
	@media (max-width: @sm-neg) {
		& + .module--bkg-light-sand {
			margin-top: calc(var(--modulePadding) * -1);
		}
	}

	@media (min-width: @sm) {
		display: grid;
		grid-template-columns: 50% 50%;

		&--text-img {
			.checkerboard__img-wrapper {
				grid-column: 2 / 3;
			}

			.checkerboard__content {
				grid-area: 1 / 1 / 2 / 2;
			}
		}
	}
}

.checkerboard__img-wrapper {
	position: relative;
	background-color: @sand;

	&::after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

.checkerboard__img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.checkerboard__content {
	align-items: center;

	&.module--bkg-black {
		color: @white;
	}
}

.checkerboard__content-width {
	@media (min-width: @sm) {
		max-width: calc(var(--pageWidth) / 2);

		.checkerboard--text-img & {
			margin-left: auto;
		}
	}

	@media (min-width: @lg) {
		padding-left: 13%;
		padding-right: 9.3%;
	}
}

.checkerboard__eyebrow:not(:last-child) {
	margin-bottom: 1.2rem;

	@media (min-width: @sm) {
		margin-bottom: 2rem;
	}
}

.checkerboard__title:not(:last-child) {
	margin-bottom: 3rem;
}

.checkerboard__btn:not(:first-child) {
	margin-top: 3rem;

	@media (min-width: @sm) {
		margin-top: 3rem;
	}
}