* {
  box-sizing: border-box;
}

:root {
  --vh: 1vh;

  --pagePadding: 2.4rem;
  --pagePadding-sm: 1.6rem;
  --pagePadding-lg: 3.6rem;
  --modulePadding: 2.4rem;
  // Default header height (is updated via JS in the nav.ts file on resize)
  --header-height: 10.1rem;

  @media screen and (min-width: @sm) {
	  --pagePadding: 4rem;
    --pageWidth: 116rem;
	  --pageWideWidth: 131.2rem;
    --pageXSWidth: 76.8rem;
	  --modulePadding: 6.4rem;
  }

  @media screen and (min-width: @md) {
	  --pagePadding: 6rem;
	  --pagePadding-sm: 2.4rem;
	  --modulePadding: 9.6rem;
  }

  @media screen and (min-width: @lg) {
    // Default header height (is updated via JS in the nav.ts file on resize)
    --header-height: 18.7rem;
  }
}

#skipToContent {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: -1;
  opacity: 0;

  &:focus-visible {
    z-index: 9999;
    opacity: 1;
  }
}

.row--stretch-sm {
  @media screen and (min-width: @sm) {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch
  }
}

.row--margin {
  @media screen and (min-width: @sm) {
    margin-right: -0.8rem;
    margin-left: -0.8rem;

    & > [class^="col-"] {
      padding: 0 0.8rem;
    }
  }

  &--lg {
    @media screen and (min-width: @sm) {
      margin-right: -1.5rem;
      margin-left: -1.5rem;

      &>[class^="col-"] {
        padding: 0 1.5rem;
      }
    }
  }
}

// standardize page wrapper widths
.page-width {
  width: 100%;
  margin: 0 auto;
  max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

  &--xs {
    max-width: calc(var(--pageXSWidth) + (var(--pagePadding) * 2));
  }

  &--lg {
    max-width: calc(var(--pageWideWidth) + (var(--pagePadding) * 2));
  }
}

/* Moved into page-width--lg class to keep with BEM */

// .page-width-wide {
// 	width: 100%;

// 	margin: 0 auto;
// 	padding-left: var(--pageWidePadding);
//   padding-right: var(--pageWidePadding);
//  }

/* Keep padding in mobile-padding class for consistency */

.mobile-padding {
  padding-left: var(--pagePadding);
  padding-right: var(--pagePadding);

  &--sm {
    @media screen and (max-width: @md-neg) {
      padding-left: var(--pagePadding-sm);
      padding-right: var(--pagePadding-sm);
    }
  }

  &--lg {
    @media screen and (max-width: @md-neg) {
      padding-left: var(--pagePadding-lg);
      padding-right: var(--pagePadding-lg);
    }
  }
}

.undo-mobile-padding {
  @media screen and (max-width: @sm-neg) {
    width: calc(100% + var(--pagePadding) * 2) !important;
    margin-left: calc(var(--pagePadding) * -1);
    margin-right: calc(var(--pagePadding) * -1);
  }
}

.padding-sm {
	padding-left: var(--pagePadding-sm);
	padding-right: var(--pagePadding-sm);
}


// show text for screenreaders only

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// basic scrollmagic animation

.fade {
  opacity: 0;
  -webkit-transform: translateY(3rem);
          transform: translateY(3rem);
  transition: opacity .6s cubic-bezier(.215,.61,.355,1),transform .6s cubic-bezier(.215,.61,.355,1),-webkit-transform .6s cubic-bezier(.215,.61,.355,1);

  &.is-show {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
  }
}

.module {
	padding-top: var(--modulePadding);
	padding-bottom: var(--modulePadding);

  // Remove the margin-top on subsequent modules with the same background colour
	&--bkg-white + .module--bkg-white,
	&--bkg-light-sand + .module--bkg-light-sand,
  &--bkg-sand + .module--bkg-sand,
  &--bkg-navy + .module--bkg-navy,
  &--bkg-light-grey + .module--bkg-light-grey,
  &--bkg-burgundy + .module--bkg-burgundy {
		margin-top: calc(var(--modulePadding) * -1);
	}
}

.module--bkg-white {
	background: @white;
}

.module--bkg-light-grey {
  background-color: @light-grey;
}

.module--bkg-light-sand {
	background: #FDFCFA; // light sand at 50% opacity
}

.module--bkg-sand {
	background: @light-sand;
}

.module--bkg-navy {
	background: @navy;
}

.module--bkg-burgundy {
	background: @burgundy;
}

.module--bkg-black {
	background: #000000;
}

// Adding extra class here for specificity
.module.module--remove-padding {
  &-top {
    padding-top: 0;
  }
  &-bottom {
    padding-bottom: 0;
  }
  &-both {
    padding-top: 0;
    padding-bottom: 0;
  }
}

img {
  display: block;
  max-width: 100%;
}

.relative {
	position: relative;
	z-index: 1;
}

hr {
  background: #DEDEDE;
  border: none;
  height: 1px;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
