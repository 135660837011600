.slider-products {
  text-align: center;
  padding: 4.8rem 0;

  @media screen and (min-width: @sm) {
    padding: 7rem 0;
  }

  &--text-dark {
    color: @black;
  }

  &--text-light {
    color: @white;
  }
}

.slider-products__title {
  .h7;
  color: @chocolate;
  margin-bottom: 3.6rem;

  .slider-products--text-light & {
    color: @white;
  }
}

.slider-products__slider {
  margin: 0 -0.8rem;

  @media screen and (min-width: @sm) {
    padding: 0 4.5%;
  }

  @media screen and (min-width: @md) {
    padding: 0 8.5%;
  }

  .flickity-page-dots {
    display: none;
  }
}

.slider-products__slide {
  padding: 0 .8rem;
  width: 50%;
  text-align: left;

  @media screen and (min-width: @sm) {
    width: 33.33333%;
  }

  @media screen and (min-width: @md) {
    width: 20%;
  }
}

.slider-products__product-img-link {
  display: block;
  background: @white;
  position: relative;
  margin-bottom: 1.7rem;

  &:before {
      content: '';
      padding-bottom: 100%;
      display: block;
    }
}

.slider-products__product-img {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

.slider-products__product-title {
  .p-sm;
  font-weight: 500;
}

.slider-products__nav-btn {
  opacity: .8;
  width: .8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  transition: opacity .2s ease;

  @media screen and (min-width: @sm) {
    opacity: .4;
    width: 1.4rem;
  }

  svg {
    display: block;
    stroke: @slate;
    width: 100%;
    height: auto;

    .slider-products--text-light & {
      stroke: @white;
    }
  }

  @media(hover: hover) and (pointer: fine) {

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  &--prev {
    left: -1.8rem;

    @media screen and (min-width: 1360px) {
      left: -5.2rem;
    }
  }

  &--next {
    right: -1.8rem;

    @media screen and (min-width: 1360px) {
      right: -5.2rem;
    }
  }
}

.slider-products__buttons {
  margin-top: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: @sm) {
    margin-top: 3rem;
  }

  .btn {
    margin: .4rem .8rem;
  }
}
