html {
  font-size: 62.5%; //10px
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.5rem;
  font-family: @primaryFont;
  font-weight: 400;
  line-height: 1.533;
	font-variant-ligatures: none;
  color: @black;
}

h1, .h1 {
	font-family: @scriptPrimaryFont;
	font-size: 2.8rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.286;
	text-transform: none;

	@media (min-width: @sm) {
		font-size: 6.4rem;
		line-height: 1.125;
	}
}

h2, .h2 {
	font-family: @scriptPrimaryFont;
	font-size: 2.8rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.286;
	text-transform: none;

	@media (min-width: @sm) {
		font-size: 4rem;
		line-height: 1.25;
	}
}

h3, .h3 {
	font-family: @scriptPrimaryFont;
	font-size: 1.9rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.473;
	text-transform: none;

	@media (min-width: @sm) {
		font-size: 3.2rem;
		line-height: 1.25;
	}
}

h4, .h4 {
	font-family: @scriptSecondaryFont;
	font-size: 3.2rem;
	font-weight: 300;
	letter-spacing: normal;
	line-height: 1.25;
	text-transform: none;

	// @media (min-width: @sm) {
	// 	font-size: 3.2rem;
	// 	line-height: 1.25;
	// }
}

h5, .h5 {
	font-family: @scriptSecondaryFont;
	font-size: 1.2rem;
	font-weight: normal;
	letter-spacing: 0.04em;
	line-height: 1.5;
	text-transform: uppercase;

	@media (min-width: @sm) {
		font-size: 2.4rem;
		letter-spacing: 0.02em;
		line-height: 1.3333;
	}
}

h6, .h6 {
	font-family: @scriptSecondaryFont;
	font-size: 1.2rem;
	font-weight: normal;
	letter-spacing: 0.06em;
	line-height: 1.3333;
	text-transform: uppercase;

	@media (min-width: @sm) {
		font-size: 1.4rem;
		line-height: 1.4286;
	}
}

.h7 {
	font-family: @primaryFont;
	font-size: 1.4rem;
	font-weight: 500;
	letter-spacing: 0.06em;
	line-height: 1.4286;
	text-transform: uppercase;

	// @media (min-width: @sm) {
	// 	font-size: 1.4rem;
	// 	line-height: 1.4286;
	// }
}

.h8 {
	font-family: @primaryFont;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.06em;
	line-height: 1.5;
	text-transform: uppercase;

	// @media (min-width: @sm) {
	// 	font-size: 1.2rem;
	// 	line-height: 1.5;
	// }
}

.p {
	font-family: @primaryFont;
	font-size: 1.5rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.5333;
	text-transform: none;

	// @media (min-width: @sm) {
	// 	font-size: 1.5rem;
	// 	line-height: 1.5333;
	// }
}

.p-lg {
	font-family: @primaryFont;
	font-size: 1.6rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.5;
	text-transform: none;

	@media (min-width: @sm) {
		font-size: 1.9rem;
		line-height: 1.54736;
	}
}

.p-sm {
	font-family: @primaryFont;
	font-size: 1.4rem;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 1.3571;
	text-transform: none;

	// @media (min-width: @sm) {
	// 	font-size: 1.4rem;
	// 	line-height: 1.3571;
	// }
}

.p-xs {
	font-family: @primaryFont;
	font-size: 1.1rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.4545;
	text-transform: none;

	// @media (min-width: @sm) {
	// 	font-size: 1.1rem;
	// 	line-height: 1.4545;
	// }
}

.fine-print {
	font-family: @primaryFont;
	font-size: 1.2rem;
	line-height: 1.33;
	font-weight: 500;
	letter-spacing: 0;
	color: @raffi-grey;
}
