.icon-banner {
  background: @light-sand;
  padding: 4rem 0 4.5rem;

  @media screen and (min-width: @sm) {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0 5.8rem;
  }

  &--inline {
    padding: 3rem 1rem 2.8rem !important;
  }
}

// Just used on cart page
.icon-banner__heading {
  font-family: @scriptSecondaryFont;
  font-size: 1.4rem;
  line-height: 1.42;
  color: @navy;
  text-align: center;
  letter-spacing: 0.084rem;
  text-transform: uppercase;

  background-color: @sand;
  padding: 1.2rem 2rem 0.8rem;
  border-radius: 0.4rem;

  position: relative;
  top: -2rem;
}

.icon-banner__item {
  display: flex;

  // Keep the icons and text side-by-side for inline version
  &:not(.icon-banner--inline &) {
    @media screen and (min-width: @sm) {
      display: block;
    }
  }

  & + & {
    margin-top: 2.4rem;

    @media screen and (min-width: @sm) {
      margin-top: 0;
    }
  }
}

.icon-banner__icon-wrapper {
  background: @sand;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.6rem;
  flex-shrink: 0;

  // Keep the icons and text side-by-side for inline version
  &:not(.icon-banner--inline &) {
    @media screen and (min-width: @sm) {
      margin-right: 0;
      margin-bottom: 3.6rem;
    }
  }
}

.icon-banner__icon {
  display: block;
  height: 2rem;
  width: auto;
}

.icon-banner__content {
  @media screen and (min-width: @sm) {
    max-width: 18.6rem;
  }

  &--large {
    @media screen and (min-width: @sm) {
      max-width: 26.6rem;
    }
  }
}

.icon-banner__title {
  .h6;
  font-family: @primaryFont;
  font-weight: 500;
  color: @chocolate;
  margin-bottom: .6rem;
  margin-top: 1rem;

  @media screen and (min-width: @sm) {
    margin-top: 0;
  }
}

.icon-banner__text {
  font-size: 1.2rem;
  line-height: 1.3;

  @media screen and (min-width: @sm) {
    font-size: 1.3rem;
  }
}
