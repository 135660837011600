.text__content {
	p {
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		display: inline-block;
	}

	p:not(:last-child) {
		margin-bottom: 1.5em;
	}
}

.text__grid {
	display: grid;
	grid-row-gap: 2rem;

	@media (min-width: @sm) {
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 1.6rem;
	}
}

/* Two Column: Title Left */
.text--cols-2.text--left {
	.text__title {
		letter-spacing: -0.052rem;

		@media (min-width: @sm) and (max-width: @lg-neg) {
			line-height: 1.375;
		}
	}

	.text__col-1 {
		@media (min-width: @sm) {
			grid-column: 1 / 6;
		}
	}

	.text__col-2 {
		@media (min-width: @sm) {
			grid-column: 7 / 13;
		}

		@media (min-width: @md) {
			grid-column: 8 / 13;
		}
	}
}

/* */
.text--cols-2.text--center {
	.text__grid {
		@media (min-width: @sm) {
			grid-row-gap: 4.4rem;
		}
	}

	.text__col {
		@media (min-width: @sm) {
			grid-column: 2 / 12;
		}
	}

	.text__eyebrow:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.text__content {
		@media (min-width: @sm) {
			column-count: 2;
			column-gap: 4.2rem;
		}
	}
}

/* Single Column */

.text--single-col {
	text-align: center;

	.text__content img {
		margin-left: auto;
		margin-right: auto;
	}

	.text__eyebrow {
		margin-bottom: 1rem;
	}

	.text__title {
		margin-bottom: 1.5rem;

		@media screen and (min-width: @sm) {
			margin-bottom: 3rem;
		}
	}

	.text__inner {
		@media screen and (min-width: @sm) {
			max-width: 85rem;
			margin: 0 auto;
		}
	}

	.text__buttons {
		margin-top: 1.4rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media screen and (min-width: @sm) {
			margin-top: 3rem;
		}

		.btn {
			margin: .4rem .8rem;
		}
	}
}

.text--single-col-video {

	.text__inner {
		@media screen and (min-width: @sm) {
			max-width: 67.5rem;
		}
	}
	.text__video {
		width: 100%;
		position: relative;
		margin-bottom: 3.2rem;
		margin-top: 3rem;

		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;
			width: 100%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/* Rich Text */
.text.text--rich {

	@media screen and (min-width: @sm) {
		padding-top: calc(var(--modulePadding)/2);
		padding-bottom: calc(var(--modulePadding)/2);
	}

	.text__heading {
		text-align: center;
		padding-bottom: var(--modulePadding);
		border-bottom: 1px solid #d8d8d8;
		margin-bottom: 3rem;

		@media screen and (min-width: @sm) {
			padding-bottom: calc(var(--modulePadding)/2);
		}
	}

	.text__title {
		color: @navy;
	}

	.text__content {
		.p-sm;
		font-weight: 400;

		p {
			line-height: 1.57;
			display: block;
		}

		h2 {
			color: @gold;
			font-family: @scriptPrimaryFont;
			font-size: 2.4rem;
			font-weight: normal;
			letter-spacing: normal;
			line-height: 1.333;
			text-transform: none;
			margin-bottom: 2rem;

			@media screen and (min-width: @sm) {
				font-size: 2.8rem;
				line-height: 1.286;
			}
		}

		h3 {
			font-family: @scriptSecondaryFont;
			font-size: 1.8rem;
			letter-spacing: -0.016em;
			font-weight: 400;
			margin-bottom: 1.6rem;
			margin-top: 3rem;

			@media screen and (min-width: @sm) {
				font-size: 2rem;
			}
		}

		h4 {
			.h6;
			margin-bottom: 1.5rem;
			margin-top: 3rem;
		}

		hr {
			margin: 3rem 0;
			border: none;
			height: 1px;
			background: #D8D8D8;
		}

		strong {
			font-weight: 500;
		}

		ul, ol {
			margin: 1.5em 0;

			li {
				margin-bottom: 1rem;
			}
		}

		ol {
			padding-left: 2.5rem;
		}

		ul {
			padding: 0;
			list-style: none;
			li {
				position: relative;
				padding-left: 1rem;

				&::before {
					content: '\2022';
					position: absolute;
					left: 0;
				}
			}
		}
	}

	a {
		color: @gold;
		text-decoration: underline;
		text-underline-offset: .1rem;
		transition: text-decoration .2s ease;
		font-weight: 500;

		@media(hover: hover) and (pointer: fine) {

				&:hover {
					text-decoration-color: transparent;
				}

				&:focus-visible {
					text-decoration-color: transparent;
				}
			}
	}

	table {
		border-collapse: collapse;
		font-size: 1.2rem;
		line-height: 1.166;
		margin: 1.6em 0;

		th, td {
			border: 1px solid #D8D8D8;
			padding: 1.5rem 1.8rem;
			box-sizing:border-box;
			vertical-align: top;
		}

		th {
			text-align: left;
			font-weight: 600;
		}

		.privacy-policy & {
			th:first-child,
			td:first-child {
				width: 20%;
				max-width: 15rem;
				word-wrap: break-word;
			}
			th::nth-of-type(2),
			td:nth-of-type(2) {
				width: 22%;
			}
			th:nth-of-type(3),
			td:nth-of-type(3) {
				width: 15%;
			}
			th:last-child,
			td:last-child {
				width: 43%;
			}
		}

		// Style specific to the AODA page(s)
		.aoda &,
		.customer-service-accessibility-policy &,
		.multi-year-accessibility-plan & {
			// Make every other table row a different colour
			tbody tr:nth-child(odd) {
				background-color: @light-grey;
			}

			ol {
				// Make it show a/b/c instead of 1/2/3
				list-style-type: lower-alpha;
			}
		}
	}

	& + & {
		// If there are two in a row, kill the negative margin that butts them up against each other
		margin-top: 0;
	}
}

/* Share */
.text--share {
	.text__inner {
		display: flex;
		flex-direction: column;
	}

	.text__title {
		margin-top: 2rem;
		color: @navy;
		max-width: 75rem;

		@media screen and (min-width: @sm) {
			margin-top: 3rem;
		}
	}

	&--right {
		.text__inner {
			align-items: flex-end;
			text-align: end;
		}
	}

	&--centre {
		.text__inner {
			align-items: center;
			text-align: center;
		}

		.share-btn {
			// Account for the share buttons expanding (not exact)
			transform: translateX(4.6rem);
		}
	}
}
