.error-page {
  text-align: center;
  padding: 5rem 0;

  @media screen and (min-width: @sm) {
    padding: 15rem 0;
  }
}

.error-page__title {
  margin-bottom: .75rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 1.6rem;
  }
}

.error-page__title-num {
  display: block;
  color: @light-gold;
  font-size: 15rem;
  line-height: .9;

  @media screen and (min-width: @sm) {
    font-size: 20rem;
    line-height: .6;
  }
}

.error-page__title-text {
  font-family: @scriptSecondaryFont;
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.04em;
  line-height: 1.5;
  text-transform: uppercase;

  @media (min-width: @sm) {
    font-size: 2.4rem;
    letter-spacing: 0.02em;
    line-height: 1.3333;
  }
}

.error-page__text {
  margin-bottom: 3rem;

  @media screen and (min-width: @sm) {
    font-size: 1.7rem;
  }
}