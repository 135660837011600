/* 40/60 Split  */

.slider-4060 {
  background: @sand;
}

.slider-4060__slide {
  width: 100%;
  overflow: hidden;
}

.slider-4060__col-40 {
  @media screen and (min-width: @md) {
    flex-basis: 41%;
    max-width: 41%;
  }
}

.slider-4060__col-60 {
  @media screen and (min-width: @md) {
    flex-basis: 59%;
    max-width: 59%;
  }
}

.slider-4060__image-wrapper {
  position: relative;
  margin: 0 calc(-1 * var(--pagePadding-lg));
  width: calc(100% ~"+" (2 * var(--pagePadding-lg)));

  @media screen and (min-width: @md) {
    margin-left: 0;
    margin-right: calc(-1 ~"*" var(--pagePadding));
    width: calc(100% ~"+" var(--pagePadding));
    height: 100%;
  }

  @media screen and (min-width: 1280px){
    margin-right: calc(-1 * (100vw - var(--pageWidth)) / 2);
    width: calc(100% + (100vw - var(--pageWidth)) / 2);
  }

  &:before {
    @media screen and (min-width: @md) {
      content: '';
      display: block;
      padding-bottom: 73%;
    }
  }
}

.slider-4060__image {

  @media screen and (min-width: @md) {
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    @media screen and (min-width: @md) {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: auto;
    }
  }
}

.slider-4060__content-wrapper {
  padding: var(--modulePadding) 0 calc(var(--modulePadding) + 5rem);

  @media screen and (min-width: @md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 14%;
    height: 100%;
  }
}

.slider-4060__eyebrow {
  .h6;
  color: @navy;
  margin-bottom: 1.2rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 2.5rem;
    color: @chocolate;
  }
}

.slider-4060__title {
  .h2;
  color: @navy;
  margin-bottom: 3rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 6rem;
  }
}

.slider-4060__text {
  .p;

  @media screen and (min-width: @sm) {
    .p-lg;
  }

  p + p {
    margin-top: 1.5rem;
  }
}

.slider-4060__link {
  .slider-4060__text + & {
    margin-top: 3rem;

    @media screen and (min-width: @sm) {
      margin-top: 4rem;
    }
  }
}

.slider-4060 {
  .flickity-page-dots {
    justify-content: flex-start;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 var(--pagePadding-lg);
    width: 100%;
    max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

    @media screen and (min-width: @md) {
      bottom: 6.6rem;
      padding: 0 var(--pagePadding);
    }
  }

  .flickity-page-dot {
    background: @gold;
    opacity: .3;
    margin: 0;
    width: .8rem;
    height: .8rem;
    transition: opacity .2s ease;

    & + .flickity-page-dot {
      margin-left: .8rem;
    }

    &.is-selected {
      opacity: 1;
    }
  }
}
