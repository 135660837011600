// Range tracks
@dualHandleRangeInnerColour: @warm-grey;
@dualHandleRangeOuterColour: #515872;
@dualHandleRangeCommonHeight: 16px;
@dualHandleRangeInnerHeight: 2px;
@dualHandleRangeOuterHeight: 2px;
@dualHandleRangeTrackRadius: 0;

// Tooltip
@dualHandleRangeTooltipBgColour: transparent;
@dualHandleRangeTooltipTextColour: #fff;
@dualHandleRangeTooltipWidth: 28px;
@dualHandleRangeTooltipHeight: 28px;

// Handles
@dualHandleRangeHandleHeight: 8px;
@dualHandleRangeHandleWidth: 8px;
@dualHandleRangeHandleColour: #fff;
@dualHandleRangeHandleRadius: 50%;
@dualHandleRangeHandleGrabbableWidth: 24px;
@dualHandleRangeHandleGrabbableHeight: 24px;
@dualHandleRangeHandleYOffset: -2px;

.dual-handle-range {
  position: relative;
  display: flex;
  align-items: center;
  width: 99%;

  margin: 6rem 0 4rem;

  & > div {
    position: absolute;
    display: flex;
    align-items: center;
    left: 13px;
    right: 15px;
    height: @dualHandleRangeCommonHeight;
  }
}

.dual-handle-range__track {
  position: absolute;
  height: @dualHandleRangeOuterHeight;
  border-radius: @dualHandleRangeTrackRadius;

  &--left {
    left: 0;
    background-color: @dualHandleRangeOuterColour;
    margin: 0 7px;
  }

  &--right {
    right: 0;
    margin: 0 7px;
    background-color: @dualHandleRangeOuterColour;
  }

  &--inner {
    left: 0;
    height: @dualHandleRangeInnerHeight;
    background-color: @dualHandleRangeInnerColour;
  }
}

// This isn't what you're clicking and dragging. That is the actual range input :thumb.
.dual-handle-range__handle {
  z-index: 1;
  position: absolute;
  margin-top: @dualHandleRangeHandleYOffset;
  // These start at the left, and with left:100%, it sticks off the edge, so we need to move it back half its width.
  margin-left: -(@dualHandleRangeHandleWidth / 2);
  width: @dualHandleRangeHandleWidth;
  height: @dualHandleRangeHandleHeight;
  border-radius: @dualHandleRangeHandleRadius;
  background-color: @dualHandleRangeHandleColour;
  text-align: left;

  box-shadow: 0 0 0 2px @navy;
  outline: none;
  cursor: pointer;
}

.dual-handle-range__tooltip {
  z-index: 2;
  position: absolute;
  top: -36px; // TODO: Turn this into math
  margin-left: -12px; // TODO: Turn this into math
  width: @dualHandleRangeTooltipWidth;
  height: @dualHandleRangeTooltipHeight;
  border-radius: 50%;
  background-color: @dualHandleRangeTooltipBgColour;
  color: @dualHandleRangeTooltipTextColour;
  white-space: nowrap;

  [data-label-style='static'] & {
    width: auto;
    margin-left: 0;
    top: auto;
    bottom: -32px;

    &.dual-handle-range__tooltip--left {
      .dual-handle-range__tooltip-text {
        transform: none;
        left: 0;
      }
    }
    &.dual-handle-range__tooltip--right {
      .dual-handle-range__tooltip-text {
        transform: none;
        left: auto;
        right: 0;
      }
    }
  }

  // Make it only show on hover
  // opacity: 0;
  // .dual-handle-range:hover & {
  //   opacity: 1;
  // }

  &::after {
    content: '';

    position: absolute;
    left: 0;
    top: 19px; // TODO: Figure out the math for the top position
    border-left: (@dualHandleRangeTooltipWidth / 2) solid transparent;
    border-right: (@dualHandleRangeTooltipWidth / 2) solid transparent;
    border-top-width: (@dualHandleRangeTooltipWidth / 2 + 2px);
    border-top-style: solid;
    border-top-color: @dualHandleRangeTooltipBgColour;
    border-radius: (@dualHandleRangeTooltipWidth / 2 + 2px);
  }

  .dual-handle-range__tooltip-text {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.15;
    letter-spacing: 0.078rem;
    z-index: 1;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.dual-handle-range__input {
  // Needs to be above the handle span so it can be clicked.
  z-index: 2;
  position: absolute;
  width: 100%;
  height: @dualHandleRangeCommonHeight;
  padding: 0 !important;

  // NOTE: Comment these out if you want to see the range input
  opacity: 0;
  filter: alpha(opacity=0);

  cursor: pointer;
  pointer-events: none;
  appearance: none;
  -webkit-appearance: none;

  // This is what you're actually clicking and dragging, it's just hidden.
  &::-ms-thumb {
    width: @dualHandleRangeHandleGrabbableWidth;
    height: @dualHandleRangeHandleGrabbableHeight;
    margin-top: @dualHandleRangeHandleYOffset;
    border: 0 none;
    border-radius: 0;
    background: red;
    pointer-events: all;
  }
  &::-moz-range-thumb {
    width: @dualHandleRangeHandleGrabbableWidth;
    height: @dualHandleRangeHandleGrabbableHeight;
    margin-top: @dualHandleRangeHandleYOffset;
    border: 0 none;
    border-radius: 0;
    background: red;
    pointer-events: all;
  }
  &::-webkit-slider-thumb {
    width: @dualHandleRangeHandleGrabbableWidth;
    height: @dualHandleRangeHandleGrabbableHeight;
    margin-top: @dualHandleRangeHandleYOffset;
    border: 0 none;
    border-radius: 0;
    background: red;
    pointer-events: all;
    -webkit-appearance: none;
  }
  &::-ms-fill-lower {
    background: transparent;
    border: 0 none;
  }
  &::-ms-fill-upper {
    background: transparent;
    border: 0 none;
  }

  &::-ms-track {
    background: transparent;
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    margin: 0 -(@dualHandleRangeHandleGrabbableWidth / 2);
  }
  &::-moz-range-track {
    background: transparent;
    color: transparent;
    -moz-appearance: none;
    margin: 0 -(@dualHandleRangeHandleGrabbableWidth / 2);
  }
  &::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
    appearance: none;
    -webkit-appearance: none;
    margin: 0 -(@dualHandleRangeHandleGrabbableWidth / 2);
  }
  &:focus {
    outline: none;
  }
  &:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus-visible + .dual-handle-range__handle {
    outline: 2px solid @dualHandleRangeHandleColour;
    outline-offset: 4px;
  }
}
