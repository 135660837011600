// .tudor__checkerboard-content {
// 	@media (max-width: @sm-neg) {
// 		padding-bottom: calc(var(--modulePadding) * 2);
// 	}

// 	@media (min-width: @sm) {
// 		padding-right: 10vw;

// 		.checkerboard__content-width {
// 			margin-left: 0;
// 			padding: 0;
// 		}
// 	}

// 	@media (min-width: @md) {
// 		padding: 10vw 12vw 10vw var(--pagePadding);
// 	}

// 	@media (min-width: @lg) {
// 		padding: 10vw 15vw 10vw var(--pagePadding);
// 	}

// 	@media (min-width: 1295px) {
// 		padding: 10vw 15vw 10vw calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
// 	}
// }

// The module container
.slider-carousel {
  .flickity-page-dot {
		background: @gold;
		height: 8px;
		width: 8px;
		opacity: 0.3;
		margin: 4px;

		&.is-selected { opacity: 1; }
		&:focus { box-shadow: none; }
		&:focus-visible { box-shadow: 0 0 0 1px @black; }
	}

  // Make the focus states use focus-visible
  .flickity-button,
  .flickity-page-dot {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 5px @raffi-grey;
    }
  }

  // Buttons for products sliders
  .flickity-prev-next-button {
    background-color: transparent;
    width: 2.4rem;
    height: 5.3rem;
    transform: translateY(0);
    .flickity-button-icon {
      fill: @raffi-grey;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    // These need to match the .slider-carousel__slide width
    &.previous {
      left: var(--pagePadding);
      @media (min-width: 1295px) {
        left: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
      }
    }
    &.next {
      right: var(--pagePadding);
      @media (min-width: 1295px) {
        right: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
      }
    }
    @media screen and (max-width: @sm-neg) {
      display: none;
    }
  }
}

// The slides
.slider-carousel__slide {
  width: calc(100vw - (var(--pagePadding) * 2));
  @media (min-width: 1295px) {
    // TODO: Doesn't exactly match, but is very close...
    // Uh what? Isn't this just 100vw - 5 * pagePadding?
    // width: calc(100vw - (var(--pagePadding) + (var(--pagePadding) * 2)) - var(--pagePadding) * 2);
    // width: calc(100vw - (var(--pagePadding) * 5));
    width: var(--pageWidth);
  }

  // What the Tudor module is using
  // 	@media (min-width: 1295px) {
  // 		padding-left: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
  // 	}
}

@carousel-transition: 0.4s ease-in-out;

// The image itself
.slider-carousel__image {
  object-fit: cover;
  object-position: center;

  width: 100%;
  aspect-ratio:  1.155; // 302.08 / 261.54;
  @media screen and (min-width: @sm) {
    aspect-ratio: 16/9;
  }

  transition: transform @carousel-transition;
  // transform: scale(0.9);
  transform: scale(0.95, 0.9);
  // The actual design is more like 75%...
  .is-selected & {
    transform: scale(1);
  }
}

// The text + button content
.slider-carousel__content {
  width: 100%;
}

// Wrapper for text content
.slider-carousel__content-inner {
  @media screen and (min-width: @sm) {
    text-align: center;
  }
  margin: 0 auto;
  max-width: 60rem;
  opacity: 0;
  transition: opacity @carousel-transition;
  .is-selected & {
    opacity: 1;
  }
}
.slider-carousel__content__title {
  .h4;
  margin: 5rem auto 2rem;
}
.slider-carousel__content__text {
  .p;
}
.slider-carousel__content__link {
  margin: 2rem auto;
}
