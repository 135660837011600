.slider-history {
	background: @sand;
	position: relative;
	overflow: hidden;

	svg {
		position: absolute;
		height: 45.549rem;
		width: 43.712rem;
		top: 8.3rem;
		left: 7.2px;
		opacity: 0.67;

		@media (min-width: @sm) {
			top: 1.8rem;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.slider-history__title {
	font-family: @scriptSecondaryFont;
	font-size: 2.4rem;
	font-weight: 300;
	letter-spacing: -0.038rem;
	line-height: 1.25;
	text-transform: none;
	margin-bottom: 4.7rem;
	color: @black;

	&--desktop {
		display: none;
	}

	@media (min-width: @sm) {
		font-size: 2.8rem;
		letter-spacing: -0.045rem;

		&--mobile {
			display: none;
		}

		&--desktop {
			display: block;
		}
	}
}

.slider-history__slider {
	width: calc(100% + (var(--pagePadding) * 2));
	margin-left: calc(var(--pagePadding) * -1);
	padding-bottom: 8.3rem;

	@media (min-width: @md) {
		padding-bottom: 7.2rem;
	}

	@media (min-width: 1290px) {
		width: calc((100% + (var(--pagePadding) * 2)) + 100vw - (var(--pageWidth) + (var(--pagePadding) * 2)));
	}

	.flickity-page-dots {
		bottom: 0;
		max-width: calc(100% - (var(--pagePadding) * 2) - (4.2rem * 2));
		left: 50%;
		transform: translateX(-50%);
		min-height: 3.9rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (min-width: 1290px) {
			left: calc(50% - (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2);
		}
	}

	.flickity-page-dot {
		background: @gold;
		opacity: 0.3;
		margin: 0.3rem 0.5rem;
		
		&:focus {
			box-shadow: none;
		}

		&:focus-visible {
			box-shadow: 0 0 0 1px @black;
		}
	}
	
	.flickity-page-dot.is-selected {
		opacity: 1;
	}

	.flickity-prev-next-button {
		display: block;
		height: 3.9rem;
		width: 3.9rem;
		background-color: transparent;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.332' height='15.268' viewBox='0 0 18.332 15.268'%3E%3Cpath id='np_arrow_5633318_000000' d='M12.94,8.285a.651.651,0,0,1,0-1.3H28.049L20.435,1.166A.65.65,0,0,1,21.224.133l9.1,6.955a.652.652,0,0,1,0,1.091l-9.1,6.955a.65.65,0,0,1-.788-1.033l7.614-5.817Z' transform='translate(30.622 15.268) rotate(180)' fill='%239a6b36'/%3E%3C/svg%3E ");
		background-repeat: no-repeat;
		background-size: 1.8332rem 1.5268rem;
		background-position: center;
		border: 1px solid @gold;
		transform: none;
		top: auto;
		bottom: 0;

		@media (min-width: @md) {
			bottom: 8.6rem;
		}

		svg {
			display: none;
		}

		&.previous {
			left: var(--pagePadding);
		}

		&.next {
			right: var(--pagePadding);
			transform: rotate(180deg);

			@media (min-width: @md) {
				right: auto;
				left: calc(var(--pagePadding) + 5.5rem);
			}
		}

		&:focus {
			box-shadow: none;
		}

		&:hover {
			background-color: @gold;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.332' height='15.268' viewBox='0 0 18.332 15.268'%3E%3Cpath id='np_arrow_5633318_000000' d='M12.94,8.285a.651.651,0,0,1,0-1.3H28.049L20.435,1.166A.65.65,0,0,1,21.224.133l9.1,6.955a.652.652,0,0,1,0,1.091l-9.1,6.955a.65.65,0,0,1-.788-1.033l7.614-5.817Z' transform='translate(30.622 15.268) rotate(180)' fill='%23fff'/%3E%3C/svg%3E ");
		}
		&:focus-visible {
			background-color: @gold;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.332' height='15.268' viewBox='0 0 18.332 15.268'%3E%3Cpath id='np_arrow_5633318_000000' d='M12.94,8.285a.651.651,0,0,1,0-1.3H28.049L20.435,1.166A.65.65,0,0,1,21.224.133l9.1,6.955a.652.652,0,0,1,0,1.091l-9.1,6.955a.65.65,0,0,1-.788-1.033l7.614-5.817Z' transform='translate(30.622 15.268) rotate(180)' fill='%23fff'/%3E%3C/svg%3E ");
		}
	}
}

.slider-history__slide {
	padding: 0 var(--pagePadding);
	
}

.slider-history__card {
	max-width: var(--pageWidth);
}

.slider-history__card-img-wrapper {
	position: relative;
	margin-bottom: 5.4rem;
	width: 100%;
	z-index: 1;

	&::after {
		content: "";
		display: block;
		padding-bottom: 98%;
	}

	@media (min-width: @sm) {
		margin-bottom: 0;
		align-self: flex-start;
	}

	@media (min-width: @md) {
		width: calc(100% + 1.4rem);
	}
}

.slider-history__card-img-corner {
	display: block;
	width: 0;
	height: 0;
	border-top: 2.6rem solid @white;
	border-right: 2.6rem solid transparent;
	position: absolute;
	z-index: 1;
	filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

	&:first-child {
		top: 0;
		left: 0;
	}

	&:nth-child(2) {
		top: 0;
		right: 0;
		transform: rotate(90deg);
	}

	&:nth-child(3) {
		bottom: 0;
		right: 0;
		transform: rotate(180deg);
	}

	&:nth-child(4) {
		bottom: 0;
		left: 0;
		transform: rotate(270deg);
	}

	@media (min-width: @md) {
		border-top: 5rem solid @white;
		border-right: 5rem solid transparent;
	}
}

.slider-history__card-img {
	position: absolute;
	top: 0.8rem;
	left: 0.8rem;
	height: calc(100% - 1.6rem);
	width: calc(100% - 1.6rem);
	object-fit: cover;
	background-color: @slate;
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.29);

	@media (min-width: @md) {
		top: 1.4rem;
		left: 1.4rem;
		height: calc(100% - 2.8rem);
		width: calc(100% - 2.8rem);
	}

}

.slider-history__card-text {

	p:not(:last-child) {
		margin-bottom: 1.5em;
	}

	@media (min-width: @md) {
		padding-top: 4.6rem;
		padding-bottom: 9.2rem;
	}
}

.slider-history__card-date {
	font-family: @scriptPrimaryFont;
	font-size: 9.4rem;
	font-weight: normal;
	letter-spacing: 0.188rem;
	line-height: 6.4rem;
	text-transform: none;
	color: @gold;
	position: relative;
	padding-bottom: 2.2rem;
	margin-bottom: 3rem;

	&::after {
		content: "";
		background: @raffi-grey;
		height: 1px;
		width: calc(100% + var(--pagePadding));
		position: absolute;
		bottom: 0;
		left: 0;
	}

	@media (min-width: @sm) {
		font-size: 10.4rem;
		letter-spacing: 0.208rem;
		line-height: 6.4rem;

		&::after {
			width: 100vw;
		}
	}

	@media (min-width: @md) {
		padding-bottom: 3.3rem;
		margin-bottom: 3.3rem;
	}
}
