.ambassadors {
	@media (min-width: @sm) {
		margin-top: 2.4rem;
	}

	@media (min-width: @md) {
		margin-top: 0;
	}
}

.ambassador__grid {

	@media (min-width: @sm) {
		display: grid;
		grid-template-columns: calc(var(--pagePadding) - 1.6rem) repeat(12, 1fr) calc(var(--pagePadding) - 1.6rem);
		grid-column-gap: 1.6rem;
		align-items: start;
		padding-bottom: var(--modulePadding);

		&:not(:last-child) {
			padding-bottom: calc(var(--modulePadding) * 2);
		}

		&--normal {
			.ambassador__portrait-wrapper {
				grid-area: 1 / 1 / 3 / 6;
			}
			.ambassador__info {
				grid-area: 1 / 7 / 2 / 14;
			}
			.ambassador__imgs {
				grid-area: 2 / 9 / 5 / 14;
			}
			.ambassador__callout {
				grid-area: 3 / 3 / 4 / 9;
				margin-top: var(--modulePadding);
				padding-right: 13%;
			}

			.ambassador__watch-bkg {
				width: 60%;
				position: absolute;
				top: 0;
				right: 0;
				transform: translateX(11%);
			}
			.ambassador__watch-img {
				margin: 34% 26% 0 auto;
				width: 64%;
			}
		}

		&--reversed {
			.ambassador__portrait-wrapper {
				grid-area: 1 / 10 / 3 / -1;
			}
			.ambassador__info {
				grid-area: 1 / 2 / 2 / 9;
			}
			.ambassador__imgs {
				grid-area: 2 / 2 / 5 / 8;
			}
			.ambassador__callout {
				grid-area: 3 / 9 / 5 / 14;
			}

			.ambassador__watch-bkg {
				width: 77%;
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-10.7%);
			}
			.ambassador__watch-img {
				margin: 20% 0 0 auto;
				width: 64%;
			}
		}
	}

	@media (min-width: 1295px) {
		grid-template-columns: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding) - 1.6rem) repeat(12, 1fr) calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding) - 1.6rem);
	}

	@media (min-width: @xxl) {
		&--reversed {
			.ambassador__callout {
				margin-top: 4rem;
			}
		}
	}
}

.ambassador__portrait-wrapper {
	position: relative;
	width: 100%;

	&::after {
		content: "";
		display: block;
		padding-bottom: 142%
	}

	@media (min-width: @sm) {
		margin-top: -5.4rem;
	}
}

.ambassador__portrait {
	display: block;
	object-fit: cover;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.ambassador__info {
	
	@media (min-width: @sm) {
		padding-left: 0;
		padding-right: 4.3vw;
	}
} 

.ambassador__logo {
	display: block;
	height: auto;
	width: 10.9rem;
	margin-bottom: 4.4rem;
}

.ambassador__name {
	color: @navy;
	margin-bottom: 0.5em;
}

.ambassador__info {
	color: @slate;
}

.ambassador__btn {
	margin-top: 3.5rem;
}

.ambassador__imgs {

	@media (min-width: @sm) {
		position: relative;
	}
}

.ambassador__watch-bkg {
	display: none;

	@media (min-width: @sm) {
		display: block;
		opacity: 0.63;
	}
}

.ambassador__watch-img {
	max-width: 36.8rem;
	height: auto;
	width: 100%;
	margin: calc((var(--modulePadding) - 2.6rem) * -1) auto 0;

	@media (min-width: @sm) {
		position: relative;
		z-index: 1;
	}
}

.ambassador__callout {
	padding-bottom: var(--modulePadding);
	margin-top: 3.8rem;

	@media (max-width: @sm-neg) {
		font-size: 2.7rem;
		letter-spacing: -0.043rem;
		line-height: 4rem;
	}

	@media (min-width: @sm) {
		letter-spacing: -0.051rem;
		padding: 0;
		margin: 0;
	}
}