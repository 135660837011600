.hidden {
  display: none;
}

.hidden-important {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

// basic scrollmagic animation
.fade {
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  transition: opacity .6s cubic-bezier(.215,.61,.355,1), transform .6s cubic-bezier(.215,.61,.355,1), -webkit-transform .6s cubic-bezier(.215,.61,.355,1);

  &.is-show {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
  }
}

figure {
  margin: 0;
}

.only-mobile {
  @media screen and (min-width: @sm) {
    display: none;
  }
}

.text-center {
  text-align: center;
}
