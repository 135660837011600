/* Regular Hero */
.hero--reg {
	position: relative;
	color: @navy;

	@media (max-width: @sm-neg) {
		& + .module--bkg-white {
			margin-top: -2.5rem;
		}
	}

	@media (min-width: @sm) {
		color: @white;
	}

	.hero__bkg {
		background-color: @navy;
		height: 30.4rem;
		width: 100%;
		position: relative;
		overflow: hidden;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: @sm-neg) {
			&--logo {
				&.hero__bkg--overlay {
					&::after {
						background: linear-gradient(45deg, rgba(36,36,36,0.58) 0%, rgba(36,36,36,0.58) 15%, rgba(0,0,0,0) 65%, rgba(0,0,0,0) 100%);
						content: "";
						height: 120%;
						width: 100%;
						position: absolute;
						top: -10%;
						left: 0;
					}
				}
			}
		}

		@media (min-width: @sm) {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;

			&--overlay {
				&::after {
					background: linear-gradient(45deg, rgba(36,36,36,0.84) 0%, rgba(36,36,36,0.84) 20%, rgba(0,0,0,0) 72%, rgba(0,0,0,0) 100%);
					content: "";
					height: 150%;
					width: 84%;
					position: absolute;
					top: -10%;
					left: 0;
				}
			}
		}
	}

	.hero__logo-wrapper {
		position: absolute;
		left: 50%;
		bottom: 3.2rem;
		transform: translateX(-50%);
		z-index: 1;

		@media (min-width: @sm) {
			bottom: auto;
			top: 7.3rem;
		}
	}

	.hero__logo {
		max-height: 6.5rem;
		max-width: 20rem;
		display: block;
		position: absolute;
		left: var(--pagePadding);
		bottom: 0;

		@media (min-width: @sm) {
			bottom: auto;
			top: 0;
			max-height: 7rem;
			max-width: 25rem;
		}
	}

	.hero__wrapper {
		position: relative;
		z-index: 1;

		@media (min-width: @sm) {
			min-height: 50.4rem;
			display: flex;
			align-items: flex-end;
		}
	}

	.hero__content {
		padding: 2.5rem 0;

		@media (min-width: @sm) {
			padding: 20rem 0 7.1rem;
			max-width: 57rem;
		}
	}

	.hero__title {
		@media (max-width: @sm-neg) {
			br {
				display: none;
			}
		}
	}

	.hero__eyebrow:not(:last-child) {
		margin-bottom: 0.8rem;

		@media (min-width: @sm) {
			margin-bottom: 1.2rem;
		}
	}
}

// Hero background image (used in hero--regular and hero--image)
.hero__bkg-img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.hero--image {
	background-size: cover;
	background-position: center;
	min-height: 30.4rem;
	position: relative;

	@media screen and (min-width: @sm) {
		min-height: 50.4rem;
	}
}

.hero--resource {
	background: @sand;

	@media screen and (min-width: @sm) {
		background: transparent;
	}

	.hero__bkg{
		&::after {
			@media screen and (min-width: @sm) {
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: #A7A8A9;
				mix-blend-mode: multiply;
			}
		}
	}
	.hero__content {
		@media (min-width: @sm) {
			padding: 5rem 0;
			max-width: 69rem;
		}
	}
	.hero__byline {
		font-size: 1.3rem;
		margin-top: 1rem;
		color: fade(@black, 70%);

		@media screen and (min-width: @sm) {
			color: inherit;
			margin-top: 2rem;
		}
	}
}
