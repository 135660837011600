// NOTE: This is a direct copy of the Tudor Ambassadors module

.featured-product__grid {
	@media (min-width: @sm) {
		display: grid;
		grid-template-columns: calc(var(--pagePadding) - 1.6rem) repeat(12, 1fr) calc(var(--pagePadding) - 1.6rem);
		grid-column-gap: 1.6rem;
		align-items: start;

		&--normal {
			.featured-product__content-wrapper {
				grid-area: 1 / 1 / 3 / 6;
			}
      .featured-product__image-wrapper {
        grid-area: 1 / 6 / 3 / 15;
        margin-left: -1.6rem;
      }
		}

		&--reversed {
			.featured-product__content-wrapper {
				grid-area: 1 / 10 / 3 / -1;
			}
      .featured-product__image-wrapper {
        grid-area: 1 / 1 / 3 / 10;
        margin-right: -1.6rem;
      }
		}
	}

	@media (min-width: 1295px) {
		grid-template-columns: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding) - 1.6rem) repeat(12, 1fr) calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding) - 1.6rem);
	}

	@media (min-width: @xxl) {
		&--reversed {
			.featured-product__callout {
				margin-top: 4rem;
			}
		}
	}
}

.featured-product__content-wrapper {
	position: relative;
	width: 100%;
  background-color: @slate;
  color: @white;

	padding: var(--modulePadding) var(--pagePadding);
	@media screen and (min-width: @sm) {
		padding: var(--modulePadding) 0 var(--modulePadding) 4rem;
		display: flex;
		align-items: center;
	}
	@media screen and (min-width: @md) {
		padding-left: 8rem;
	}

  aspect-ratio: 100 / 142;
}

.featured-product__content {
	@media screen and (min-width: @sm) {
		width: 75%;
	}
}

.featured-product__image-wrapper {
  position: relative;
  height: 100%;
  @media screen and (max-width: @sm-neg) {
    aspect-ratio: 1;
  }

  @media (min-width: @sm) {
		padding-left: 0;
		padding-right: 4.3vw;
	}
}

.featured-product__image {
	display: block;
	object-fit: cover;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.featured-product__title {
	color: @white;
	margin-bottom: 0.5em;
}

.featured-product__eyebrow {
	.p-xs;
	text-transform: uppercase;
	color: @white;
	border-top: 1px solid @warm-grey;
	padding: 1.6rem 0 1rem;
}

.featured-product__btn {
	margin-top: 3.5rem;
}

.featured-product__imgs {
	@media (min-width: @sm) {
		position: relative;
	}
}

.featured-product__product-bkg {
	display: none;

	@media (min-width: @sm) {
		display: block;
		opacity: 0.63;
	}
}

.featured-product__product-img {
	max-width: 36.8rem;
	height: auto;
	width: 100%;
	margin: calc((var(--modulePadding) - 2.6rem) * -1) auto 0;

	@media (min-width: @sm) {
		position: relative;
		z-index: 1;
	}
}

.featured-product__callout {
	padding-bottom: var(--modulePadding);
	margin-top: 3.8rem;

	@media (max-width: @sm-neg) {
		font-size: 2.7rem;
		letter-spacing: -0.043rem;
		line-height: 4rem;
	}

	@media (min-width: @sm) {
		letter-spacing: -0.051rem;
		padding: 0;
		margin: 0;
	}
}

.featured-product__slider {
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

	@media screen and (min-width: @sm) {
		max-width: 28rem;
	}

	// Make the focus states use focus-visible
  .flickity-button,
  .flickity-page-dot {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 5px @light-sand;
    }
  }

	// Buttons for products sliders
  .flickity-prev-next-button {
    background-color: transparent;
    width: 2.4rem;
    height: 5.3rem;
    .flickity-button-icon {
      fill: @light-sand;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &.previous {
      left: -2.6rem;
    }
    &.next {
      right: -2.6rem;
    }
  }

	// The 2x2 grid version if there are 4+ products
	&--2x2 {
		.featured-product__slide {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
		}
	}
}

.featured-product__slide {
	width: 100%;
}

// Overrides to product-card component
.featured-product__grid {
	// Kill the lifestyle image overlay since the text is below the image here
	.product-card--lifestyle-image .product-card__images::after {
		display: none;
	}

	.product-card {
		// Kill the weird mobile styles for the product cards...
		&--sm {
			&.hover:not(.disable-hover) {
				@media screen and (max-width: @sm-neg) {
						.product-card__img--primary {
							transform: scale(1.1); // translate(-50%, -50%);
						}
					}
			}
			&.product-card--lifestyle-image {
				&.hover:not(.disable-hover) {
					@media screen and (max-width: 767px) {
						color: @white;
					}
				}
			}
		}
	}

	.product-card__images {
		aspect-ratio: 1;
		overflow: hidden;
		display: flex;
	}
	.product-card__img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	.product-card__details {
		position: relative;
		width: 75%;
		padding: 2rem 0 0;
	}
	.product-card__btns {
		padding: 0;
	}

	// Specific customizations to the 2x2 version
	.featured-product__slider--2x2 {
		.product-card {
			aspect-ratio: 1;
		}

		// Hide the details but let screen readers still read them
		.product-card__details {
			.sr-only();
		}
		// Remove add-to-cart button, since nobody's gonna buy a $5000 watch without even seeing the name/price
		.product-card__btns {
			display: none;
		}
	}
}
