/* 50/50 Split  */

.slider-5050 {
  background: fade(@light-sand, 50%);

  // Make the focus states use focus-visible
  .flickity-button,
  .flickity-page-dot {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 5px @light-sand;
    }
  }

  // Buttons for products sliders
  .flickity-prev-next-button {
    background-color: transparent;
    width: 2.4rem;
    height: 5.3rem;
    .flickity-button-icon {
      fill: @light-sand;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &.previous {
      // left: 1.6rem;
      display: none;
    }
    &.next {
      right: 1.6rem;
    }
    @media screen and (max-width: @sm-neg) {
      display: none;
    }
  }
}

.slider-5050__slide {
  width: 100%;
  overflow: hidden;
}

.slider-5050__image-wrapper {
  position: relative;
  margin: 0 calc(-1 * var(--pagePadding-lg));
  width: calc(100% ~"+" (2 * var(--pagePadding-lg)));

  @media screen and (min-width: @sm) {
    margin-left: 0;
    margin-right: calc(-1 ~"*" var(--pagePadding));
    width: calc(100% ~"+" var(--pagePadding));
    height: 100%;
  }

  @media screen and (min-width: 1280px){
    margin-right: calc(-1 * (100vw - var(--pageWidth)) / 2);
    width: calc(100% + (100vw - var(--pageWidth)) / 2);
  }

  &::before {
    @media screen and (min-width: @sm) {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  // Add the breakout padding for mobile sliders with product images
  &:has(.slider-5050__product) {
    @media screen and (max-width: @sm-neg) {
      padding-top: 20%;
    }
  }
}

// Floating product image if present
.slider-5050__product {
  display: block;
	position: absolute;
  object-fit: contain;
	top: 20%;
	left: 50%;
	z-index: 1;
	transform: translate(-50%, 0);
	height: 80%;
	width: 50%;

  // Hide image on "tablet" since it's too skinny to show in the centre
	@media (min-width: @sm) {
    display: none;
	}

	@media (min-width: @lg) {
    display: block;
    height: 100%;
    width: 50%;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    // This is based on the max page width. The 0.25 is because it's half of one side of the 50/50 split
    max-width: calc((var(--pageWidth) + (var(--pagePadding) * 2)) * 0.25);
    padding: 1rem; // Not related to anything, just so it's not too close to the text
	}
}

.slider-5050__image {

  @media screen and (min-width: @sm) {
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    @media screen and (min-width: @sm) {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: auto;
    }
  }
}

.slider-5050__content-wrapper {
  padding: var(--modulePadding) 0 calc(var(--modulePadding) + 5rem);

  @media screen and (min-width: @sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 19%;
    height: 100%;

    // Add more space on the fade 5050 sliders
    [data-fade] & {
      padding-right: 25%;
    }
  }
}

.slider-5050__eyebrow {
  .h6;
  color: @navy;
  margin-bottom: 1.2rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 2.5rem;
  }
}

.slider-5050__title {
  .h2;
  color: @navy;
  margin-bottom: 3rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 5rem;
  }
}

.slider-5050__text {
  .p;

  @media screen and (min-width: @sm) {
    .p-lg;
  }

  p + p {
    margin-top: 1.5rem;
  }
}

.slider-5050__link {
  .slider-5050__text+& {
      margin-top: 3rem;

      @media screen and (min-width: @sm) {
        margin-top: 4rem;
      }
    }
}

.slider-5050 {
  .flickity-page-dots {
    justify-content: flex-start;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 var(--pagePadding-lg);
    width: 100%;
    max-width: calc(var(--pageWidth) + (var(--pagePadding) * 2));

    @media screen and (min-width: @sm) {
      bottom: 6.6rem;
      padding: 0 var(--pagePadding);
    }
  }

  .flickity-page-dot {
    background: @gold;
    opacity: .3;
    margin: 0;
    width: .8rem;
    height: .8rem;
    transition: opacity .2s ease;

    & + .flickity-page-dot {
      margin-left: .8rem;
    }

    &.is-selected {
      opacity: 1;
    }
  }
}
