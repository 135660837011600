// remove hover cursor on links that don't go anywhere
html:not(.can-touch) nav a[href*="#"]:hover {
	cursor: default;
}

.nav {
	top: 0;
	background: @light-sand;
	position: relative;
	z-index: 10;

	&.fixed {
		position: sticky;
		top: -6.4rem;
		transition: transform 0.3s ease;

		&.show {
			transform: translateY(100%);
		}

		&.top {
			top: 0;
			transform: none;
			transition: none;
		}
	}


	@media (min-width: @nav-bp) {

		&.fixed {
			top: -15rem;

			&.show {
				transform: translateY(4.7rem);
			}

			&.top {
				top: 0;
				transform: translateY(-9.7rem);
				transition: none;
			}

			.nav__home-link,
			.nav__left--expanded,
			.nav__right--expanded {
				transform: scale(0);
				visibility: hidden;
			}

			.nav__left--compressed,
			.nav__right--compressed {
				transform: scale(1);
				visibility: visible;
			}
		}
	}
}

.nav__list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	transition: transform 0.3s ease, visibility 0.3s ease;

	@media (min-width: @nav-bp) {
		transition: none;

		&--level-1 {
			display: flex;
			justify-content: center;
		}

		&--2-col {
			grid-column: 1 / 3;
			column-count: 2;

			li:first-child {
				column-span: all;
			}
		}
	}
}

.nav__dropdown {
	width: 100%;
	height: calc(var(--vh, 1vh) * 100 - 64px);
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(100%);
	visibility: hidden;
	transition: visibility 0.3s ease, transform 0.3s ease;
	overflow: auto;
	padding-bottom: 18px;

	&.open {
		visibility: visible;
	}

	@media (min-width: @nav-bp) {
		background: @light-sand;
		height: auto;
		visibility: hidden;
		opacity: 0;
		top: auto;
		bottom: 0;
		transform: translateY(100%);
		border-top: 1px solid @grey;
		padding: 3.1rem 0 6.2rem;
		// grid-column-gap: 3.4rem;
		z-index: -1;
		transition: none;
		pointer-events: none;

		&.open {
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.nav__dropdown-grid {
	// The width of the grid container for the featured images
	@featuredImagesGridWidth: 64.7rem;
	display: grid;
	grid-template-rows: repeat(5, auto) 1fr auto;
	height: 100%;

	@media (min-width: @nav-bp) {
		grid-column-gap: 2.1rem;
		grid-template-columns: minmax(auto, 21.5rem) minmax(auto, 21.5rem) 1fr @featuredImagesGridWidth;
		padding-left: var(--pagePadding);
 		padding-right: var(--pagePadding);

		// If there are three nav lists, make the cards smaller
		&--three-cols {
			@featuredImagesGridWidth: 50.7rem;
			grid-template-columns: minmax(auto, 21.5rem) minmax(auto, 21.5rem) minmax(auto, 21.5rem) @featuredImagesGridWidth;
		}
	}
}

.nav__list-item {
	width: 100%;
	text-align: center;

	@media screen and (min-width: @nav-bp) {
		 width: auto;
		 text-align: left;

		 &--level-1 {

			&:not(:last-child) {
				margin-right: 3.6rem;
			}
		 }
	}

	&--mobile-only {
		@media screen and (min-width: @nav-bp) {
			display: none;
		}
	}
}

.nav__link {

	&--level-1 {
		padding: 18px var(--pagePadding);
		transition: opacity 0.3s ease;
		display: block;

		font-family: @scriptSecondaryFont;
		font-size: 1.3rem;
		font-weight: normal;
		letter-spacing: 0.78px;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
		border-bottom: 1px solid @grey;
		position: relative;

		&::after {
			content: "";
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.731' height='16.693' viewBox='0 0 8.731 16.693'%3E%3Cpath id='Path_831' data-name='Path 831' d='M110.31,2047.935l8,7.678,8-7.678' transform='translate(-2047.574 126.656) rotate(-90)' fill='none' stroke='%23172045' stroke-width='1'/%3E%3C/svg%3E%0A");
			background-size: contain;
			background-repeat: no-repeat;
			height: 16px;
			width: 8.731px;
			position: absolute;
			top: 20px;
			right: 27px;
			transition: transform 0.3s ease;
		}

		&:hover {
			&::after {
				transform: translateX(10px);
			}
		}
		&:focus-visible {
			&::after {
				transform: translateX(10px);
			}
		}

		@media (min-width: @nav-bp) {
			border: none;
			font-size: 1.2rem;
			letter-spacing: 0.78px;
			padding: 1.5rem 0;

			&::after {
				background: @gold;
				content: "";
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: 1.3rem;
				left: 0;
				top: auto;
				transform: none !important;
				opacity: 0;
				transition: opacity 0.3s ease;
			}

			&.open {

				&::after {
					opacity: 1;
				}
			}
		}
	}

	&--level-2 {
		display: inline-block;
		text-align: center;
		font-size: 1.4rem;
		line-height: 2.8rem;

		&:hover {
			text-decoration: underline;
		}
		&:focus-visible {
			text-decoration: underline;
		}

		@media (min-width: @nav-bp) {
			text-align: left;
		}
	}
}

/* Mobile menu */
.nav__toggle {
	height: 42px;
	width: 42px;
	position: relative;

	&:hover {
		span {
			background: @gold;
		}
	}
	&:focus-visible {
		span {
			background: @gold;
		}
	}

	@media screen and (min-width: @nav-bp) {
		 display: none;
	}
}

.nav__toggle-line {
	background: @navy;
	height: 2px;
	width: 20px;
	position: absolute;
	left: 11px;
	transform-origin: center;
	transition: transform 0.3s ease;

	&:nth-child(2) {
		top: 13px;
	}

	&:nth-child(3) {
		top: 21px;
	}

	&:nth-child(4) {
		top: 29px;
	}
}

.nav__header {
	width: 100%;
	position: relative;
	z-index: 1; // So the header shows when mobile menu open
	background: @light-sand;
	display: grid;
	grid-template-columns: 42px auto 42px;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	justify-content: space-between;
	padding: 10.82px var(--pagePadding);
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);

	@media screen and (min-width: @nav-bp) {
		width: 100%;
		box-shadow: none;
		padding: 2.1rem 3rem 0.5rem;
		grid-template-columns: 44rem auto 44rem;
		z-index: auto; // So that the search dropdown gets overlapped by open desktop nav
	}
}

.nav__menu {
	background-color: @light-sand;
	visibility: hidden;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	width: 100%;
	-webkit-transition: transform 0.3s ease, visibility 0.3s ease;
	transition: transform 0.3s ease, visibility 0.3s ease;
	position: fixed;
	left: 0;
	top: calc(var(--vh, 1vh) * -100);
	padding: 64px 0 0;
	transform: translateY(0);

	&::after {
		background: @grey;
		content: "";
		height: 1px;
		width: 100%;
		position: absolute;
		top: 65px;
		left: 0;
	}

	@media screen and (min-width: @nav-bp) {
		 background-color: transparent;
		 visibility: visible;
		 overflow: visible;
		 height: auto;
		 -webkit-transition: none;
		 transition: none;
		 width: auto;
		 position: static;
		 padding: 0;

		 &::after {
			content: none;
		 }
	}
}

.nav__menu-container {
	position: relative;
	height: 100%;
	// overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 18px;

	@media screen and (min-width: @nav-bp) {
		 overflow: visible;
		 height: auto;
		 padding: 0;
		 display: grid;
		 grid-template-columns: 8rem 1fr 8rem;
		 padding: 0 4rem;
		 background: @light-sand;
	}
}


/* Open mobile nav */

.nav.open {
	.nav__menu {
		 visibility: visible;
		 transform: translateY(100%);
		 pointer-events: auto;
	}

	.nav__toggle-line {

		&:nth-child(2) {
			transform: rotate(45deg) translate(5.5px, 6px) scaleX(1.03);
		}

		&:nth-child(3) {
			transform: scaleX(0);
		}

		&:nth-child(4) {
			transform: rotate(-45deg) translate(5.5px, -5.5px) scaleX(1.03);
		}
	}

	@media (min-width: @nav-bp) {

		.nav__menu {
			visibility: visible;
			transform: none;
			pointer-events: auto;
	  }
	}
}

.nav__menu.sub-menu-open {
	.nav__list--level-1,
	.nav__menu-footer--main,
	.nav__mobile-certification {
		transform: translateX(-100%);
		visibility: hidden;
	}

	@media (min-width: @nav-bp) {

		.nav__list--level-1,
		.nav__menu-footer--main,
		.nav__mobile-certification {
			transform: none;
			visibility: visible;
		}
	}
}


/* Custom Styles */

.nav__logo {
	height: 32px;
	width: auto;
	display: block;

	@media (min-width: @nav-bp) {
		height: 5.044rem;
	}
}

.nav__search {
	padding: 13px;
	margin-left: -13px;

	& svg {
		display: block;
	}

	&:hover {
		color: @gold;

		svg path {
			fill: @gold;
		}
	}
	&:focus-visible {
		color: @gold;

		svg path {
			fill: @gold;
		}
	}

	@media (min-width: @nav-bp) {
		display: flex;
		padding: 0;
		margin: 0;
	}
}

.nav__search-text {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;

	font-family: @scriptSecondaryFont;
	font-size: 1.3rem;
	font-weight: normal;
	letter-spacing: -0.013rem;
	line-height: 1.3333;

	@media (min-width: @nav-bp) {
		display: block;
		position: static;
		width: auto;
		height: auto;
		margin: 0 0 0 11px;
		clip: none;
	}
}

.nav__cta-btn {
	display: none;

	@media (min-width: @nav-bp) {
		display: block;
		margin-left: 3.5rem;
	}
}

.nav__mobile-certification {
	width: 100%;
	margin: auto auto 0;
	padding-top: 1.8rem;
	transform: none;
	visibility: visible;
	text-align: center;
	transition: transform 0.3s ease, visibility 0.3s ease;

	@media screen and (min-width: @nav-bp) {
		display: none;
	}
}

.nav__certification {
	display: block;
	width: 15rem;
	height: 7.7rem;
	margin: 0 auto;

	@media (min-width: @nav-bp) {
		margin: 0 0 0 2.6rem;
		transition: none;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	&--desktop {
		display: none;

		@media screen and (min-width: @nav-bp) {
			display: block;
		}
	}

	&--mobile {
		@media screen and (min-width: @nav-bp) {
				display: none;
			}
	}
}

.nav__icon-link {
	display: none;
	padding: 0.7rem;
	position: relative;

	@media (min-width: @nav-bp) {
		display: block;
	}

	& svg {
		display: block;
		width: auto;
		height: 1.7rem;
	}

		&:first-of-type {

			// svg {
			// 	width: 1.663rem;
			// }

			&::after {
				content: "";
				background: @chocolate;
				height: 1rem;
				width: 1rem;
				border-radius: 100%;
				position: absolute;
				top: 0.7rem;
				right: 0.5rem;
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}

		&--cart {
			display: block;

			&::after {
				content: "";
				background: @burgundy;
				height: 0.7rem;
				width: 0.7rem;
				border-radius: 100%;
				position: absolute;
				bottom: 0.5rem;
				right: 0.7rem;
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}

		&:hover {
			&::after {
				opacity: 1;
			}
		}

		&:focus-visible {
			&::after {
				opacity: 1;
			}
		}

	&--cart {
		&.has-items {
			&:after {
				opacity: 1;
			}
		}
	}
}

.nav__menu-footer {
	background: @white;
	border-radius: 4px;
	display: flex;
	justify-content: space-around;
	padding: 18px 0;
	margin: 0 15px;
	transition: transform 0.3s ease, visibility 0.3s ease;

	&--main {
		margin-top: 18px;
	}

	&--sub {
		grid-row: 7 / 8;
		margin-top: 32px;
	}

	@media (min-width: @nav-bp) {
		display: none;
	}
}

.nav__footer-link {
	text-align: center;
	display: block;
	width: 108px;

	&:hover {
		text-decoration: underline;
		color: @gold;

		svg path[fill="#172045"] {
			fill: @gold;
		}

		svg path[stroke="#172045"] {
			stroke: @gold;
		}
	}
	&:focus-visible {
		text-decoration: underline;
		color: @gold;

		svg path[fill="#172045"] {
			fill: @gold;
		}

		svg path[stroke="#172045"] {
			stroke: @gold;
		}
	}
}

.nav__footer-link-text {
	display: block;
	font-family: @primaryFont;
	font-size: 1.4rem;
	letter-spacing: -0.14px;
	line-height: noraml;
	margin: 0 15px;
}

.nav__footer-link-icon {
	display: block;
	margin: 0 auto .5rem;
	height: 2.5rem;
	max-width: 2.5rem;
	position: relative;

	svg {
		height: 100%;
		width: auto;
	}

	.nav__footer-link--cart & {
		&::after {
			content: "";
			background: @burgundy;
			height: 0.9rem;
			width: 0.9rem;
			border-radius: 100%;
			position: absolute;
			bottom: -0.3rem;
			right: 0.1rem;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
	}

	.nav__footer-link--cart.has-items & {
		&:after {
			opacity: 1;
		}
	}
}

.nav__back-btn {
	font-family: @scriptSecondaryFont;
	font-size: 1.3rem;
	font-weight: normal;
	letter-spacing: 0.78px;
	line-height: normal;
	text-transform: uppercase;
	text-align: center;
	display: block;
	background: @white;
	border-bottom: 1px solid @grey;
	width: 100%;
	padding: 18px var(--pagePadding);
	position: relative;
	color: currentColor;

	&::after {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.731' height='16.693' viewBox='0 0 8.731 16.693'%3E%3Cpath id='Path_840' data-name='Path 840' d='M110.31,2047.935l8,7.678,8-7.678' transform='translate(2056.306 -109.963) rotate(90)' fill='none' stroke='%23172045' stroke-width='1'/%3E%3C/svg%3E ");
		background-size: contain;
		background-repeat: no-repeat;
		width: 8.731px;
		height: 16.693px;
		position: absolute;
		left: 28px;
		top: 20px;
		transition: transform 0.3s ease;
	}

	&:hover {
		&::after {
			transform: translateX(-10px);
		}
	}
	&:focus-visible {
		&::after {
			transform: translateX(-10px);
		}
	}

	@media (min-width: @nav-bp) {
		display: none;
	}
}

.nav__list-heading {
	font-family: @scriptSecondaryFont;
	font-size: 1.4rem;
	font-weight: 700;
	letter-spacing: normal;
	line-height: 1.3333;
	display: block;
	text-align: center;
	margin: 26px 0 15px;

	@media (min-width: @nav-bp) {
		font-size: 1.5rem;
		text-align: left;
		margin: 3.3rem 0 2.2rem;
	}
}

.nav__all-link-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 24px;

	// Force it to move to the bottom on mobile
	@media (max-width: @nav-neg) {
		order: 1;
	}

	@media (min-width: @nav-bp) {
		grid-area: 2 / 1 / 3 / 3;
		justify-content: flex-start;
	}

	&--multiple {
		margin-top: 4px;
		@media (min-width: @nav-bp) {
			margin-top: 0;
			// If there are three nav lists, make each link take up a third of the space
			.nav__dropdown-grid--three-cols & {
				width: 33.3333%;
			}
		}
	}
}

.nav__all-link-wrapper-multiple {
	grid-column: 1;
	column-count: 1;
	padding: 1rem 0;
	margin-top: 24px;

	@media (min-width: @nav-bp) {
		grid-column: 1 / 3;
		column-count: 2;
		padding: 1rem 0;

		display: flex;
		justify-content: space-between;
		// If there are three nav lists, fill them all
		.nav__dropdown-grid--three-cols & {
			grid-column: 1 / 4;
			column-count: 3;
		}
	}
}

.nav__left {
	display: flex;
	align-items: center;

	&--compressed {
		display: none;
	}

	@media (min-width: @nav-bp) {
		transition: transform 0.3s ease, visibility 0.3s ease;

		&--compressed {
			display: flex;
			visibility: hidden;
			transform: scale(0);
		}
	}
}

.nav__right {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&--compressed {
		display: none;
	}

	@media (min-width: @nav-bp) {
		transition: transform 0.3s ease, visibility 0.3s ease;

		&--compressed {
			display: flex;
			visibility: hidden;
			transform: scale(0);
		}
	}
}

.nav__home-link {

	@media (min-width: @nav-bp) {
		transition: transform 0.3s ease, visibility 0.3s ease;
	}
}

/* Nav feature cards */

.nav__feature {
	display: none;

	@media (min-width: @nav-bp) {
		display: block;
		grid-area: 1 / 4 / 3 / 5;
		padding-left: 5%;
	}

	@media (min-width: 1400px) {
		padding-left: 14.6%;
	}

	&--filters {
		display: block;
	}
}

.nav__feature-wrapper {
	width: 105%;
	display: grid;

	&--double {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2.4rem;
		align-items: start;
	}

	&--filters {
		width: 100%;
		display: flex;
		flex-direction: column;

		@media screen and (min-width: @nav-bp) {
			flex-direction: row;
			gap: 4.5rem;
		}
	}

	@media (min-width: 1400px) {
		width: 114.6%;
	}
}

.nav__feature-title {
	grid-column: 1 / -1;
	font-family: @scriptSecondaryFont;
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: normal;
	line-height: 1.3333;
	margin: 3.3rem 0 2.2rem;

	&--filters {
		@media screen and (min-width: @nav-bp) {
			border-bottom: 1px solid @raffi-grey;
			padding-bottom: 0.5rem;
		}
	}
}

.nav__feature-column {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: @nav-neg) {
		align-items: center;
	}
}

ul.nav__feature-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
}

.nav__feature-icon {
	height: 2.3rem;
	width: 4rem;
	object-fit: contain;
}

.nav__feature-link {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	&--filters {
		font-size: 1.4rem;
		gap: 1.2rem;

		&:hover,
		&:focus-visible {
			text-decoration: underline;
		}
		// Centre align on mobile if no icon
		justify-content: start;
		@media screen and (max-width: @nav-neg) {
			&:not(:has(.nav__feature-icon)) {
				justify-content: center;
			}
		}
	}
}

a.nav__feature-link {

	&:hover {
		.nav__feature-img {
			transform: scale(1.1);
		}
		.link-arrow {
			text-decoration: underline;
		}
	}
	&:focus-visible {
		.nav__feature-img {
			transform: scale(1.1);
		}
		.link-arrow {
			text-decoration: underline;
		}
	}
}

.nav__feature-card {
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	height: 24rem;
	margin-bottom: 2.5rem;
	width: 100%;

	&--resource {
		&::after {
			content: "";
			// background: linear-gradient(181deg, #EBEBEB00 0%, #7676767B 100%)
			background: linear-gradient(180deg, rgba(235,235,235,0) 0%, rgba(235,235,235,0) 62%, rgba(118,118,118,1) 100%);
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			mix-blend-mode: multiply;
		}
	}

	// If there are three nav lists, make the cards smaller
	.nav__dropdown-grid--three-cols & {
		max-width: 24rem;
	}
}

.nav__feature-img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	transition: transform 0.3s ease;
}

.nav__feature-text-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;

	:last-child:not(:first-child) {
		margin-left: 2rem;
	}
}

.nav__feature-text {
	font-family: @scriptSecondaryFont;
	font-size: 1.4rem;
	font-weight: normal;
	letter-spacing: 0.056rem;
	line-height: normal;
	text-transform: uppercase;

	&--resource {
		color: @white;
		position: absolute;
		left: 2.4rem;
		bottom: 1.5rem;
		right: 2.4rem;
		z-index: 1;
	}
}


/* Search dropdown */
.nav__s-box {
	background: @white;
	position: absolute;
	left: 0;
	top: 6.4rem;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;

	nav.open & {
		height: calc(var(--vh, 1vh) * 100 - 6.4rem;);
	}

	@media (min-width: @nav-bp) {
		top: 14.4rem;

		nav.open & {
			height: auto;
		}
	}
}

.nav__s-box-wrapper {
	padding-top: 5.6rem;
	padding-bottom: 5.6rem;
	max-width: calc(76.8rem + (var(--pagePadding) * 2));
	margin: 0 auto;
}

.nav__s-box-close {
	position: absolute;
	left: 50%;
	bottom: 1.4rem;
	transform: translateX(-50%);
	padding: 1rem;

	svg {
		transition: transform 0.3s ease;
		display: block;
		width: 1.4919rem;
		height: 0.788rem;
	}

	&:hover {
		svg {
			transform: translateY(-10px);
		}
	}
	&:focus-visible {
		svg {
			transform: translateY(-10px);
		}
	}
}

// Tudor plaque in nav on Tudor page(s)
.tudor-plaque {
	// width: 15rem;
	height: 7rem;
	aspect-ratio: 2;

	&--desktop {
		flex-shrink: 0;
		margin-right: 3rem;

		@media screen and (max-width: @nav-neg) {
			display: none;
		}
	}

	&--mobile {
		margin: 24px auto 0;

		@media screen and (min-width: @nav-bp) {
			display: none;
		}
	}
}
