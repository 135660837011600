.footer {
	background: @light-sand;
	padding-top: var(--pagePadding-sm);
}

/* Footer CTA */

.footer__cta {
	display: block;
	width: 100%;
	color: @white;
	border-radius: 4px;
	position: relative;
	padding: 2rem 2rem 2.4rem;
	overflow: hidden;
	background-color: @slate;
	margin-bottom: var(--pagePadding-sm);

	@media (min-width: @sm) {
		padding: 4.1rem 3rem;
	}

	@media (min-width: @md) {
		margin-bottom: 4.4rem;
	}
}

a.footer__cta,
button.footer__cta {

	&:hover {
		.footer__cta-bkg {
			transform: scale(1.1);
		}
	}
	&:focus-visible {
		.footer__cta-bkg {
			transform: scale(1.1);
		}
	}
}

.footer__cta-bkg {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: transform 0.3s ease;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(0deg, rgba(36,36,36,0.87) 0%, rgba(0,0,0,0.17) 100%);
	}
}

.footer__cta-eyebrow {
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	@media (min-width: @sm) {
		&:not(:last-child) {
			margin-bottom: 2.1rem;
		}
	}
}

.footer__cta-title {
	font-family: @scriptPrimaryFont;
	font-size: 3.2rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 4rem;
	text-transform: uppercase;
	max-width: 53.6rem;
	margin: 0 auto;

	@media (min-width: @sm) {
		font-size: 4.8rem;
		line-height: 5.6rem;
	}
}

/* Footer Top */
.footer__top {

	@media (min-width: @md) {
		display: grid;
		grid-template-columns: auto 1fr minmax(auto, 48rem);
		grid-column-gap: 2rem;
		align-items: start;
		padding: 4.6rem calc(6.4rem - var(--pagePadding-sm));
	}
}

/* Footer Newsletter signup */

.footer__email-box {
	background: @sand;
	border-radius: 4px;
	padding: 1.3rem 1.6rem 3rem;

	@media (min-width: @md) {
		grid-area: 1 / 3 / 2 / 4;
		padding: 2.5rem 3.2rem 3.5rem;
	}
}

.footer__email-heading {
	color: @slate;
	font-family: @scriptPrimaryFont;
	font-size: 2rem;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 2.4rem;
	text-transform: none;
	margin-bottom: 2rem;

	@media (min-width: @sm) {
		font-size: 2.4rem;
		line-height: 3rem;
	}

	@media (min-width: @md) {
		margin-bottom: 1.2rem;
	}
}

/* Footer Nav/Menu */
.footer__nav {
	padding: 4.2rem 0 0;

	@media (min-width: @sm) {
		align-items: start;
		display: grid;
		grid-template-columns: minmax(auto, 20.5rem) minmax(auto, 40rem);
		padding: 4.2rem 0;
	}

	@media (min-width: @md) {
		padding: 0;
	}
}

.footer__logo-link {
	display: block;
}

.footer__logo {
	display: block;
	height: auto;
	width: 14.6rem;

	&--desktop {
		display: none;
	}

	@media (min-width: @sm) {
		width: 7.8rem;

		&--mobile {
			display: none;
		}
		&--desktop {
			display: block;
		}
	}
}

.footer__social {

	@media (min-width: @sm) {
		width: 100%;
		margin-top: 3rem;
	}
}

.footer__social-link {
	display: block;

	svg {
		display: block;
		transition: transform 0.3s ease;
		height: auto;
	}

	&:not(:last-child) {
		margin-right: 1.5rem;
	}

	&:hover {
		svg {
			transform: scale(1.1);
		}
	}
	&:focus-visible {
		svg {
			transform: scale(1.1);
		}
	}

	@media (min-width: @xxl) {
		transform: scale(1.4);

		&:not(:last-child) {
			margin-right: 2.1rem;
		}
	}
}

.footer__userway {
	margin-top: 0;
	display: flex;
	@media screen and (min-width: @sm) {
		margin-top: 3rem;
	}
}

.footer__userway-btn {
	.btn-reset();
	border-radius: 100%;
	background-color: @navy;
	width: 3.4rem;

	&:hover, &:focus-visible {
		transform: scale(1.1);
		transition: transform 0.1s ease;
	}
}

.footer__menu {
	margin: 0;
	padding: 3.8rem 0;
	list-style: none;
	column-count: 2;

	@media (min-width: @sm) {
		padding: 0;
	}
}

.footer__menu-link {

	&:hover {
		text-decoration: underline;
	}
	&:focus-visible {
		text-decoration: underline;
	}

	@media (min-width: @sm) {
		font-size: 1.3rem;
	}
}

/* Footer Middle */
.footer__middle {
	border-top: 1px solid @warm-grey;
	border-bottom: 1px solid @warm-grey;
	padding: 3rem 0;

	@media (min-width: @md) {
		padding: 0 calc(6.4rem - var(--pagePadding-sm));
		border: none;
		position: relative;

		&::before {
			background: @warm-grey;
			content: "";
			height: 1px;
			width: calc(100% - ((6.4rem - var(--pagePadding-sm)) * 2));
			position: absolute;
			top: 0;
			left: calc(6.4rem - var(--pagePadding-sm));
		}

		&::after {
			background: @warm-grey;
			content: "";
			height: 1px;
			width: calc(100% - ((6.4rem - var(--pagePadding-sm)) * 2));
			position: absolute;
			bottom: 0;
			left: calc(6.4rem - var(--pagePadding-sm));
		}
	}
}

/* Footer Locations */
.footer__location-grid {
	font-size: 1.2rem;
	line-height: 1.9rem;
	color: @slate;

	&:not(:last-child) {
		padding-bottom: 3rem;
		margin-bottom: 3rem;
		border-bottom: 1px solid @warm-grey;
	}

	@media (min-width: 500px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2rem;
	}

	@media (min-width: @sm) {
		font-size: 1.4rem;
		line-height: 2rem;
	}

	@media (min-width: @md) {
		padding: 4.2rem 0;

		&:not(:last-child) {
			padding-bottom: 4.2rem;
			padding-right: 2.4rem;
			margin-bottom: 0;
			border-bottom: 0;
			border-right: 1px solid @warm-grey;
		}

		&:last-child {
			padding-left: 4.4rem;
		}
	}
}

.footer__location-title {
	font-family: @scriptPrimaryFont;
	font-size: 2rem;
	font-weight: normal;
	letter-spacing: -0.02rem;
	line-height: 2.4rem;
	text-transform: none;
	margin-bottom: 1.5rem;
}

.footer__location-address {
	font-style: normal;
}

.footer__location-phone {
	&:hover {
		text-decoration: underline;
	}
	&:focus-visible {
		text-decoration: underline;
	}
}

.footer__location-email {
	color: @gold;
	text-decoration: underline;
	display: block;
	margin-top: 1.5rem;

	&:hover {
		text-decoration: none;
	}
	&:focus-visible {
		text-decoration: none;
	}

	@media (min-width: @md) {
		margin-top: 2rem;
	}
}

.footer__location-hours {
	margin-top: 3rem;

	@media (min-width: 500px) {
		margin: 0;
	}
}

.footer__location-sub-title {
	text-transform: none;
	letter-spacing: normal;

	@media (min-width: 500px) {
		font-family: @scriptPrimaryFont;
		font-size: 2rem;
		font-weight: normal;
		letter-spacing: -0.02rem;
		line-height: 2.4rem;
		text-transform: none;
		margin-bottom: 1.5rem;
	}
}

/* Footer Bottom */
.footer__bottom {
	padding: 2.1rem 0 3.7rem;

	@media (min-width: @sm) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 2.5rem;
		grid-column-gap: 1rem;
		align-items: center;
	}

	@media (min-width: @md) {
		padding: 2rem calc(6.4rem - var(--pagePadding-sm)) 3.4rem;
	}

	@media (min-width: @lg) {
		grid-template-columns: 1fr auto 1fr;
	}
}

/* Footer Logos */
.footer__related-logos {
	width: calc(100% + 2.6rem);
	margin-left: -1.3rem;

	@media (min-width: @sm) {
		grid-area: 1 / 1 / 2 / 3;
	}

	@media (min-width: @lg) {
		grid-area: 1 / 2 / 2 / 3;
	}
}

.footer__related-logo {
	display: block;
	height: auto;
	margin: 0.5rem 1.3rem;

	&--gia {
		width: 8.1rem;
	}

	&--ags {
		width: 12rem;
	}

	&--jvc {
		width: 12.5rem;
	}

	&--cja {
		width: 12rem;
	}

	&--bbb {
		width: 3.3rem;
	}
}

/* Footer Terms */
.footer__policies {
	margin-top: 2.5rem;

	@media (min-width: @sm) {
		grid-area: 2 / 2 / 3 / 3;
		text-align: right;
		margin: 0;
	}

	@media (min-width: @lg) {
		grid-area: 1 / 3 / 2 / 4;
	}
}

.footer__policies-link {
	text-decoration: underline;
	color: #7B7B7B;
	font-family: @primaryFont;
	font-size: 1.2rem;
	font-weight: 400;
	letter-spacing: -0.012rem;
	line-height: 1.5;
	text-transform: none;
	display: inline-block;

	&:not(:last-child) {
		margin-right: 2.2rem;
	}

	&:hover {
		text-decoration: none;
	}
	&:focus-visible {
		text-decoration: none;
	}

	@media (min-width: @sm) {
		letter-spacing: none;
	}
}

/* Footer Copyright */
.footer__copyright {
	color: #7B7B7B;
	font-family: @primaryFont;
	font-size: 1.2rem;
	font-weight: 400;
	letter-spacing: -0.012rem;
	line-height: 1.5;
	text-transform: none;
	margin-top: 0.6rem;

	@media (min-width: @sm) {
		letter-spacing: none;
		margin: 0;
		grid-area: 2 / 1 / 3 / 2;
	}

	@media (min-width: @lg) {
		grid-area: 1 / 1 / 2 / 2;
	}
}
