.filters {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: ~"calc(var(--vh, 1vh) * 100)";
  left: 0;
  top: 0;
  z-index: 5; // make sure this is lower than nav

  pointer-events: none;

  @media screen and (min-width: @sm) {
    z-index: 99;
  }

  &::before {
    content: '';
  }

  &.filters--open {
    pointer-events: all;
  }
}

.filters__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  background: fade(@slate, 20%);
  opacity: 0;
  transition: opacity .2s ease, visibility .2s ease;

  .filters--open & {
    opacity: 1;
    visibility: visible;
  }
}

.filters__inner {
  background: @white;
  max-width: 32.8rem;
  width: 87%;
  padding: var(--pagePadding) 5rem 6rem var(--pagePadding);
  padding-top: 6rem;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  // position: absolute;
  left: 0;
  transform: translateX(-100%);
  transition: transform .4s ease;

  .body--nav-sticky & {
    @media screen and (max-width: @sm-neg) {
      padding-top: 12.5rem;
    }
  }

  @media screen and (min-width: @sm) {
    padding-top: 10rem;
    max-width: 40rem;
    padding-right: var(--pagePadding);
  }

  .filters--open & {
    transform: none;
    transition-delay: .1s;
  }

  // .body--announcement-banner & {
  //   @media screen and (max-width: @sm-neg) {
  //     padding-top: 18rem;
  //   }
  // }
}

.filters__filter-title {
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 0.078rem;
  font-family: @primaryFont;
  font-weight: 400;
}

.filters__range {
  position: relative;
  z-index: 0;

  .ais-RangeSlider {

    .rheostat {
      margin: 40px 0 30px;
    }

    .rheostat-handle {
        background: transparent;
        border: none;
        top: -8px;

        &::before {
          content: '';
          display: block;
          width: .8rem;
          height: .8rem;
          background: @slate;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0px 0px 0px 2px @white;
            -webkit-box-shadow: 0px 0px 0px 2px @white;
            -moz-box-shadow: 0px 0px 0px 2px @white;
        }
      }

      .rheostat-progress {
        background: @slate;
        height: 3px;
      }

      .rheostat-background {
        border: none;
        background: @warm-grey;
        height: 3px;
      }

      .rheostat-tooltip {
        width: max-content;
        font-size: 1.2rem;
      }
  }
}

.filters__accordion{
  list-style: none;
  margin: 0;
  padding: 0;
}

.filters__accordion-item {
  border-top: 1px solid @warm-grey;
}

.filters__accordion-toggle {
  display: block;
  padding: 1rem 3rem 1rem 0;
  position: relative;
  width: 100%;
  text-align: left;
  color: @slate;

  &::after {
    content: '';
    width: 1.4rem;
    height: .7rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.919' height='7.88' viewBox='0 0 14.919 7.88'%3E%3Cpath d='M110.31,2047.935l7.113,6.826,7.113-6.826' transform='translate(-109.963 -2047.575)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E") center / contain no-repeat;
  }

  &.open {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.filters__accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;
}

.filters__accordion-item--instantSearchPanel {
  border: none;

  .ais-Panel-header {
    border-top: 1px solid @warm-grey;
  }

  .ais-RangeSlider {
    // Add space to the edges since the numbers get cut off at the sides
    margin: 0 2.4rem;
  }
  .ais-Panel-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
  }
}

.filters__checklist {

  &--links {
    list-style: none;
    margin: 0;
    padding: 1rem 0 2.4rem;
  }

  .filters__checklist-link {
    min-height: 1.6rem;
    position: relative;
    font-size: 1.3rem;
    letter-spacing: 0.021rem;
    color: @dark-grey;
    line-height: 1.6rem;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration .2s ease;

    @media(hover: hover) and (pointer: fine) {

        &:hover {
          text-decoration-color: inherit;
        }

        &:focus-visible {
          text-decoration-color: inherit;
        }
      }
  }

  .ais-RefinementList-item + .ais-RefinementList-item {
    margin-top: .8rem;
  }

  .ais-RefinementList {
    padding: 1rem 0 2.4rem;
  }

  .ais-RefinementList-item {
    font-size: 0;
    position: relative;
  }
  input[type="checkbox"] {
    width: 0;
    height: 0;
    appearance: none;
    -webkit-appearance: none;
  }
  .ais-RefinementList-labelText {
    display: inline-block;
    padding-left: 3.2rem;
    min-height: 1.6rem;
    position: relative;
    font-size: 1.3rem;
    letter-spacing: 0.021rem;
    color: @dark-grey;
    line-height: 1.6rem;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::before {
      border: 1px solid @warm-grey;
      background: @white;
    }

    &::after {
      opacity: 0;
      transition: opacity .1s ease;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.568 7.509'%3E%3Cpath d='m.355 4.373 2.412 2.428L9.214.354' fill='none' stroke='%23242424'/%3E%3C/svg%3E");
      background-size: 1rem auto;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .ais-RefinementList-checkbox:checked + .ais-RefinementList-labelText {
    &::after {
      opacity: 1;
    }
  }
  .ais-RefinementList-count {
    display: none;
  }
}

.filters__footer {
  display: flex;
  align-items: center;
  margin-top: 3rem;

  .btn {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    margin-right: .8rem;
  }

  > * {
    flex-grow: 1;
  }
}

.filters__toggle {

  .ais-ToggleRefinement {
    padding: 1rem 0;
  }

  input[type="checkbox"] {
    width: 0;
    height: 0;
    position: absolute;
    appearance: none;
    -webkit-appearance: none;
  }

  .ais-ToggleRefinement-labelText {
      display: block;
      padding-right: 3.2rem;
      min-height: 1.6rem;
      position: relative;
      line-height: 1.6rem;
      cursor: pointer;

      text-transform: uppercase;
      font-size: 1.3rem;
      letter-spacing: 0.078rem;
      font-family: @primaryFont;
      font-weight: 400;

      &::before,
      &::after {
        content: '';
        width: 1.6rem;
        height: 1.6rem;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
      }

      &::before {
        border: 1px solid @warm-grey;
        background: @white;
      }

      &::after {
        opacity: 0;
        transition: opacity .1s ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.568 7.509'%3E%3Cpath d='m.355 4.373 2.412 2.428L9.214.354' fill='none' stroke='%23242424'/%3E%3C/svg%3E");
        background-size: 1rem auto;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .ais-ToggleRefinement-checkbox:checked+.ais-ToggleRefinement-labelText {
      &::after {
        opacity: 1;
      }
    }
}

.filters {
  .ais-ClearRefinements-button {
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    font-family: @scriptSecondaryFont;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: none;
    padding: 0.8rem 1.4rem;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    border-color: @gold;
    color: @gold;
    background: transparent;
    width: 100%;
    transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: @gold;
        color: @white;
      }

      &:focus-visible {
        background: @gold;
        color: @white;

      }
    }
  }
}
