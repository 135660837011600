
.page-card {
  width: 100%;
  @media screen and (min-width: @sm) {
    display: flex;
  }
}

.page-card__image-link {
  position: relative;
  display: block;
  width: 12rem;
  border-radius: .4rem;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 1.5rem;

  @media screen and (min-width: @sm) {
    width: 16.8rem;
    margin-bottom: 0;
    margin-right: 4rem;
  }

  &:before {
    content: '';
    padding-bottom: 100%;
    display: block;
  }
}

.page-card__image {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}

.page-card__title {
  font-family: @primaryFont;
  font-size: 1.9rem;
  font-weight: 400;
}

.page-card__breadcrumbs {
  font-size: 1rem;
  margin-bottom: 1.6rem;
  display: block;

  span {
    &:not(:first-of-type) {
      color: #707070;
      &:before {
        content: '>';
        display: inline-block;
        margin: 0 .1rem;
      }
    }
   
  }
}

.page-card__summary {
  .p;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 1.5rem;
}