.resource-card {
  padding: 1.6rem;
  background: @white;
  opacity: 1;
  transition: opacity .3s ease;

  &--hidden {
    opacity: 0;
  }
}

.resource-card__image-link {
  display: block;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    padding-bottom: 50%;
    display: block;
  }
}

.resource-card__img {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

.resource-card__details {
  padding: 1.1rem 3.2rem;
}

.resource-card__title {
  .p-lg;
  font-weight: 500;
}

.resource-card__byline {
  font-size: 1.1rem;
  color: fade(@slate, 70%);
  margin-top: .4rem;
}

.resource-card__summary {
  .p;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 1.5rem;
}

.resource-card__link {
  margin-top: 1.7rem;
}