.product-card {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: pan-y;

  @media(hover: hover) and (pointer: fine) {

    &.hover:not(.disable-hover) {
      .product-card__img--primary {
        transform: scale(1.1);
      }
    }

    &.hover {
      .product-card__add-to-cart {
        opacity: 1;
      }
    }
  }

  &--multi-image {
    &.hover:not(.disable-hover) {
      .product-card__img--primary {
        transform: none;
      }

      .product-card__img--secondary {
        opacity: 1;
      }
    }
  }

  &--lifestyle-image {
    &.hover:not(.disable-hover) {
      color: @white;

      .product-card__images:after {
        opacity: 1;
      }

      .product-cards--list-view & {
        @media screen and (max-width: @sm-neg) {
          color: @black;
        }
      }
    }
  }

  &--sm {
    &.hover:not(.disable-hover) {
      @media screen and (max-width: @sm-neg) {
          .product-card__img--primary {
            transform: scale(1.1) translate(-50%, -50%);
          }
        }
    }
  }

  &--sm.product-card--lifestyle-image {
    &.hover:not(.disable-hover) {
      @media screen and (max-width: 767px) {
        color: @black;
      }
    }
  }
}

.product-card__title {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: @primaryFont;
  margin-bottom: .5rem;
}

.product-card__details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  padding: 1.6rem 0 1.6rem 2.4rem;

  .product-cards--list-view & {
    @media screen and (max-width: @sm-neg) {
      top: 0;
      bottom: auto;
      left: @productCardListViewHeight;
      width: calc(~"100% - @{productCardListViewHeight}");
      max-width: 50rem;
      padding: 1.7rem 1.5rem .8rem;
    }
  }
}

.product-card__btns {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 0 2.4rem 2.7rem 0;

  .product-cards--list-view & {
    @media screen and (max-width: @sm-neg) {
      flex-direction: row;
      bottom: 1.5rem;
      width: calc(~"100% - @{productCardListViewHeight}");
      padding: 0 1.5rem 0 .7rem;
    }
  }
}

.product-card__images {
  display: block;
  height: 100%;
  width: 100%;

  .product-card--lifestyle-image & {
    &:after {
      content: '';
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
      opacity: 0;
      transition: opacity .2s ease;

      .product-cards--list-view & {
        @media screen and (max-width: @sm-neg) {
          display: none;
        }
      }
    }
  }

}

.product-card__img {
  &--primary {
    width: 75%;
    margin: 0 auto;
    transform: none;
    transition: transform .2s ease;

    .product-cards--list-view & {
      @media screen and (max-width: @sm-neg) {
        padding: .8rem 0;
        width: @productCardListViewHeight;
        height: @productCardListViewHeight;
        margin: 0;
        object-fit: contain;
      }
    }
  }

  &--secondary {
    position: absolute;
    top: 0;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .2s ease;

    .product-cards--list-view & {
      @media screen and (max-width: @sm-neg) {
        padding: .8rem 0;
        width: @productCardListViewHeight;
        height: @productCardListViewHeight;
        margin: 0;
        object-fit: contain;
        left: 0;
        transform: none;
      }
    }
  }

  .product-card--lifestyle-image &--secondary{
    width: 100%;

    .product-cards--list-view & {
      @media screen and (max-width: @sm-neg) {
        padding: 0;
        width: @productCardListViewHeight;
      }
    }
  }
}

.product-card__price {
  font-size: 1.4rem;
  font-weight: 400;

  &--hidden {
    font-size: 1.2rem;
  }

  strike {
    margin-right: 0.5rem;
  }
}

.product-card__add-to-cart {
  margin-bottom: 1.6rem;

  @media(hover: hover) and (pointer: fine) {
    opacity: 0;
    transition: opacity .2s ease;
  }

  &.hidden {
    opacity: 0;
  }

  .product-cards--list-view & {
    @media screen and (max-width: @sm-neg) {
      order: 1;
      margin: 0 0 -0.4rem auto;
    }
  }
}

.product-card__badge {
  &--new {
    border: 1px solid @light-gold;
    border-radius: .2rem;
    background: @light-sand;
    color: @gold;
    font-family: @scriptSecondaryFont;
    font-weight: 600;
    font-size: 1.3rem;
    display: block;
    padding: .2rem 1rem;
    position: absolute;
    top: 2.4rem;
    left: 2.4rem;
    z-index: 1;

    .product-cards--list-view & {
      @media screen and (max-width: @sm-neg) {
        top: 1.6rem;
        left: 1.6rem;
      }
    }
  }
}

.product-card__img-button {
  opacity: 0;
  width: 4.2rem;
  height: 1.6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  top: 50%;
  visibility: visible;
  transform: translateY(-50%);
  transition: opacity .2s ease, visibility .2s ease;

  .product-card.disable-hover & {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .product-card--multi-image & {
    opacity: 1;

    &[disabled] {
      opacity: .3;
    }
  }

  .product-cards--list-view & {
    display: none;
  }

  &--prev {
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.482 30.763' fill='none' stroke='%23242424'%3E%3Cpath d='M15.122.346.693 15.381l14.429 15.035'/%3E%3C/svg%3E");

    .product-card--lifestyle-image.hover & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.482 30.763' fill='none' stroke='%23fff'%3E%3Cpath d='M15.122.346.693 15.381l14.429 15.035'/%3E%3C/svg%3E");
    }
  }

  &--next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.482 30.763' fill='none' stroke='%23242424'%3E%3Cpath d='M.361 30.416 14.79 15.381.361.346'/%3E%3C/svg%3E");
    right: 0;

    .product-card--lifestyle-image.hover & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.482 30.763' fill='none' stroke='%23fff'%3E%3Cpath d='M.361 30.416 14.79 15.381.361.346'/%3E%3C/svg%3E");
    }
  }

  @media (pointer: fine) {
    display: none;
  }
}
