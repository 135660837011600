.slider-cta {
	overflow: hidden;
}

.slider-cta__title {
	font-family: @scriptSecondaryFont;
	font-size: 1.4rem;
	font-weight: noraml;
	letter-spacing: normal;
	line-height: 2.4rem;
	text-transform: uppercase;
	margin-bottom: 2rem;
	color: @navy;

	@media (min-width: @sm) {
		font-size: 2rem;
		line-height: 2.9rem;
		margin-bottom: 4.2rem;
	}
}

.slider-cta__slider {
	width: calc(100% + 4.8rem);
	margin-left: -2.4rem;

	.flickity-page-dots {
		bottom: 0;
		max-width: calc(100vw - (var(--pagePadding) * 2));
		left: 2.3rem;

		@media (min-width: @sm) {
			left: 0;
		}

		@media (min-width: 1290px) {
			max-width: calc(var(--pageWidth) + 1.6rem);
		}
	}

	.flickity-page-dot:focus {
		box-shadow: none;
	}

	.flickity-page-dot:focus-visible {
		box-shadow: 0 0 0 1px @gold;
	}

	@media (max-width: @sm-neg) {
		&:not([data-number="1"])::after {
			content: 'flickity';
  			display: none; /* hide :after */
		}

		&.flickity-enabled {
			padding-bottom: 3.2rem;
		}
	}

	@media (min-width: @sm) {
		width: calc(100% + 1.6rem);
		margin-left: -0.8rem;

		&.flickity-enabled {
			padding-bottom: 6.2rem;
		}

		&:not(.flickity-enabled) {
			display: flex;
		}

		&:not([data-number="1"]):not([data-number="2"])::after {
			content: 'flickity';
  			display: none; /* hide :after */
		}
	}

	@media (min-width: @md) {

		&.flickity-enabled {
			width: calc(100vw - var(--pagePadding) + 0.8rem);
		}
	}
}

.slider-cta__slide {
	width: 100%;
	padding: 0 1.6rem;

	@media (min-width: @sm) {
		width: 50%;
		padding: 0 0.8rem;
	}

	@media (min-width: @md) {

		.flickity-enabled & {
			width: calc((100vw - (var(--pagePadding) * 2)) / 2)
		}
	}

	@media (min-width: 1281px) {

		.flickity-enabled & {
			width: calc((var(--pageWidth) + 1.6rem) / 2);
		}
	}
}

.slider-cta__card {
	border-radius: 4px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	min-height: 22.4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: @white;
	overflow: hidden;
	padding: 2.6rem 20% 2.8rem 2.4rem;

	&::after {
		content: "";
		background: linear-gradient(82deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	@media (min-width: @sm) {
		min-height: 30rem;
	}

	@media (min-width: @md) {
		min-height: 36.8rem;
		padding: 3.2rem 28% 4.3rem 3.3rem;
	}

	@media (min-width: @lg) {
		padding-right: 37%;
	}
}

.slider-cta__card-eyebrow {
	font-family: @scriptSecondaryFont;
	font-size: 1.3rem;
	font-weight: normal;
	letter-spacing: 0.06em;
	line-height: 1.25;
	text-transform: uppercase;
	margin-bottom: 2rem;
}

.slider-cta__card-title {
	margin-bottom: 1.6rem;

	@media (max-width: @sm-neg) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
}