.resource__body {
  padding: 2.5rem 0 0;
  @media screen and (min-width: @sm) {
    padding: 6rem 0 0;
  }

  .share-btn {
    margin-bottom: 1.8rem;
  }
}

.resource__content {
  h2,h3,h4,h5,h6 {
    color: @darkNavy;
  }

  p {
    margin: 2rem 0;
  }

  > p:first-of-type {
    font-size: 1.9rem;
    line-height: 1.47;
    margin: 3rem 0;
  }

  blockquote {
    font-family: @scriptSecondaryFont;
    margin: 3.4rem 0;
    border-left: 1px solid @darkNavy;
    color: @darkNavy;
    padding-left: 2.3rem;
    font-weight: 300;
    font-size: 1.9rem;
    line-height: 1.26;

    @media screen and (min-width: @sm) {
      padding-left: 3.6rem;
      margin: 4.5rem 0;
      font-size: 2.4rem;
      line-height: 1.33;
    }

    > * {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.resource__img {
  margin: 2.4rem 0;

  @media screen and (min-width: @sm) {
    margin: 5rem 0;
  }

  @media screen and (min-width: @md) {
    margin: 5rem -9rem;
  }
  @media screen and (min-width: @lg) {
    margin: 5rem -19rem;
  }
  &--dual {
    @media screen and (min-width: @sm) {
      display: flex;
    }

    figure {
      @media screen and (min-width: @sm) {
        padding: 0 0.8rem;
      }
    }
  }

  figure + figure {
    margin-top: 0.8rem;

    @media screen and (min-width: @sm) {
      margin: 0;

    }
  }

  picture {
    display: block;
    border-radius: .4rem;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  figcaption {
    font-size: 1.1rem;
    color: fade(@slate, 70%);
    margin-top: 1.7rem;

    @media screen and (min-width: @md) {
      padding: 0 9rem;
    }

    @media screen and (min-width: @lg) {
      padding: 0 19rem;
    }
  }
}

.resource__video {
  position: relative;
  margin: 2.4rem 0;
  background: @slate;

  @media screen and (min-width: @sm) {
    margin: 5rem 0;
  }

  @media screen and (min-width: @md) {
    margin: 5rem -9rem;
  }

  @media screen and (min-width: @lg) {
    margin: 5rem -19rem;
  }
}

.resource__author {
  margin-top: 4.3rem;

  @media screen and (min-width: @sm) {
    margin-top: 6rem;
  }
}

.resource__footer {
  padding-bottom: 4.9rem;

  @media screen and (min-width: @sm) {
    padding-bottom: 9rem;
  }

  .share-btn {
    margin-top: 2.5rem;
  }
}

.resource__prev-next {
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;

  @media screen and (min-width: @sm) {
    margin-top: 4rem;
  }
}

.resource__prev-btn,
.resource__next-btn {
  display: flex;
  align-items: center;
  color: @slate;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: .1rem;
  transition: text-decoration .2s ease;

  @media(hover: hover) and (pointer: fine) {

    &:hover {
      text-decoration-color: currentColor;
    }

    &:focus-visible {
      text-decoration-color: currentColor;
    }
  }

  svg {
    display: block;
    width: 1.3rem;
    height: auto;
    stroke: @slate;
  }
}

.resource__prev-btn {
  svg {
    margin-right: 1.5rem;
    transform: rotate(180deg);
  }
}

.resource__next-btn {
  svg {
    margin-left: 1.5rem;
  }
}
