.variant-selectors {
  & + & {
    margin-top: 2rem;

    @media screen and (min-width: @sm) {
      margin-top: 1.6rem;
    }
  }
}

.variant-selectors__selected-option,
.variant-selectors__option-label {
  display: block;
  font-size: 1.2rem;
  line-height: 1;
  margin-bottom: 1rem;

  @media screen and (min-width: @sm) {
    font-size: 1.4rem;
  }
}

.variant-selectors__buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8rem;
}

.variant-selectors__input {
  opacity: 0;
  position: absolute;
}

.variant-selectors__option {
  margin: .4rem .8rem;
}

.variant-selectors__option-btn {
  border-radius: .4rem;
  display: block;
  border: 1px solid #AEAEAE;
  font-size: 1.2rem;
  line-height: 1;
  padding: 1.5rem;
  cursor: pointer;
  transition: box-shadow .2s ease, border .2s ease;

  @media screen and (min-width: @sm) {
    font-size: 1.4rem;
    padding: 1.2rem 1.7rem;
  }
}

.variant-selectors__input:checked ~ .variant-selectors__option-btn {
  font-weight: 500;
  border-color: @navy;
  box-shadow: 0 0 0 .1rem @navy inset;
}

.variant-selectors__input[disabled] ~ .variant-selectors__option-btn,
.variant-selectors__input.disabled ~ .variant-selectors__option-btn {
  opacity: .3;
}

.variant-selectors__option--color {

  margin: .4rem .6rem;

  .variant-selectors__option-btn {
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: '';
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      position: absolute;
      top: .3rem;
      left: .3rem;
      // opacity: .7;
      opacity: 1;
      transition: opacity .2s ease;
    }

    &:after {
      content: '';
      display: block;
      width: 1.2rem;
      height: 2.4rem;
      border-radius: 0 2.4rem 2.4rem 0;
      position: absolute;
      top: .3rem;
      right: .3rem;
      opacity: .7;
      transition: opacity .2s ease;
    }
  }
  
  .variant-selectors__input:checked~.variant-selectors__option-btn {
    box-shadow: none;

    &:before,
    &:after {
      opacity: 1;
    }
  }
}

// color selectors 

.variant-selectors__option-btn {

  .variant-selectors__option[class*="white"] & {
    &:before {
      background: #F2F2E9;
    }
  }

  .variant-selectors__option[class*="yellow"] & {
    &:before {
      background: #D4AF37;
    }
  }

 .variant-selectors__option[class*="bronze"] & {
    &:before {
      background: #B58D42;
    }
  }

  .variant-selectors__option[class*="rose"] & {
    &:before {
      background: #E0BFB8;
    }
  }

  .variant-selectors__option[class*="platinum"] & {
    &:before {
      background: #E5E4E2;
    }
  }

  .variant-selectors__option[class*="silver"] & {
    &:before {
      background: #E2E5E6;
    }
  }

  .variant-selectors__option[class*="steel"] & {
    &:before {
      background: #CFD4D9;
    }
  }

  .variant-selectors__option[class*="titanium"] & {
    &:before {
      background: #878681;
    }
  }

  .variant-selectors__option[class*="dark-case"] & {
    &:before {
      background: #242424;
    }
  }

  // have to do special cases for the multi-colored ones (not dials)

  .variant-selectors__option--silver-yellow & {
    &:before {
      background: #D4AF37;
    }

    &:after {
      background: #E2E5E6;
    }
  }

  .variant-selectors__option--white-yellow & {
    &:before {
      background: #D4AF37;
    }

    &:after {
      background: #F2F2E9;
    }
  }

  // dials

  .variant-selectors__option[class*="dark"] & {
    &:after {
      background: #242424;
    }
  }

  .variant-selectors__option[class*="light"] & {
    &:after {
      background: #F7F7F7;
    }
  }

  .variant-selectors__option[class*="gold"] & {
    &:after {
      background: #D4AF36;
    }
  }

}

// smaller version for product cards 

.variant-selectors--mini {

  .variant-selectors__buttons {
    justify-content: flex-end;
    margin: 0;
  }

  .variant-selectors__input[disabled]~.variant-selectors__option-btn,
  .variant-selectors__input.disabled~.variant-selectors__option-btn {
    opacity: 1;
  }

  .variant-selectors__input[disabled]~.variant-selectors__option-strikethrough,
  .variant-selectors__input.disabled~.variant-selectors__option-strikethrough {
    opacity: 1;
  }

  .variant-selectors__input:focus-visible ~ .variant-selectors__option-btn {
    border: 1px solid;
  }

  .variant-selectors__option-strikethrough {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &:after {
      content: '';
      width: 1px;
      height: 120%;
      background: @black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .variant-selectors__option--color {
    margin: 0 0 0 .8rem;
    position: relative;
    .variant-selectors__option-btn {
      width: 1.2rem;
      height: 1.2rem;
      border: none;

      &:before {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &:after {
        width: 50%;
        height: 100%;
        border-radius: 0 1.2rem 1.2rem 0;
        top: 0;
        right: 0;
      }
    }
  }

  .variant-selectors__option-underline {
    position: absolute;
    width: 1.2rem;
    background: @black;
    height: 1px;
    bottom: -0.4rem;
    opacity: 0;
  }

  .variant-selectors__input:checked ~ .variant-selectors__option-underline {
    opacity: 1;
  }

  .variant-selectors__additional {
    display: block;
    height: 1.2rem;
    line-height: 1.2rem;
    margin-left: 0.8rem;
  }
}