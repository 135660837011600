.pagination {
  padding: 0 var(--pagePadding-lg);
  margin-top: 4rem;

  @media screen and (min-width: @sm) {
    margin-top: 6rem;
  }

  &--empty {
    display: none;
  }
}

.pagination__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__link {
  display: block;
  color: #7B7B7B;
  font-size: 1.5rem;
  letter-spacing: 0.024rem;

  .pagination__item--page &{
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    text-align: center;
    line-height: 2.4rem;
    transition: background .2s ease, font-weight .2s ease;

    @media screen and (min-width: @sm) {
      width: 3.2rem;
      height: 3.2rem;
      line-height: 3.2rem;
    }
  }

  .pagination__item--selected & {
    background: #E9E9E9;
    font-weight: 500;
    color: @black;
  }

  .pagination__item:not(.pagination__item--disabled) & {
    @media(hover: hover) and (pointer: fine) {

        &:hover {
          // font-weight: 500;
          color: @black;
        }

        &:focus-visible {
          // font-weight: 500;
          color: @black;
        }
      }
  }

  .pagination__item--arrow & {
    width: 1.4rem;
    height: .8rem;
    color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.463 8.899'%3E%3Cg fill='none' stroke='%237b7b7b'%3E%3Cpath data-name='Path 222' d='M0 4.472h14.2'/%3E%3Cpath data-name='Path 223' d='m8.001.425 6.51 4.025-6.51 4.025'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    transition: background .2s ease;
  }

  .pagination__item--prev & {
    transform: rotate(180deg);
  }

  .pagination__item--arrow:not(.pagination__item--disabled) & {
    @media(hover: hover) and (pointer: fine) {

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.463 8.899'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath data-name='Path 222' d='M0 4.472h14.2'/%3E%3Cpath data-name='Path 223' d='m8.001.425 6.51 4.025-6.51 4.025'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:focus-visible {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.463 8.899'%3E%3Cg fill='none' stroke='%23000'%3E%3Cpath data-name='Path 222' d='M0 4.472h14.2'/%3E%3Cpath data-name='Path 223' d='m8.001.425 6.51 4.025-6.51 4.025'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
  }
}

.pagination__item {
  @media screen and (min-width: @sm) {
    margin: 0 0.5rem;
  }

  &--first {
    margin-right: 1rem;
    @media screen and (min-width: @sm) {
      margin-right: 1.5rem;
    }
  }

  &--last {
    margin-left: 1rem;
    @media screen and (min-width: @sm) {
      margin-left: 1.5rem;
    }
  }

  &--prev {
    margin-right: .8rem;
  }

  &--next {
    margin-left: .8rem;
  }
}
