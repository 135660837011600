.map {
  .locations + & {
    padding-top: 4.8rem;
  }
}

.map__location {
  display: none;
}

.map__map {
  height: 42rem;
}