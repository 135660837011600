// e.g. on /cart page
.titled-block {
  border-radius: 0.4rem;
  padding: 0.8rem;
  background-color: @light-sand;

  p.bottom-pad {
    margin-bottom: 2.6rem;
    font-size: 1.2rem;
  }

  // TODO: Is there an existing style I should use?
  textarea {
    width: 100%;
    padding: 1.2rem 1.5rem;
    margin-top: 1.6rem;
    border-radius: 0.4rem;
    border: none;
    background-color: @white;
    resize: none;

    &::-webkit-input-placeholder { /* Edge */
			color: @raffi-grey;
			opacity: 1;
      font-size: 1.2rem;
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: @raffi-grey;
			opacity: 1;
      font-size: 1.2rem;
		}

		&::placeholder {
			color: @raffi-grey;
			opacity: 1;
      font-size: 1.2rem;
		}
  }

  margin-bottom: 1.6rem;
}

.titled-block__title-wrapper {
  padding: 1.9rem 2rem 1.7rem;
  border-radius: 0.4rem;
  background-color: @sand;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.titled-block__icon {
  display: block;
  height: 2.4rem;
  width: auto;
}

.titled-block__title {
  font-family: @primaryFont;
  font-weight: 500;
  color: @chocolate;
  font-size: 1.4rem;
  letter-spacing: 0.09rem;
  line-height: 1.36;
  text-transform: uppercase;
}

.titled-block__pad-x {
  padding: 0 1.8rem;
}

