body {
  // --vh: 1vh;
  opacity: 1;
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
}
body.fade-out {
  opacity: 0;
  -webkit-transition: none;
  transition: none;
}

#pageWrapper {
  opacity: 1;
  -webkit-transition: 1.5s opacity;
  transition: 1.5s opacity;
}
#pageWrapper.fade-out {
  opacity: 0;
  -webkit-transition: none;
  transition: none;
}

@keyframes pagespin {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

.pagespinner {
  border: .8rem solid fade(black, 30%);
  border-top: .8rem solid @spinColour;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: pagespin 1.5s linear infinite;
  position: absolute;
  transition: opacity 1s;
  opacity: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);

  &--small {
    width: 2.4rem;
    height: 2.4rem;
    border-width: .4rem;
  }
}

.pagespinner.spin {
  opacity: 1;
}
