.featured-resource {
  padding: 3.6rem 0 4.8rem;
  @media screen and (min-width: @sm) {
    padding: 6.3rem 0;
  }
}

.featured-resource__eyebrow {
  .h6;
  color: @navy;

  &--mobile{
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (min-width: @sm) {
      display: none;
    }
  }

  &--desktop {
    display: none;
    @media screen and (min-width: @sm) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }
}

.featured-resource__title {
  font-family: @scriptPrimaryFont;
  color: @navy;
  font-size: 2.4rem;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.333;

  @media screen and (min-width: @sm) {
    font-size: 3.6rem;
    line-height: 1.25;
  }
}

.featured-resource__byline {
  font-size: 1.1rem;
  color: fade(@slate, 70%);
  margin-top: .4rem;

  @media screen and (min-width: @sm) {
    margin-top: 1.5rem;
  }
}

.featured-resource__summary {
  .p;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2.5rem;
  margin-top: 2.2rem;
}

.featured-resource__img {
  display: block;
  margin: 0 calc(-1 * var(--pagePadding)) 3.3rem;

  @media screen and (min-width: @sm) {
    height: 100%;
    display: flex;
    margin: 0 calc(-1 * var(--pagePadding)) 0 0;
  }

  @media screen and (min-width: 1280px) {
    margin-right: calc(-1 * (100vw - var(--pageWidth)) / 2);
  }

  img {
    width: 100%;

    @media screen and (min-width: @sm) {
      object-fit: cover;
      object-position: center;
    }
  }
}

.featured-resource__content {
  @media screen and (min-width: @sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    align-items: flex-start;
  }
}