.resource-archive {

  // custom hero styling, since this is a one off we'll just use existing regular hero
  .hero {
    background: @light-sand;

    @media screen and (min-width: @sm) {
      background: @slate;
    }
  }

  .hero__bkg:after {
    background: transparent linear-gradient(184deg, #FFFFFF00 0%, #5C5D626F 53%, #05060E9D 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
    top: 0;
    mix-blend-mode: multiply;
  }

  .hero__wrapper {
    @media screen and (min-width: @sm) {
      min-height: 32.8rem;
    }
  }

  .hero__content {
    text-align: center;
    @media screen and (min-width: @sm) {
      padding: 7rem 0;
      text-align: left;
    }
  }
}

.resource-archive__header {
  padding: 2rem 0;
  background: @light-sand;
  border-bottom: 1px solid #E3E3E3;
}

.resource-archive__header-inner {
  display: flex;
  justify-content: space-between;
}

.resource-archive__header-title {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: @navy;
  display: block;
  margin-bottom: .8rem;
  line-height: 2;

  @media screen and (min-width: @sm) {
    font-size: 1.2rem;
  }
}

.resource-archive__nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.resource-archive__nav-item {
  & + & {
    margin-left: 2rem;
  }
}

.resource-archive__nav-link {
  font-weight: 500;
  font-size: 1.5rem;
  font-family: @scriptSecondaryFont;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: .2rem;
  transition: text-decoration .2s ease;

  @media(hover: hover) and (pointer: fine) {
  
    &:hover {
      text-decoration-color: currentColor;
    }

    &:focus-visible {
      text-decoration-color: currentColor;
    }
  }
}

.resource-archive__featured {
  background: @light-sand;
}

.resource-archive__section-header {
  padding: 4rem 0 2rem;

  @media screen and (min-width: @sm) {
    padding: 2rem 0 3.5rem;
    display: flex;
    align-items: flex-end;
    min-height: 16rem;
  }
}

.resource-archive__section-header-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: @sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.resource-archive__section-footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 12rem;
  padding: 3rem 0;

  @media screen and (min-width: @sm) {
    min-height: 16rem;
    padding: 4.8rem 0;
  }
}

.blog__sortBy {
  margin-top: 2rem;

  @media screen and (min-width: @sm) {
    margin: 0;
  }
  .sortBy__label {
    display: block;
  }

  [data-selected] {
    font-weight: 500;
  }
}

.resource-archive__title {
  .h4;
}

.resource-archive__grid {
  border-top: 1px solid #E3E3E3;
  @media screen and (min-width: @sm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.resource-archive__grid-item {
  width: 100%;
  border-bottom: 1px solid #E3E3E3;

  @media screen and (min-width: @sm) {
    flex-basis: calc(100%/3);
    max-width: calc(100%/3);
    border-right: 1px solid #E3E3E3;
  }

  &:nth-of-type(3n) {
    @media screen and (min-width: @sm) and (max-width: @md-neg) {
      border-right: none;
    }
  }

  &:nth-of-type(3n) {
    @media screen and (min-width: @md) {
      border-right: none;
    }
  }
}

.resource-archive__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.resource-archive__item {
  &+& {
    margin-left: 2rem;
  }
}

.resource-archive__link {

  svg {
    height: 1.5rem;
    width: auto;
    display: block;
    transform: none;
    transition: transform .2s ease;
  }

  @media(hover: hover) and (pointer: fine) {

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    &:focus-visible {
      svg {
        transform: scale(1.1);
      }
    }
  }
}

.resource-archive__empty-msg {
  width: 100%;
  padding: 2rem 0 3.5rem;

  &--hidden {
    display: none;
  }
}

.resource-archive__view-more {
  // opacity: 1;
  // visibility: visible;
  // transition: opacity .2s ease, visibility .2s ease;
  &--hidden {
    // opacity: 0;
    // visibility: hidden;
    display: none;
  }
}

