.birthstones {
	padding-bottom: 4.2rem;
}

.birthstones__title {
	color: @navy;
	margin-bottom: 2.3rem;
}

.birthstone__nav-col {
	padding: 0 0.3rem;
	margin-bottom: 2rem;
 }

.birthstone__nav-btn {
	font-size: 1.6rem;
	font-weight: 500;
	display: block;
	width: 100%;
	color: @black;
	transition: color 0.3s ease;

	&:hover {
		color: @chocolate;
		.birthstone__nav-img {
			transform: scale(1.1) translateX(-50%);
		}
	}
	&:focus-visible {
		color: @chocolate;
		.birthstone__nav-img {
			transform: scale(1.1) translateX(-50%);
		}
	}
}

.birthstone__nav-img-wrapper {
	position: relative;
	display: block;
	margin-bottom: 1.3rem;

	&::after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

.birthstone__nav-img {
	position: absolute;
	top: 11%;
	width: 81%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	transform-origin: left center;
	transition: transform 0.3s ease;
}

.birthstones__acc-panel {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
 }

 .birthstones__acc-content {
	padding: 0 0 4rem 2rem;
	color: @slate;
	
	p:not(:last-child) {
		margin-bottom: 1.5em;
	}

	@media (min-width: @sm) {
		column-count: 2;
		column-gap: 4rem;
		padding: 1.7rem 0 9rem 20.3rem;
	}

	@media (min-width: @md) {
		padding-left: 26.4rem;
		column-gap: 5.5rem;
	}
 }

 .birthstones__accordion {
	border-bottom: 1px solid @navy;
 }

 .birthstone__acc-btn {
	border-top: 1px solid @navy;
	display: flex;
	width: 100%;
	font-family: @scriptPrimaryFont;
	font-size: 2.8rem;
	font-weight: normal;
	letter-spacing: 0.03rem;
	line-height: 1.286;
	text-transform: none;
	color: @gold;
	padding: 0.6rem 0 0.6rem 1.5rem;
	position: relative;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.548' height='11.54' viewBox='0 0 22.548 11.54'%3E%3Cpath id='Path_751' data-name='Path 751' d='M110.31,2047.935l10.928,10.487,10.928-10.487' transform='translate(-109.964 -2047.575)' fill='none' stroke='%23172045' stroke-width='1'/%3E%3C/svg%3E ");
	background-size: 2.2548rem 1.154rem;
	background-position: center right;
	background-repeat: no-repeat;

	@media (min-width: @sm) {
		font-size: 4.5rem;
		letter-spacing: 0.09rem;
		line-height: 6.4rem;
		padding: 0.6rem 0 0.6rem 3.5rem;

		&.active {
			.birthstone__acc-img {
				transform: scale(1.5);
			}
		}
	}

	@media (min-width: @md) {
		padding: 0.6rem 0 0.6rem 7.2rem;

		&.active {
			.birthstone__acc-img {
				transform: scale(2.24);
			}
		}
	}
 }

 .birthstone__acc-img {
	display: block;
	width: 5.5rem;
	margin-right: 2.5rem;
	transition: transform 0.3s ease;
	transform-origin: top;

	@media (min-width: @sm) {
		width: 9.6rem;
		margin-right: 7rem;
	}

	@media (min-width: @md) {
		margin-right: 9.6rem;
	}
 }
