// Overrides for other styles
.cart {
  @radio-size: 1.6rem;

  .radio-label {
    display: grid;
    grid-template-columns: @radio-size auto;
    gap: 0.8rem;

    font-family: @primaryFont;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0;
    color: @slate;
  }

  [type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;

    height: @radio-size;
    width: @radio-size;

    border: 1px solid @warm-grey;
    border-radius: 0;

    background-color: @white;

    display: grid;
    place-content: center;

  }

  .radio-label + .radio-label {
    margin-top: 0.8rem;
  }

  input[type="radio"]::before {
    content: "";
    height: @radio-size;
    width: @radio-size;
    border-radius: 0;
    background-repeat: no-repeat;
		background-size: 1.2rem 1.1rem;
		background-position: center;
    // box-shadow: inset 1em 1em red;
  }

  input[type="radio"]:checked::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.116' height='13.937' viewBox='0 0 16.116 13.937'%3E%3Cpath id='Path_874' data-name='Path 874' d='M-7116.7-6094.073l4.641,5.656,9.939-11.729' transform='translate(7117.474 6100.792)' fill='none' stroke='%23242424' stroke-width='2'/%3E%3C/svg%3E ");
  }
}

.cart__title {
  .h2;
  margin-bottom: 1rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 0;
  }
}

.cart__banner {
  margin: 1.6rem 0;
  @media screen and (min-width: @sm) {
    margin: 5.6rem 0 1.6rem;
  }
}

.cart__main {
  @media screen and (min-width: @sm) {
    padding-right: 5rem;
  }
}

// The shopping cart label and the continue shopping link
.cart__heading {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: @sm) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.cart__checkout-btn {
  width: 100%;
  margin-bottom: 1rem;
}

// The "table" headings
.cart__product-headings {
  display: none;

  @media screen and (min-width: @sm) {
    display: flex;
  }
  // TODO: Need to figure out how to line these up
  width: 100%;
  justify-content: space-between;

  padding: 3rem 0 1rem;
  border-bottom: 1px solid #DEDEDE;
}

.cart__product-heading {
  font-family: @primaryFont;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.096rem;
  line-height: 2;
  text-transform: uppercase;

  &:nth-last-child(2) {
    @media screen and (min-width: @sm) {
      flex-basis: 19%;
      margin-left: auto;
      padding: 0 1.8rem;
    }
  }

  &:last-child {
    @media screen and (min-width: @sm) {
        padding-left: 1.8rem;
        flex-basis: 12%;
        text-align: right;
      }
  }
}
.cart__label {
  font-family: @primaryFont;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.096rem;
  line-height: 2;
  text-transform: uppercase;
  color: @raffi-grey;
  margin-bottom: .5rem;

  @media screen and (min-width: @sm) {
    display: none;
  }
}

// From Crown's checkout:

.cart-product {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  padding: 2.4rem 0;
  border-bottom: 1px solid #DEDEDE;
  opacity: 1;
  transition: opacity 0.2s;

  @media screen and (min-width: @sm) {
    padding: 4rem 0;
  }

  @media screen and (min-width: @sm) {
    flex-wrap: nowrap;
  }

  &.js-hidden {
    opacity: 0;
  }
}

.cart-product__image {
  // display: block;
  // width: 17.5rem;
  width: 100%;
  background-color: fade(#E9E9E9, 50%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: .4rem;
  max-width: 14.5rem;
  margin-bottom: 2rem;

  @media screen and (min-width: @sm) {
    margin-right: 1.8rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: @md) {
    max-width: 16rem;
  }

  &:before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }
}

.cart-product__name {
  .p-sm;
  margin-bottom: .5rem;
}

.cart-product__sku {
  .p-sm;
  font-weight: 400;
  color: @raffi-grey;
  margin-bottom: 1.8rem;
}

.cart-product__variant {
  display: flex;
  margin-top: 1rem;
}

.cart-product__custom-attributes {
  margin-top: 1rem;
  font-size: 1.4rem;
}

// Based on variant-selectors.less
// TODO: This doesn't look right and I don't understand what it's doing. Gonna do later.
.cart-product__variant-icon {
  border-radius: .4rem;
  display: block;
  border: 1px solid @black;
  font-size: 1.2rem;
  line-height: 1;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  margin-right: 1.6rem;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    position: absolute;
    top: .2rem;
    left: .2rem;
    opacity: .7;
  }

  &::after {
    content: '';
    display: block;
    width: 0.9rem;
    height: 1.8rem;
    border-radius: 0 1.8rem 1.8rem 0;
    position: absolute;
    top: .2rem;
    right: .2rem;
    opacity: .7;
  }

  &[class*="white"] {
    &::before {
      background: #F2F2E9;
    }
  }

  &[class*="yellow"] {
    &::before {
      background: #D4AF37;
    }
  }

    &[class*="bronze"] {
      &:before {
        background: #B58D42;
      }
    }

    &[class*="rose"] {
      &:before {
        background: #E0BFB8;
      }
    }

    &[class*="platinum"] {
      &:before {
        background: #E5E4E2;
      }
    }

    &[class*="silver"] {
      &:before {
        background: #E2E5E6;
      }
    }

    &[class*="steel"] {
      &:before {
        background: #CFD4D9;
      }
    }

    &[class*="titanium"] {
      &:before {
        background: #878681;
      }
    }

    &[class*="dark-case"] {
      &:before {
        background: #242424;
      }
    }

    // have to do special cases for the multi-colored ones (not dials)

    &.cart-product__variant-icon--silver-yellow{
      &:before {
        background: #D4AF37;
      }

      &:after {
        background: #E2E5E6;
      }
    }

    &.cart-product__variant-icon--white-yellow{
      &:before {
        background: #D4AF37;
      }

      &:after {
        background: #F2F2E9;
      }
    }

    // dials

    &[class*="dark"] {
      &:after {
        background: #242424;
      }
    }

    &[class*="light"] {
      &:after {
        background: #F7F7F7;
      }
    }

    &[class*="gold"] {
      &:after {
        background: #D4AF36;
      }
    }
}

.cart-product__variant-name {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.cart-product__col {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  width: 100%;

  @media screen and (min-width: @sm) {
    width: auto;
  }

  &--details {
    margin-bottom: 5rem;

    @media screen and (min-width: @sm) {
      margin-bottom: 0;
    }
  }
  // quantity
  &--quantity {
    flex-basis: 67%;
    @media screen and (min-width: @sm) {
      flex-basis: 19%;
      margin-left: 17.8rem;
      margin-left: auto;
    }

    @media screen and (min-width: @md) {

    }
  }

  // price
  &:last-of-type {
    // display: flex;
    // align-items: center;
    // margin-left: auto;
    // justify-content: space-between;
    // margin-top: 2rem;
    flex-basis: 33%;

    @media screen and (min-width: @sm) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      margin-top: 0;
      flex-basis: 12%;
      padding-right: 0;
    }
  }
}

.cart-product__col-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-product__remove {
  .btn-reset();
  color: @raffi-grey;
  margin: 0 0.8rem;
  position: relative;

  // Make this button an icon
  padding: 0;
  width: 2.8rem;
  height: 2.8rem;

  svg {
    display: block;
    width: 1.5rem;
    margin: 0 auto;
  }
}

.cart-product__price {
  // .copy--large--bold;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.4rem;

  @media screen and (min-width: @sm) {
    display: block;
    text-align: right;
  }

  strike {
    color: @darker-grey;
    margin-right: 0.5rem;
  }
}

.cart-product__quantity-wrapper {
  display: flex;
  align-items: center;
}

.cart-product__quantity {
  // .text-input--white;
  width: 7.5rem;
  height: 4.8rem;
  text-align: center;
  border-radius: .4rem;
  border: 1px solid #DEDEDE;
  font-weight: 500;
  font-size: 1.4rem;

  @media screen and (min-width: @sm) {
    margin-left: 0;
  }
}

.cart__note {
  height: 13.6rem;
}

.cart__summary-total {
  display: flex;
  justify-content: space-between;

  padding: 0 1.8rem 2rem;

  @media screen and (min-width: 500px) {
    display: flex;
    justify-content: space-between;
  }
}

.cart__summary-total-title {
  .p-sm;
  font-weight: 400;
  color: @slate;
  display: block;
}

.cart__summary-total-val {
  font-size: 1.4rem;
  line-height: 1.64;
  font-weight: 500;
  letter-spacing: 0;
  color: @slate;
}

.cart__summary-disclaimer {
  padding: 1rem 1.8rem 2rem;
}

// Hide the stock affirm widget so we can control the text
.cart__summary-affirm {
  display: none;

  // Hide custom affirm label if the affirm widget is empty (not available)
  &:empty + .custom-affirm-label {
    display: none;
  }
}

.custom-affirm-label {
  font-size: 1.3rem;
  line-height: 1.8;
  letter-spacing: 0;
  color: @darker-grey;
  margin-bottom: 2rem;

  .affirm-disclaimer {
    display: block;
  }
}

.custom-affirm-label__img {
  width: 4rem;
  display: inline-block;
}
