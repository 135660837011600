/* ==========================================================================
   IE Banner
   ========================================================================== */

/* Hide IE banner by default */
.ie-banner {
  display: none;
}

/* Show IE banner on IE10/11 */
@media screen and (-ms-high-contrast: active),
       screen and (-ms-high-contrast: none) {
    .ie-banner {
    display: block;
  }
}

.ie-banner {
  width: 100%;
  padding: 1rem 3rem;
  position: fixed;
  top: 0;
  left: 0;
  background: @black;
  color: @white;
  text-align: center;
  font-size: 1.4rem;
  z-index: 999;

  p {
    margin: 0;
  }

  @media screen and (min-width: @sm) {
    padding: 2rem 3rem;
  }

  &.hidden {
    display: none;
  }
}

.ie-banner__link {
  color: inherit;
  display: inline-block;
  margin-top: 1rem;
  @media screen and (min-width: @sm) {
    margin: 0 0 0 1rem;
  }
}
.ie-banner__content {
  @media screen and (min-width: @sm) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ie-banner__close {
  -webkit-appearance: none;
  appearance: none;
  background: transparent url('../../img/icons/close-x.svg') center / contain no-repeat;
  border: none;
  border-radius: 0;
  padding: 0;
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 2rem;
  right: 1rem;
}
