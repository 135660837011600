.columns {
  &--text-light {
    color: @white;
  }
  &--text-dark {
    color: @slate;
  }
}

// The main flex wrapper
.columns__inner {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: @md) {
    flex-direction: row;
  }

  // Set gap based on contents
  gap: 8rem;
  .columns__text {
    column-gap: 8rem;
  }
  &:has(.columns__picture) {
    gap: 5rem;
    .columns__text {
      column-gap: 5rem;
    }
  }
  &:has(.columns__picture:nth-child(2)) {
    gap: 1.6rem;
  }
}

// The picture tag, around the image tag
.columns__picture {
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;

  flex-shrink: 0;
  min-height: 47rem;
  &--full {
    flex-basis: 100%;
    // min-height: 55rem;
  }
  &--half {
    flex-basis: 50%;
    // min-height: 55rem;
  }
  &--third {
    flex-basis: 33.3333%;
    // min-height: 47rem;
  }
  &--two-thirds {
    flex-basis: 66.6667%;
    // min-height: 47rem;
  }
}

// Make first paragraph a bit larger
.bigger-paragraph {
  font-size: 1.9rem;
  line-height: 1.49;
}

.columns__image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

.columns__text {
  flex-basis: auto;
  @media screen and (min-width: @sm) {
    margin: 4.2rem 0;
  }

  &--double {
    @media screen and (min-width: @sm) {
      column-count: 2;
    }
  }

  > p {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.53;

    // Make first paragraph a bit larger
    &:first-child {
      .bigger-paragraph();
    }

    + p {
      margin-top: 1.6rem;
    }
  }

  .columns__p-image + p {
    .bigger-paragraph();
  }


  blockquote {
    font-family: @scriptSecondaryFont;
    margin: 3.4rem 0;
    border-left: 1px solid @navy;
    color: @navy;
    .columns--text-light & {
      border-color: @light-gold;
      color: @light-gold;
    }
    padding-left: 2.3rem;
    font-weight: 300;
    font-size: 1.9rem;
    line-height: 1.26;

    @media screen and (min-width: @sm) {
      padding-left: 3.6rem;
      margin: 4.5rem 0;
      font-size: 2.4rem;
      line-height: 1.33;
    }

    > * {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
