.contact__inner {
  @media screen and (min-width: @md) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.contact__form-wrapper {
  width: 100%;

  @media screen and (min-width: @md) {
    max-width: 76.8rem;
    margin-right: 5%;
  }
}

.contact__cta {
  padding: 3.4rem;
  background: @light-sand;
  border-radius: .4rem;
  @media screen and (min-width: @md) {
    max-width: 25.6rem;
    margin-top: 2rem;
  }
}

.contact__cta-eyebrow {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 1rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 2rem;
  }
}

.contact__cta-title {
  font-family: @scriptPrimaryFont;
  font-size: 2.4rem;
  line-height: 1.167;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  color: @navy;
  margin-bottom: 3rem;

}