// ======================================================
//https://github.com/paulirish/lite-youtube-embed
// ======================================================

@import "~lite-youtube-embed/src/lite-yt-embed.css"; // loads from node_modules

@circlePlayIcon: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20107.939%20107.939%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M76.04%2049.265%2043.645%2031.784a5.342%205.342%200%200%200-7.888%204.7v34.965a5.342%205.342%200%200%200%207.888%204.7l32.4-17.481a5.345%205.345%200%200%200%200-9.408Zm-2.135%205.455-32.4%2017.481a.831.831%200%200%201-1.25-.747V36.488a.856.856%200%200%201%20.852-.852.837.837%200%200%201%20.4.105l32.4%2017.481a.854.854%200%200%201%200%201.5Z%22%2F%3E%3Cpath%20d%3D%22M53.97%200a53.97%2053.97%200%201%200%2053.97%2053.97A54.028%2054.028%200%200%200%2053.97%200Zm0%20103.442a49.472%2049.472%200%201%201%2049.472-49.472%2049.527%2049.527%200%200%201-49.477%2049.472Z%22%2F%3E%3C%2Fsvg%3E');

// .lite-youtube-embed {
//   width: 400px;
//   margin: auto;
// }

// Override the default styles
lite-youtube {
  max-width: none;

  &::before {
    display: none;
  }

  // The play button
  & > .lty-playbtn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

// Customize for Rolex videos (e.g. /rolex/1908)
.rlx-video {
  lite-youtube > .lty-playbtn {
    background-color: @white;
    width: 6rem;
    height: 6rem;
    background-size: 25%;
    transition: filter 0.2s ease;

    // dark version
    // background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22enable-background%3Anew%200%200%2015%2015%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20viewBox%3D%220%200%2015%2015%22%3E%3Cpath%20d%3D%22M13.6%207.5%201.4%2015V0l12.2%207.5z%22%20%2F%3E%3C%2Fsvg%3E');

    // green version
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22enable-background%3Anew%200%200%2015%2015%22%20viewBox%3D%220%200%2015%2015%22%3E%3Cpath%20d%3D%22M13.6%207.5%201.4%2015V0l12.2%207.5z%22%20fill%3D%22%23127749%22%20%2F%3E%3C%2Fsvg%3E');

    // @media(hover: hover) and (pointer: fine) {
    //   &:hover,
    //   &:focus-visible {
    //     background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22enable-background%3Anew%200%200%2015%2015%22%20viewBox%3D%220%200%2015%2015%22%3E%3Cpath%20d%3D%22M13.6%207.5%201.4%2015V0l12.2%207.5z%22%20fill%3D%22%23127749%22%20%2F%3E%3C%2Fsvg%3E');
    //   }
    // }
  }
}

// Customize for resource videos (e.g. /blog/test-blog-entry?preview)
.resource__video {
  lite-youtube > .lty-playbtn {
    width: 9.5rem;
    height: 9.5rem;
    background-size: 100%;
    background-image: @circlePlayIcon;
    transition: opacity 0.2s ease;
  }

  // Override hover/focus from lite-youtube-embed CSS
  lite-youtube:hover > .lty-playbtn,
  lite-youtube .lty-playbtn:focus {
    // filter: none;
    opacity: 0.5;
  }

  // Fix it showing while the video is playing
  lite-youtube.lyt-activated::before,
  lite-youtube.lyt-activated > .lty-playbtn {
    opacity: 0;
  }
}

// Customize for Tudor page (e.g. /tudor)
.tudor__video-wrapper {
  lite-youtube {
    aspect-ratio: 1.36;

    @media screen and (min-width: @sm) {
      aspect-ratio: 16 / 9;
    }

    & > .lty-playbtn {
      width: 4.8rem;
      height: 4.8rem;
      background-image: @circlePlayIcon;
      background-size: 60%;
      transition: transform 0.3s ease;

      @media (min-width: @sm) {
        height: 10.8rem;
		    width: 10.8rem;
        background-size: 100%;
      }
    }

    // Override hover/focus from lite-youtube-embed CSS
    &:hover > .lty-playbtn,
    & .lty-playbtn:focus {
      // filter: none;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}
