.newsletter {
  background-color: @black;
  background-size: cover;
  background-position: center;
  color: @light-sand;
  padding: 4rem 0 5rem;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 100%);

    @media screen and (min-width: @sm) {
      display: none;
    }
  }
}

.newsletter__inner{
  max-width: 41rem;
  position: relative;
  @media screen and (min-width: @sm) {
    max-width: 54rem;
  }
}

.newsletter__eyebrow {
  .h6;
  margin-bottom: .8rem;
}

.newsletter__title {
  .h2;
  margin-bottom: 2rem;
}

.newsletter__form {
  @media screen and (min-width: @sm) {
    max-width: 41rem;
  }

  .form__input {
    display: flex;
    align-items: flex-end;
    margin-bottom: 2.4rem;
  }

  .btn {
    flex-shrink: 0;
    margin-left: 2.4rem;
    padding-left: 1.9rem;
    padding-right: 1.9rem;
  }

  .form__input--results {
    margin: 1rem 0 -1rem;
  }
}