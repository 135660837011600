// Our imports
@import 'base/variables.less';
@import 'base/mixins.less';
@import 'base/general.less';
@import 'vendor/normalize.less';
@import 'vendor/flexgrid.less';
@import "~flickity/css/flickity.css"; // loads from node_modules
@import "~flickity-fade/flickity-fade.css";
@import "~flatpickr/dist/flatpickr.css";
@import 'components/forms.less';
@import 'vendor/tippy.less';
@import 'vendor/autocomplete.css';
@import 'base/typography.less';

@import 'components/general.less';
@import 'components/page-load.less';
@import 'components/modal.less';
@import 'components/buttons.less';
@import 'components/multi-step-form.less';
@import 'components/nav.less';
@import 'components/autocomplete.less';
@import 'components/footer.less';
@import 'components/breadcrumbs.less';
@import 'components/collection.less';
@import 'components/filters.less';
@import 'components/product-card.less';
@import 'components/spec-table.less';
@import 'components/icon-banner.less';
@import 'components/variant-selectors.less';
@import 'components/titled-block.less';
@import 'components/pagination.less';
@import 'components/author-card.less';
@import 'components/share-button.less';
@import 'components/resource-card.less';
@import 'components/featured-resource.less';
@import 'components/product-list.less';
@import 'components/page-card.less';
@import 'components/radio-switcher.less';
@import 'components/ring-assistant.less';
@import 'components/dual-handle-range.less';
@import 'components/lite-youtube-embed.less';

@import 'modules/slider-5050.less';
@import 'modules/slider-4060.less';
@import 'modules/slider-fullwidth.less';
@import 'modules/slider-logos.less';
@import 'modules/slider-products.less';
@import 'modules/slider-cta.less';
@import 'modules/slider-carousel.less';
@import 'modules/cta.less';
@import 'modules/hero.less';
@import 'modules/hero-slider.less';
@import 'modules/checkerboard.less';
@import 'modules/columns.less';
@import 'modules/text.less';
@import 'modules/consult.less';
@import 'modules/featured-content.less';
@import 'modules/icon-grid.less';
@import 'modules/slider-history.less';
@import 'modules/birthstones.less';
@import 'modules/locations.less';
@import 'modules/map.less';
@import 'modules/contact.less';
@import 'modules/ambassadors.less';
@import 'modules/lifestyle-collection.less';
@import 'modules/lifestyle-spread.less';
@import 'modules/featured-product.less';
@import 'modules/newsletter.less';

@import 'partials/ie-banner.less';
@import 'partials/cookies-banner.less';
@import 'partials/announcement-banner.less';
@import 'partials/drop-a-hint.less';

@import 'pages/single-product.less';
@import 'pages/tudor.less';
@import 'pages/cart.less';
@import 'pages/resource.less';
@import 'pages/resource-archive.less';
@import 'pages/search-results.less';
@import 'pages/error-page.less';

// bring in text component for service module
// @import 'rolex-v6/styleguide.less';
// @import 'rolex-v6/text-component.less';

// https://www.raffi-jewellers.ca/watch-repair has a rolex green button (hardcoded in markdown), so adding that here...
@import 'rolex-v7/styleguide.less';
