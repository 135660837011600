.spec-table__row {
  display: flex;
  font-size: 1.2rem;
  line-height: 1.3;
  padding: 1.2rem 1.2rem;

  &:nth-of-type(even) {
    background: @light-grey;
  }

  @media screen and (min-width: @sm) {
    font-size: 1.3rem;
    padding: 1.3rem 1.8rem;
  }
}

.spec-table__title {
  font-weight: 500;
  padding-right: .6rem;
  flex-basis: 50%;

  @media screen and (min-width: @sm) {
    padding-right: .9rem;
  }
}

.spec-table__text {
  padding-left: .6rem;
  flex-basis: 50%;
  
    @media screen and (min-width: @sm) {
      padding-left: .9rem;
    }

    @media screen and (min-width: @md) {
      flex-basis: 61%;
    }
}