.breadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  color: @dark-grey;
  font-size: 1.1rem;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: .3rem;
    &:after {
      content: '>';
      display: inline-block;
      margin-left: .3rem;
    }
  }
}