.consult__bkg {
	background: #172045;
	border-radius: 4px;
	overflow: hidden;
}

.consult__wrapper {

	@media (min-width: @sm) {
		padding-left: calc(var(--pagePadding) - var(--pagePadding-sm));
		padding-right: calc(var(--pagePadding) - var(--pagePadding-sm));
		max-width: calc(var(--pageWidth) + ((var(--pagePadding) - var(--pagePadding-sm)) * 2));
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 1.6rem;
	}
}

.consult__form {
	@media (min-width: @sm) {
		grid-column: 2 / 12;
	}

	@media (min-width: @lg) {
		grid-column: 3 / 11;
	}
}