.icon-grid {
	position: relative;

	.flickity-page-dots {
		display: none;
	}

	.flickity-button-icon {
		display: none;
	}

	.flickity-prev-next-button {
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		top: calc((100% - 9.8rem) / 2 + 9.8rem);
		transform-origin: top center;

		&.next {
			right: -2.4rem;
		}

		&.previous {
			left: -2.4rem;
		}

		&:focus {
			box-shadow: none;
		}

		&:disabled {
			opacity: 0.5;
		}

		&:hover {
			border: 1px solid currentColor;
		}
		&:focus-visible {
			border: 1px solid currentColor;
		}
	}

	&.icon-grid--dark .flickity-prev-next-button.previous {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.46' height='16.626' viewBox='0 0 7.46 16.626'%3E%3Cpath id='Path_848' data-name='Path 848' d='M0,0,8,6.429,16,0' transform='translate(7.07 0.313) rotate(90)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E%0A");
	}

	&.icon-grid--dark .flickity-prev-next-button.next{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.061' height='16.707' viewBox='0 0 9.061 16.707'%3E%3Cpath id='Path_847' data-name='Path 847' d='M0,0,8,8l8-8' transform='translate(0.354 16.354) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/svg%3E ");
	}

	&.icon-grid--light .flickity-prev-next-button.previous {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.46' height='16.626' viewBox='0 0 7.46 16.626'%3E%3Cpath id='Path_848' data-name='Path 848' d='M0,0,8,6.429,16,0' transform='translate(7.07 0.313) rotate(90)' fill='none' stroke='%23242424' stroke-width='1'/%3E%3C/svg%3E%0A");
	}

	&.icon-grid--light .flickity-prev-next-button.next{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.061' height='16.707' viewBox='0 0 9.061 16.707'%3E%3Cpath id='Path_847' data-name='Path 847' d='M0,0,8,8l8-8' transform='translate(0.354 16.354) rotate(-90)' fill='none' stroke='%23242424' stroke-width='1'/%3E%3C/svg%3E ");
	}
}

.icon-grid--dark {
	color: @white;

	.icon-grid__eyebrow,
	.icon-grid__title {
		color: @light-sand;
	}

	.icon-grid__item-title {
		color: @light-gold;
	}

	.icon-grid__nav-btn::before {
		background: @light-gold;
	}

	.icon-grid__icon-wrapper {
		height: 4.4rem;
		width: 4.4rem;
	}
}

.icon-grid--light {
	color: @slate;

	.icon-grid__eyebrow,
	.icon-grid__title {
		color: @navy;
	}

	.icon-grid__item-title {
		color: @gold;
	}

	.icon-grid__nav-btn::before {
		background: @gold;
	}
}

.module--bkg-img {

	&::after {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.icon-grid--dark &::after {
		background: @navy;
		opacity: 0.69;
	}

	.icon-grid--light &::after {
		background: @sand;
		opacity: 0.69;
	}
}

.icon-grid__bkg {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.icon-grid.module & {
		border-radius: 4px;
	}
}

.icon-grid__wrapper {
	position: relative;
	z-index: 1;

	@media (min-width: @md) {
		.icon-grid.module & {
			width: calc(100% + (var(--pagePadding-sm) * 2));
		}
	}
}

.icon-grid__eyebrow {
	margin-bottom: 1.5rem;

	@media (min-width: @md) {
		margin-bottom: 1rem;
	}
}

.icon-grid__title {
	margin-bottom: 3.6rem;

	@media (min-width: @md) {
		margin-bottom: 5.5rem;
	}
}

.icon-grid__nav {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0 auto 2.5rem;
	max-width: 40rem;

	@media (min-width: @md) {
		display: none;
	}
}

.icon-grid__nav-btn {
	color: inherit;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: 0.064em;
	line-height: 1.6rem;
	text-transform: uppercase;
	opacity: 0.5;
	position: relative;
	padding-top: 0.8rem;

	&::before {
		content: "";
		width: 2.8rem;
		height: 1px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
	}

	&:not(:last-child) {
		margin-right: 8px;
	}

	&:hover {
		opacity: 1;
	}
	&:focus-visible {
		opacity: 1;
	}

	&.selected {
		opacity: 1;

		&::before {
			opacity: 1;
		}
	}
}

.icon-grid__items {
	max-width: 45rem;
	margin: 0 auto;

	&::after {
		content: 'flickity';
  		display: none; /* hide :after */
	}

	@media (min-width: @md) {
		display: flex;
		margin-left: -1.6rem;
		margin-right: -1.6rem;
		max-width: none;

		&::after {
			content: none;
		}
	}
}

.icon-grid__icon-wrapper {
	height: 6.8rem;
	width: 6.8rem;
	margin: 0 auto 3rem;
	position: relative;

	@media (min-width: @md) {
		margin-bottom: 4.6rem;
	}
}

.icon-grid__icon {
	display: block;
	height: auto;

	&--empty {
		height: 100%;
		width: 100%;
		border-radius: 100%;
		border: 1px solid @gold;
	}
}

.icon-grid__item-title {
	margin-bottom: 1.3rem;
}

.icon-grid__item-col {
	padding: 0 2.5rem;
	
	@media (min-width: @md) {
		padding: 0 1.6rem;
		flex-basis: 25%;
		flex-grow: 1;
	}
}

.icon-grid__button-wrapper {
	margin-top: 4.6rem;

	@media (min-width: @md) {
		margin-top: 6.6rem;
	}
}