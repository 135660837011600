.collection-page__content-modules {
  .module:last-child {
    padding-bottom: 4.8rem;
  }
}

.collection {
  background: @white;
  padding-bottom: 5.5rem;

  @media screen and (min-width: @sm) {
    padding-bottom: 9.6rem;
  }
}

.collection__header {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2.4rem;
  padding-bottom: 2rem;

  @media screen and (min-width: @md) {
    justify-content: space-between;
  }
}

.collection__header-right {
  margin-left: auto;
  @media screen and (min-width: @md) {
    width: 35%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }
}

.collection__view {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.024rem;
  text-align: center;

  @media screen and (max-width: @sm-neg) {
    order: 1;
    width: 100%;
    margin-top: 1.5rem;
  }

  @media screen and (min-width: @sm) {
    display: none;
  }
}

.collection__stats {
  color: @dark-grey;
  font-size: 1.5rem;
  letter-spacing: 0.024rem;
  text-align: center;

  @media screen and (max-width: @md-neg) {
    order: 1;
    width: 100%;
    margin-top: 1.5rem;
  }

  @media screen and (min-width: @md) {
    width: 30%;
  }
}

.collection__breadcrumbs {
  width: 100%;
  margin-bottom: 2rem;
}

.collection__grid {
  border-top: 1px solid #D5D5D5;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  transition: border .2s ease;

  &--empty,
  &--loading {
    border: none;
  }
}

.collection__grid-item {
  border-bottom: 1px solid #E3E3E3;
  width: 100vw;
  height: 100vw;

  .product-cards--list-view & {
    @media screen and (max-width: @sm-neg) {
      height: @productCardListViewHeight;
    }
  }

  @media screen and (min-width: @sm) {
    width: 50vw;
    height: 50vw;
    border-right: 1px solid #E3E3E3;
  }

  @media screen and (min-width: @md) {
    width: calc(100% ~"/" 3);
    height: calc(100vw ~"/" 3);
  }

  &:nth-of-type(2n) {
    @media screen and (min-width: @sm) and (max-width: @md-neg) {
      border-right: none;
    }
  }

  &:nth-of-type(3n) {
    @media screen and (min-width: @md) {
      border-right: none;
    }
  }

  &--empty {
    width: 100%;
    height: auto;
    padding: 5rem var(--pagePadding) 0;
    text-align: center;
    border: none;
  }

  &--loading {
    width: 100%;
    height: auto;
    padding: 5rem var(--pagePadding) 0;
    text-align: center;
    border: none;
    position: relative;
  }
}

.collection__header-left {
  @media screen and (min-width: @md) {
    width: 35%;
  }
}

.collection__empty-msg {
  font-family: @scriptSecondaryFont;
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.25;
  text-transform: none;
  color: @navy;

  @media screen and (min-width: @sm) {
    font-size: 3.2rem;
  }
}

.collection__filters-open {
  display: inline-flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.024rem;
  color: @slate;

  svg {
    width: 2rem;
    height: auto;
    display: block;
    fill: @slate;
    margin-right: 1.5rem;
  }
}



.sortBy {
  position: relative;
  display: flex;
  width: auto;
}

.sortBy__list {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid @warm-grey;
  padding: 2.4rem;
  position: absolute;
  background: @white;
  width: 100%;
  min-width: 23.2rem;
  right: 0;
  top: 4rem;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;

  .sortBy__toggle--open + &{
    opacity: 1;
    visibility: visible;
  }
}

.sortBy__list-item {
  & + & {
    margin-top: 1.6rem;
  }
}

.sortBy__label {
  color: @dark-grey;
  font-weight: 400;
  display: none;

  @media screen and (min-width: @sm) {
    display: block;
  }

  .filters & {
    display: block;
  }
}

.sortBy__toggle {
  font-size: 1.5rem;
  letter-spacing: 0.024rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: @slate;
  margin-left: 1.6rem;

  &--open {
    &:after {
      transform: rotate(180deg);
    }
  }

  &:after {
    content: '';
    display: block;
    width: 1.2rem;
    height: .7rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.851 6.889'%3E%3Cpath d='M6.421 6.889a.46.46 0 0 0 .326-.133L12.716.787a.461.461 0 0 0-.652-.652L6.42 5.782.786.144a.461.461 0 0 0-.652.652l5.969 5.969a.459.459 0 0 0 .317.124Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: 1.6rem;
  }
}

.sortBy__button {
  padding-left: 3.2rem;
  position: relative;
  font-size: 1.3rem;
  letter-spacing: 0.021rem;
  line-height: 1.6rem;
  display: block;

  &:before {
    content: '';
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    border: 1px solid @slate;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
  }

  &:after {
    content: '';
    width: .8rem;
    height: .8rem;
    background: @gold;
    position: absolute;
    top: .4rem;
    left: .4rem;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .2s ease;
  }

  &--selected {
    &:after {
      opacity: 1;
    }
  }

  .sortBy__list--no-radio & {
    padding-left: 0;
    &:before,
    &:after {
      display: none;
    }
  }
}
