.single-product__gallery {
  margin: 0 calc(-1 * var(--pagePadding-lg));

  @media screen and (min-width: @sm) {
    margin-right: 0;
  }

  @media screen and (min-width: @md) {
    margin-left: calc(-1 ~"*" var(--pagePadding));
  }

  @media screen and (min-width: 1280px) {
    margin-left: calc(-1 * (100vw - var(--pageWidth)) / 2);
  }

  .flickity-page-dots {
    bottom: 1.6rem;
    padding: 0 var(--pagePadding-lg);
    width: 100%;

    @media screen and (min-width: @sm) {
      display: none;
    }
  }

  .flickity-page-dot {
    background: @black;
    opacity: .15;
    margin: 0;
    width: .8rem;
    height: .8rem;
    transition: opacity .2s ease;

    &+.flickity-page-dot {
      margin-left: .8rem;
    }

    &.is-selected {
      opacity: 1;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:focus-visible {
      opacity: .3;
    }
  }
}

.single-product__gallery-slide {
  width: 100%;
}

.single-product__gallery-btn {
  display: block;
  width: 100%;
}

.single-product__gallery-image {
  width: 100%;
}

.single-product__gallery-video {
  width: 100%;
  object-fit: contain;
}

.single-product__gallery-thumbs {
  display: none;

    @media screen and (min-width: @sm) {
      display: flex;
      flex-wrap: wrap;
      margin-left: calc(-1 * var(--pagePadding-lg) - 0.4rem);
    }

    @media screen and (min-width: @md) {
      margin-left: calc(-1 ~"*" var(--pagePadding) - 0.4rem);
    }

    @media screen and (min-width: 1280px) {
      margin-left: calc(-1 * (100vw - var(--pageWidth)) / 2 - 0.4rem);
    }
}

.single-product__gallery-thumbs-item {
  @media screen and (min-width: @sm) {
    flex-basis: 33%;
    padding: 0.4rem;
  }

  @media screen and (min-width: @xl) {
    flex-basis: 25%;
  }
}

.single-product__gallery-thumb {
  background-size: cover;
  background-position: center;
  width: 100%;
  display: block;
  position: relative;

  &.selected {
    box-shadow: inset 0 0 0 .2rem @light-gold;
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &--video {
    &::after {
      content: '';
      display: block;
      // Add play icon
      width: 2.4rem;
      height: 2.4rem;
      background: transparent url('../../img/icons/play.svg') center / contain no-repeat;
      position: absolute;
      bottom: 1.8rem;
      right: 1.8rem;
    }
  }
}

.single-product__details {
  padding-top: 2.4rem;

  @media screen and (min-width: @sm) {
    padding-top: 3.4rem;
    padding-left: 6%;
  }
}

.single-product__breadcrumbs {
  margin-bottom: 2rem;

  @media screen and (min-width: @sm) {
      margin-bottom: 4rem;
    }
}

.single-product__brand-logo {
  max-width: 9rem;
  max-height: 5rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 2.3rem;
  }
}

.single-product__title {
  font-family: @scriptPrimaryFont;
  font-size: 2.8rem;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.286;
  text-transform: none;
  color: @navy;
  margin-bottom: 1rem;

  @media (min-width: @sm) {
    font-size: 3.2rem;
    line-height: 1.25;
  }
}

.single-product__tags {
  margin: 2.5rem 0 2rem;
}

.single-product__tag {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  font-family: @scriptSecondaryFont;
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: normal;
  text-transform: none;
  padding: 0.8rem 2.4rem;

  &--bf {
    background-color: @black;
    color: @white;
    border-color: @black;
  }

  &--exclusive {
    border-color: @warm-grey;
    color: @chocolate;
    font-family: @primaryFont;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.18rem;
    line-height: 1;
    padding: 1.1rem 2.4rem 1rem;
    font-size: 1.1rem;
  }
}

.single-product__sku {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: @darker-grey;
  display: block;

  @media screen and (min-width: @sm) {
    font-size: 1.4rem;
  }
}

.single-product__price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  font-size: 2.8rem;
  font-weight: 400;
  margin-bottom: 1.5rem;

  @media screen and (min-width: @sm) {
    font-size: 3.2rem;
  }

  &--hidden {
    font-size: 1.8rem;
    line-height: 1.47;
    font-family: @scriptSecondaryFont;
    margin-top: 0.8rem;
  }

  strike {
    color: @darker-grey;
    font-size: 2.4rem;
  }
}

.single-product__buttons {
  display: grid;
  margin: 2.4rem 0;
  gap: 0.8rem;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 375px) {
      flex-basis: 50%;
    }

    // Make a single button span the full width when it doesn't quite fit
    &:only-child {
      // 0 - 400: full
      grid-column: 1 / -1;

      // 400 - 768: half
      @media screen and (min-width: 400px) {
        grid-column: 1;
      }
      // 768 - 870: full
      @media screen and (min-width: @sm) {
        grid-column: 1 / -1;
      }
      // 870+: half
      @media screen and (min-width: 870px) {
        grid-column: 1;
      }
    }

    &--full {
      margin: 0 .4rem;
      flex-basis: 100%;
      @media screen and (min-width: @sm) {
        margin: 0 .4rem;
        flex-basis: 50%;
      }
    }
  }
}

.single-product__availability {
  margin-bottom: 3rem;
}

.single-product__notice {
  color: @chocolate;
  background: @light-sand;
  padding: 1.2rem 1.6rem;
  font-size: 1.2rem;
  display: flex;
  line-height: 1.6rem;

  @media screen and (min-width: @sm) {
    font-size: 1.3rem;
  }

  & + &,
  & + .btn--single-product-notice {
    margin-top: .8rem;
  }

  strong {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    text-underline-offset: .1rem;
    transition: text-decoration .2s ease;

    @media(hover: hover) and (pointer: fine) {

      &:hover {
        text-decoration-color: transparent;
      }

      &:focus-visible {
        text-decoration-color: transparent;
      }
    }
  }

  // Affirm widget styling
  &--affirm {
    display: block;
    margin-bottom: 2rem;

    // Hide the banner if affirm div is empty (not available on product)
    &:has(.js-affirm:empty) {
      display: none;
    }

    // This is customizing our custom disclaimer
    .affirm-disclaimer {
      display: block;
      margin-top: 0.2rem;
    }

    // These are customizing the affirm widget's HTML
    .affirm-ala-price {
      margin-left: 3px;
    }
    .__affirm-logo {
      margin: -3px 0 0 3px;
    }
    .affirm-modal-trigger {
      margin-left: 3px;
    }
  }
}

.single-product__notice-icon {
  display: block;
  width: 4rem;
  flex-shrink: 0;

  &::before {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 1.6rem;
    width: 2.5rem;
    display: block;

    // default to info icon
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' class='icon-info'%3E%3Cg fill='none' stroke='%2365450A' stroke-width='.75'%3E%3Ccircle cx='5.5' cy='5.5' r='5.5' stroke='none'/%3E%3Ccircle cx='5.5' cy='5.5' r='5.125'/%3E%3C/g%3E%3Cpath d='M5.41 4.19h.437l-.418 4.269h-.437Zm.159-1.666h.45l-.1.976h-.448Z' fill='%2365450A' stroke='%2365450A' stroke-width='.25'/%3E%3C/svg%3E");

    .single-product__notice--availability &{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' class='icon-availability'%3E%3Cg fill='none' stroke='%2365450a'%3E%3Cg data-name='Ellipse 173'%3E%3Ccircle cx='8' cy='8' r='8' stroke='none'/%3E%3Ccircle cx='8' cy='8' r='7.5'/%3E%3C/g%3E%3Cpath data-name='Path 763' d='m4.161 8.048 2.529 2.529 5.152-5.152'/%3E%3C/g%3E%3C/svg%3E");
    }

    .single-product__notice--delivery & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34.484 19.562' class='icon-delivery'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 1603' fill='%2365450a' d='M0 0h34.484v19.562H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Group 2951' clip-path='url(%23a)' fill='%2365450a'%3E%3Cpath data-name='Path 777' d='M34.476 16.353 32.422 4.952a.556.556 0 0 0-.548-.458h-8.37V.556A.556.556 0 0 0 22.948 0H3.809a.556.556 0 0 0-.556.556v2.378a.556.556 0 1 0 1.112 0V1.112h18.026V15.9h-9.2a3.108 3.108 0 0 0-6.117 0H4.365v-1.68a.556.556 0 0 0-1.112 0v2.232a.556.556 0 0 0 .556.556h3.264a3.108 3.108 0 0 0 6.117 0h9.247a3.108 3.108 0 0 0 6.117 0h5.374a.557.557 0 0 0 .548-.655Zm-24.345 2.1a2 2 0 1 1 2-2 2 2 0 0 1-2 2m15.365 0a2 2 0 0 1-2-1.965V16.385a2 2 0 1 1 1.992 2.067Zm3.058-2.555a3.106 3.106 0 0 0-5.05-1.83V5.607h7.907l1.852 10.288Z'/%3E%3Cpath data-name='Path 778' d='M6.415 6.352a.556.556 0 0 0-.556-.556H.556a.556.556 0 0 0 0 1.112h5.3a.556.556 0 0 0 .556-.556'/%3E%3Cpath data-name='Path 779' d='M6.415 10.655a.556.556 0 0 0-.556-.556H1.951a.556.556 0 0 0 0 1.112h3.907a.556.556 0 0 0 .557-.556Z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .single-product__notice--diamond & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.237 21.79'%3E%3Cg data-name='Group 2968'%3E%3Cpath data-name='Path 787' d='M27.153 6.545 22.613.19a.454.454 0 0 0-.369-.19H4.994a.454.454 0 0 0-.369.19L.085 6.545a.454.454 0 0 0 .033.569L13.282 21.64a.454.454 0 0 0 .673 0L27.12 7.114a.454.454 0 0 0 .033-.569Zm-10.048.718-3.486 12.4-3.486-12.4Zm-6.74-.908 3.254-5.062 3.254 5.062ZM14.451.908h6.911L17.724 6ZM9.514 6 5.876.908h6.911Zm-.862.355H1.337l3.657-5.12Zm.538.908L12.66 19.6 1.479 7.263Zm8.859 0h7.711L14.578 19.6Zm.538-.908 3.657-5.12 3.656 5.12Z' fill='%2365450a'/%3E%3C/g%3E%3C/svg%3E");
    }

    .single-product__notice--account & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.632' height='24.632' viewBox='0 0 24.632 24.632'%3E%3Cg transform='translate(-12.5 -12.5)'%3E%3Cpath data-name='Path 677' d='M20.027,54.168A7.537,7.537,0,0,0,12.5,61.694v1.369a2.064,2.064,0,0,0,2.053,2.053H35.08a2.064,2.064,0,0,0,2.053-2.053V61.694a7.537,7.537,0,0,0-7.527-7.526Zm0,1.369h9.58A6.148,6.148,0,0,1,35.765,61.7v1.369a.675.675,0,0,1-.685.683H14.553a.673.673,0,0,1-.684-.684V61.694a6.147,6.147,0,0,1,6.158-6.158Z' transform='translate(0 -27.983)' fill='%2365450A'/%3E%3Cpath data-name='Path 678' d='M37.408,12.5a6.158,6.158,0,1,0,6.158,6.158A6.168,6.168,0,0,0,37.408,12.5Zm0,1.369a4.789,4.789,0,1,1-4.789,4.789A4.779,4.779,0,0,1,37.408,13.869Z' transform='translate(-12.592)' fill='%2365450A'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .single-product__notice--giftwrapping & {
      // This is the gift-wrapping.svg icon used on the checkout page
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.165' height='24.205' viewBox='0 0 24.165 24.205'%3E%3Cg id='GiftWrapping_Icon' transform='translate(0.1 0.1)'%3E%3Cg id='Group_2955' data-name='Group 2955'%3E%3Cpath id='Path_780' data-name='Path 780' d='M23.965,137.232H0v-3.546a1.143,1.143,0,0,1,1.142-1.142H22.823a1.143,1.143,0,0,1,1.142,1.142ZM.823,136.409h22.32v-2.724a.32.32,0,0,0-.319-.319H1.142a.32.32,0,0,0-.319.319Z' transform='translate(0 -126.152)' fill='%2365450a' stroke='%2365450a' stroke-width='0.2'/%3E%3Cpath id='Path_781' data-name='Path 781' d='M44.37,226.449H24.3a.817.817,0,0,1-.816-.816V212.7h21.7v12.932a.816.816,0,0,1-.815.816Zm-20.062-.823H44.363v-12.1H24.308Z' transform='translate(-22.353 -202.444)' fill='%2365450a' stroke='%2365450a' stroke-width='0.2'/%3E%3Crect id='Rectangle_1604' data-name='Rectangle 1604' width='0.823' height='16.79' transform='translate(11.571 6.803)' fill='%2365450a' stroke='%2365450a' stroke-width='0.2'/%3E%3Cpath id='Path_782' data-name='Path 782' d='M114.689,7.205h-3.605a3.6,3.6,0,1,1,3.605-3.6ZM111.083.822a2.78,2.78,0,1,0,0,5.56h2.783V3.607A2.787,2.787,0,0,0,111.083.822' transform='translate(-102.295)' fill='%2365450a' stroke='%2365450a' stroke-width='0.2'/%3E%3Cpath id='Path_783' data-name='Path 783' d='M243.549,7.206h-3.605v-3.6a3.605,3.605,0,1,1,3.605,3.6Zm-2.783-.823h2.783a2.78,2.78,0,1,0-2.783-2.775Z' transform='translate(-228.373 -0.001)' fill='%2365450a' stroke='%2365450a' stroke-width='0.2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.single-product__key-features-title {
  font-family: @primaryFont;
  font-size: 1.2rem;
  color: @chocolate;
  font-weight: 500;
  letter-spacing: 0.072em;
  line-height: 1.4286;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.single-product__key-features {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 3rem;
  padding: 0;
  display: flex;
  border-bottom: 1px solid @warm-grey;
  border-right: 1px solid @warm-grey;
  // border-left: 1px solid @warm-grey;
  // border: 1px solid @warm-grey;

  @media screen and (min-width: @sm) {
    margin-bottom: 3.4rem;
  }
}

.single-product__key-feature {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.2rem;
  padding: 1.2rem;
  border-top: 1px solid @warm-grey;
  border-left: 1px solid @warm-grey;
  line-height: 1.3;

  @media screen and (min-width: @sm) {
    font-size: 1.3rem;
  }

  @media screen and (min-width: @md) {
    flex-basis: calc(100% / 3);
    // flex-grow: 0;
  }

  strong {
    display: block;
    font-weight: 500;
    color: @chocolate;
  }
}

.single-product__description {
  margin-top: 2rem;
  margin-bottom: 2.3rem;

  p {
    .p;
  }

  p + p {
    margin-top: 1.6rem;
  }

  a {
    text-decoration: underline;
    text-underline-offset: .1rem;
    transition: text-decoration .2s ease;

    @media(hover: hover) and (pointer: fine) {

      &:hover {
        text-decoration-color: transparent;
      }

      &:focus-visible {
        text-decoration-color: transparent;
      }
    }
  }
}

.single-product__description-title {
  font-family: @primaryFont;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  color: @navy;

  @media screen and (min-width: @sm) {
    font-size: 1.9rem;
  }
}

.single-product__accordion {
  &--legal {
    @media screen and (min-width: @sm) {
      margin-bottom: 3.4rem;
    }

    @media screen and (min-width: @md) {
      margin-bottom: 6.4rem;
    }
  }
}

.single-product__accordion-title {
  font-family: @primaryFont;
  font-size: 1.5rem;
  font-weight: 400;
  color: @navy;

  @media screen and (min-width: @sm) {
    font-size: 1.9rem;
  }
}

.single-product__accordion-item {
  border-top: 1px solid @navy;

  &:last-of-type {
    border-bottom: 1px solid @navy;

    .single-product__accordion--details & {
      @media screen and (max-width: @sm-neg) {
        border-bottom: none;
      }
    }
  }
}

.single-product__accordion-toggle {
  display: block;
  padding: 1rem 3rem 1rem 0;
  position: relative;
  width: 100%;
  text-align: left;

  &:after {
    content: '';
    width: 1.4rem;
    height: .7rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.919' height='7.88' viewBox='0 0 14.919 7.88'%3E%3Cpath d='M110.31,2047.935l7.113,6.826,7.113-6.826' transform='translate(-109.963 -2047.575)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E") center / contain no-repeat;
  }

  &.open {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.js-accordion-toggle {
  &.open-default {
    & + .js-accordion-panel {
      max-height: unset;
    }
  }
}

.single-product__accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;

  .spec-table {
    margin-bottom: 2.4rem;

    @media screen and (min-width: @sm) {
      margin-top: 1rem;
    }
  }

    &--rich-text {

      >*:first-child {
        margin-top: .5rem;
      }

      >*:last-child {
        margin-bottom: 2.5rem;
      }

      p,
      ul {
        .p;
      }

      p+p {
        margin-top: 1.6rem;
      }

      a {
        text-decoration: underline;
        text-underline-offset: .1rem;
        transition: text-decoration .2s ease;

        @media(hover: hover) and (pointer: fine) {

          &:hover {
            text-decoration-color: transparent;
          }

          &:focus-visible {
            text-decoration-color: transparent;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 1.5rem 0;

        li {
          position: relative;
          padding-left: 1rem;

          &:before {
            content: '\2022';
            position: absolute;
            left: 0;
          }
        }
      }
    }
}

.single-product__policies {
  margin-top: 4.8rem;

  @media screen and (min-width: @sm) {
    margin-bottom: 3.4rem;
  }

  @media screen and (min-width: @md) {
    margin-top: 8rem;
    margin-bottom: 6.4rem;
  }
}

.single-product__ring-size-selectors {
  margin-top: 3rem;
}

// === Stuff for js-image-zoom lib ===

.single-product__gallery-zoom {
  position: relative;
  .single-product__gallery-image {
    cursor: zoom-in;
  }
}

.js-image-zoom__zoomed-area {
  // If I don't make the .js-image-zoom__zoomed-area have a z-index,
  // on initial click, it continually fires events and alternates between display block and none
  // (move, enter, move, leave, move, enter, move, leave...)
  z-index: 1;
  cursor: zoom-out;
  background-color: transparent !important;
  opacity: 1 !important;
}

// The "add to cart" button on the single product page
.single-product__bag-btn {
	&.success {
		background: @white;
		color: @navy;
	}
}

// === sticky-buy bar ===
.sticky-buy {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform .3s ease-in-out;
  z-index: 2;

  background-color: @light-sand;
  width: 100%;
  border-top: 1px solid #DAD9D9;

  &--visible {
    transform: translateY(0);
  }
}

.sticky-buy__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 0;

  @media screen and (min-width: @sm) {
    justify-content: space-between;
    gap: 2rem;
  }
}

.sticky-buy__product {
  display: flex;
  align-items: center;
  gap: 2.8rem;

  // Hide product info on mobile
  @media screen and (max-width: @sm-neg) {
    display: none;
  }
}

.sticky-buy__image {
  height: 6.4rem;
  width: 6.4rem;
  object-fit: contain;
}

.sticky-buy__title {
  font-family: @scriptPrimaryFont;
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.25;
  color: @navy;

  max-width: 34rem;
}

.sticky-buy__actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--mobile {
    @media screen and (min-width: @sm) {
      display: none;
    }

    // Make the button full width on mobile
    width: 100%;
    .sticky-buy__buttons {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }

  &--desktop {
    display: none;
    @media screen and (min-width: @sm) {
      display: flex;
      flex-direction: row;
    }
  }
}

.sticky-buy__buttons {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.sticky-buy__price {
  font-size: 1.8rem;
  font-weight: 400;
  margin-right: 1.4rem;

  @media screen and (max-width: @sm-neg) {
    margin-bottom: 0.8rem;
    margin-right: 0;
  }

  &--hidden {
    font-size: 1.4rem;
    line-height: 1.47;
    font-family: @scriptSecondaryFont;
  }

  strike {
    color: @darker-grey;
    margin-right: 0.5rem;
    font-size: 1.5rem;

    // Hide "compare at" price on mobile
    @media screen and (max-width: @sm-neg) {
      display: none;
    }
  }
}
