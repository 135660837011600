.locations__heading {
	color: @chocolate;
	margin-bottom: 1.5rem;

	@media (min-width: @sm) {
		margin-bottom: 3.4rem;
	}
}

.locations__grid {
	display: grid;
	grid-row-gap: 6rem;

	@media (min-width: @sm) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 4rem;
	}

	@media (min-width: @lg) {
		grid-column-gap: 7.2rem;
	}
}

.locations__item {
	@media (min-width: @sm) {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.locations__img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: @slate;
	border-radius: 4px;
	margin-bottom: 2.6rem;

	&::after {
		content: "";
		display: block;
		padding-bottom: 44%;
	}


	@media (min-width: @sm) {
		margin-bottom: 4.2rem;
	}
}

.locations .svg__raffi-logo {
	width: 4.8rem;
	height: auto;
	display: block;
	margin: 0 auto 1.8rem;
}

.locations__title {
	color: @navy;
	margin-bottom: 0.5rem;

	@media (max-width: @sm-neg) {
		font-size: 2.4rem;
		letter-spacing: 0.02em;
		line-height: 1.3333;
	}
}

.locations__city {
	color: @chocolate;
	margin-bottom: 3.2rem;
}

.locations__desc {
	color: @slate;

	@media (max-width: @sm-neg) {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}

	@media (min-width: @sm) {
		padding: 0 5%;
	}
}

.locations__info {
	background: @light-sand;
	border-radius: 4px;
	padding: 0 2.5rem;
	font-size: 1.4rem;
	line-height: 2rem;
	margin-top: 3rem;

	@media (min-width: @md) {
		margin-top: 3.5rem;
		padding: 2.5rem;
		flex-wrap: nowrap;
	}
}

.locations__sub-title {
	color: @slate;
	margin-bottom: 1.6rem;
}

.locations__hours {
	padding: 2.5rem 0;

	&:not(:last-child) {
		border-bottom: 1px solid @warm-grey;
	}

	@media (min-width: @md) {
		padding: 0 2.5rem 0 0;
		flex-basis: fit-content;
		flex-shrink: 1;

		&:not(:last-child) {
			border-bottom: none;
			border-right: 1px solid @warm-grey;
		}
	}
}

.locations__contact {
	padding: 2.5rem 0;

	@media (min-width: @md) {
		flex-basis: fit-content;
		flex-shrink: 1;
		padding: 0 0 0 2.5rem;
	}
}

.locations__address {
	font-style: normal;
}

.locations__phone-link {
	color: @gold;

	&:hover {
		text-decoration: underline;
	}
	&:focus-visible {
		text-decoration: underline;
	}
}

.locations__email {
	color: @gold;
	text-decoration: underline;
	font-size: 1.4rem;
	line-height: 2.4rem;
	margin-top: 2.8rem;

	svg {
		margin-right: 1.6rem;
	}
	
	&:hover,
	&:focus {
		text-decoration: none;
	}

	@media (min-width: @sm) {
		margin-top: 3.5rem;
	}
}