.lifestyle-spread {
  position: relative;

  @media screen and (min-width: @sm) {
    height: 100vh;
  }

  &--left {
    // Move content to the left on desktop
    .lifestyle-spread__content {
      @media screen and (min-width: @sm) {
        order: -1;
      }
    }
  }

  &--right {
    // Move content to the right on desktop
    .lifestyle-spread__content {
      @media screen and (min-width: @sm) {
        order: 1;
      }
    }
  }
}

// Stacked mode tweaks
.lifestyle-spread--stack-text {
  // Make the left/right options work as expected for stacked mode
  .lifestyle-spread__wrapper {
    @media screen and (min-width: @sm) {
      flex-direction: column;
      justify-content: flex-end;

      .lifestyle-spread--left& {
        align-items: flex-start;
      }
      .lifestyle-spread--right& {
        align-items: flex-end;
      }
    }
  }

  // Force the order for stacked mode on desktop
  // (order controls vertical stacking order for the stacked layout)
  .lifestyle-spread__content {
    @media screen and (min-width: @sm) {
      order: -1;
    }
  }
}

.lifestyle-spread__image {
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: @sm) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Add overlays on desktop
  @media screen and (min-width: @sm) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #D5D5D5 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40rem;
      min-height: 50%;
      background: transparent linear-gradient(360deg, #0D0D0D83 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    }
  }
}

.lifestyle-spread__wrapper {
  position: relative;
  height: 100%;
  display: flex;
  gap: 4.5rem;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (min-width: @sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.lifestyle-spread__content {
  width: 100%;
  max-width: 57rem;

  color: @navy;
  @media screen and (min-width: @sm) {
    color: @white;
    width: 50%;
  }

  order: 1;
}

.lifestyle-spread__logo {
  height: 2.7rem;
  margin-bottom: 1.6rem;
  @media screen and (min-width: @sm) {
    height: 3rem;
    margin-bottom: 4rem;
  }
}

.lifestyle-spread__title {
  margin-bottom: 1.6rem;
  @media screen and (min-width: @sm) {
    margin-bottom: 2.2rem;
  }
}

.lifestyle-spread__info {
  .p;
}

.lifestyle-spread__btn {
  margin-top: 1.6rem;
  @media screen and (min-width: @sm) {
    margin-top: 3rem;
  }

  // Make it only do the --light style on desktop
  @media screen and (min-width: @sm) {
    color: @light-gold;
  }
}

.lifestyle-spread__products {
  width: 100%;
  max-width: 62rem;
  margin-top: 1.6rem;

  @media screen and (min-width: @sm) {
    width: 50%;
    margin-top: 0;
  }

  // Make the focus states use focus-visible
  .flickity-button,
  .flickity-page-dot {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 5px @light-sand;
    }
  }

  // Buttons for products sliders
  .flickity-prev-next-button {
    background-color: transparent;
    width: 2.4rem;
    height: 5.3rem;
    .flickity-button-icon {
      fill: @light-sand;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &.previous {
      left: -2.6rem;
    }
    &.next {
      right: -2.6rem;
    }
    @media screen and (max-width: @sm-neg) {
      display: none;
    }
  }
}

.lifestyle-spread__slider {
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.lifestyle-spread__slide {
  width: calc(100% / 3);
  padding: 0 .4rem;
  margin-bottom: 3.8rem;

  .lifestyle-spread__slider & {
    width: 30%;
    padding: 0 .4rem;
    margin: 0;

    @media screen and (min-width: @sm) {
      width: 33.33%;
    }

    &:first-child {
        @media screen and (max-width: @sm-neg) {
          padding-left: 2.4rem;
          width: calc(30% + 2rem);
        }
      }

      &:last-child {
        @media screen and (max-width: @sm-neg) {
          padding-right: 2.4rem;
          width: calc(30% + 2rem);
        }
      }
  }
}

.lifestyle-spread__slider .product-card {
  height: auto;

  .product-card__btns {
    display: none;
  }

  .product-card__details {
    position: static;
    width: 100%;
    padding: 0;
  }

  .product-card__price {
    display: none;
  }

  .product-card__title {
    font-size: 1rem;
    @media screen and (min-width: @sm) {
      color: @white;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .product-card__img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .product-card__images {
    @media screen and (max-width: @sm-neg) {
      border: 1px solid #E3E3E3;
    }
    border-radius: .4rem;
    overflow: hidden;
    margin-bottom: 1.2rem;
    padding-bottom: 100%;
    position: relative;
  }

  .product-card__badge {
    display: none;
  }
}
