// // Only show reset controls if in dirty form
// input[type="reset"],
// button[type="reset"] {
//   display: none;

//   form[data-dirty] & {
//      display: inline-block;
//   }
// }

a {
	color: inherit;
	text-decoration: none;
}

button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	cursor: pointer;
	background: transparent;
	line-height: normal;
	appearance: none;
	-webkit-appearance: none;
	border-radius: 0;
	color: inherit;
}

.btn {
	display: inline-block;
	border-width: 1px;
	border-style: solid;
	font-family: @scriptSecondaryFont;
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: none;
	padding: 0.8rem 2.4rem;
	border-radius: 4px;
	display: inline-block;
	text-align: center;
	transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;

	&--primary {
		border-color: @gold;
		color: @gold;
		background: transparent;

		svg {
			display: inline-block;
			width: 1.5rem;
			margin-left: 0.8rem;
		}

		svg path {
			stroke: @gold;
			transition: stroke 0.3s ease;
		}

		&:hover {
			background: @gold;
			color: @white;

			svg path {
				stroke: @white;
			}
		}

		&:focus-visible {
			background: @gold;
			color: @white;

			svg path {
				stroke: @white;
			}
		}
	}

	&--secondary {
		border-color: @light-gold;
		color: @light-gold;
		background: transparent;

		// Add this class when the icon is on the left of the text
		&.btn--svg-left svg {
			margin-left: 0;
			margin-right: 0.8rem;
		}

		svg {
			display: inline-block;
			margin-left: 0.8rem;
			width: 1.5rem;
		}

		svg path {
			stroke: @light-gold;
		}

		&:hover {
			background: @gold;
			border-color: @gold;
			color: @white;

			svg path {
				stroke: @white;
			}
		}

		&:focus-visible {
			background: @gold;
			border-color: @gold;
			color: @white;

			svg path {
				stroke: @white;
			}
		}
	}

	&--white {
		border-color: @white;
		color: @white;

		@media(hover: hover) and (pointer: fine) {

			&:not([disabled]):hover {
				background: @gold;
				border-color: @gold;
			}

			&:not([disabled]):focus-visible {
				background: @gold;
				border-color: @gold;
			}
		}
	}

	&--sm {
		font-weight: normal;
		font-size: 1.3rem;
		padding: 0.5rem 1.6rem;
	}

	&--lg {
		@media screen and (min-width: @sm) {
			font-size: 2rem;
			padding: 1.2rem 2.4rem;
			min-width: 15.2rem;
		}
	}

	// Add a white arrow after the button text
	&--arrow-after {
		&::after {
			content: '';
			display: inline-block;
			width: 1.6rem;
			height: 1.2rem;
			margin-left: 1.4rem;
			background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.432 13.099" fill="none" stroke="%23fff" stroke-width="1.5"%3E%3Cpath d="M0 6.514h16.37"/%3E%3Cpath d="m9.831.559 6.5 5.843-6.5 6.155"/%3E%3C/svg%3E');
		}
	}

	&--submit-lg {
		font-weight: 700;
		font-size: 1.9rem;
		padding: 0.95rem 3.7rem;
	}

	&--solid-gold {
		background: @gold;
		border-color: @gold;
		color: @white;

		svg {
			display: inline-block;
			width: 1.5rem;
			margin-left: 0.8rem;
		}

		svg path {
			stroke: @white;
		}

		&:hover {
			background: #80521F;
			border-color: #80521F;
		}

		&:focus-visible {
			background: #80521F;
			border-color: #80521F;
		}
	}

	&--solid-green {
		background: @green;
		border-color: @green;
		border-radius: 3.5rem;
		color: @white;
		font-family: 'Helvetica', sans-serif;

		svg {
			display: inline-block;
			width: 1.5rem;
			margin-left: 0.8rem;
		}

		svg path {
			stroke: @white;
		}

		&:hover {
			background: @light-sand;
			border-color: @green;
			color: @green;
		}
		&:focus-visible {
			background: @light-sand;
			border-color: @green;
			color: @green;
		}
	}

	&--solid-navy {
		background: @navy;
		color: @white;

		@media(hover: hover) and (pointer: fine) {

			&:not([disabled]):hover {
				background: @white;
				color: @navy;
			}
			&:not([disabled]):focus-visible {
				background: @white;
				color: @navy;
			}
		}
	}

	// Buttons meant to keep spacing etc with the single product notices
	&--single-product-notice {
		padding: 1.2rem 1.6rem;
		font-size: 1.2rem;
		display: flex;
  	line-height: 1.6rem;
		// justify-content: flex-start !important;

		@media screen and (min-width: @sm) {
			font-size: 1.3rem;
		}

		& + &,
		& + .single-product__notice {
			margin-top: .8rem;
		}
	}

	// One-off for the drop-a-hint button
	&--drop-a-hint {
		border: 1px solid @chocolate;
		border-radius: 0;

		color: @chocolate;
		font-family: @primaryFont;
		font-size: 1.4rem;
		font-weight: 500;

		display: flex;
    align-items: center;
    justify-content: center;
		margin: 2.4rem 0; // Spacing from .single-product__buttons
		padding: 0 1.2rem;

		// The arrow
		.arrow-icon {
			transition: all 0.3s ease;
			margin-left: 0.8rem;

			svg {
				display: inline-block;
				width: 1.5rem;
				stroke: @chocolate !important;
			}
		}

		// The icon container
		.drop-a-hint-icon {
			margin-right: 1.6rem;
			margin-bottom: 0.4rem;
			margin-top: -0.3rem;

			svg {
				width: 2.1rem;
				margin-left: 0;
			}
			.heart {
				transition: all 0.3s ease;
				transform: translateY(0);
			}
			.heart-fill {
				transition: all 0.3s ease;
				fill: @light-blue;
			}
		}

		&:hover, &:focus-visible {
			.heart {
				transform: translateY(-450px);
			}
			.heart-fill {
				fill: #ff7b7b;
			}
			.arrow-icon {
				margin-left: 1.6rem;
			}
		}
	}

	// TODO: get proper styles for disabled state
	&[disabled] {
		opacity: .5;
	}
}

.link-arrow {
	color: @chocolate;
	font-family: @scriptSecondaryFont;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 2.8rem;
	letter-spacing: normal;
	line-height: normal;
	display: inline-flex;
	align-items: center;
	// text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
	// text-decoration-color: transparent;
	// transition: text-decoration .2s ease;

	& svg {
		display: inline-block;
		width: 1.2rem;
		margin-left: .5rem;
		stroke: currentColor;
		pointer-events: none;
	}

	&:focus-visible {
		// text-decoration-color: currentColor;
		text-decoration: underline;
	}
	&:hover {
		// text-decoration-color: currentColor;
		text-decoration: underline;
	}

	&--sm {
		@media screen and (max-width: @sm-neg) {
			font-size: 1.2rem;
		}
	}

	&--lg {
		@media screen and (min-width: @sm) {
			font-size: 2rem;
		}

		svg {
			@media screen and (min-width: @sm) {
				width: 1.6rem;
				margin-left: .8rem;
			}
		}
	}

	&--light {
		color: @light-gold;
	}
}

// The bag "add to cart" icon on the instantsearch grids
.bag-btn {
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 50%;
	background: @sand url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.775 25.186'%3E%3Cg fill='none' stroke='%2365450A' stroke-miterlimit='10' stroke-width='1.5'%3E%3Cpath d='m.921 9.907 3.014 14.526h16.9l3.014-14.529Z'/%3E%3Cpath d='M15.988 12.38C15.614 5.719 14.143.75 12.387.75s-3.225 4.969-3.6 11.63'/%3E%3C/g%3E%3C/svg%3E") center ~"/" 1.4rem auto no-repeat;
	position: relative;

	&::after {
		content: '';
		display: block;
		width: 1.1rem;
		height: 1.1rem;
		background: @chocolate url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' xml:space='preserve' fill='%23f4f0ea'%3E%3Cpath d='M32 13.4H18.6V0h-5.2v13.4H0v5.2h13.4V32h5.2V18.6H32z'/%3E%3C/svg%3E") center ~"/" 0.5rem auto no-repeat;
		border-radius: 50%;
		position: absolute;
		top: -0.4rem;
		right: -0.2rem;
		opacity: 1;
		transition: opacity .2s ease, background-color .2s ease;
	}

	@media(hover: hover) and (pointer: fine) {

		&::after {
			opacity: 0;
		}

		&:not([disabled]):hover::after {
			opacity: 1;
		}

		&:not([disabled]):focus-visible::after {
			opacity: 1;
		}
	}

	&.processing {
		&::after {
			opacity: 0;
			// background: @light-sand;
		}
	}

	&.success {
		&::after {
			opacity: 1;
			background: #127749 url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16.116' height='13.937' viewBox='0 0 16.116 13.937' %3E%3Cpath id='Path_874' d='M-7116.7-6094.073l4.641,5.656,9.939-11.729' transform='translate(7117.474 6100.792)' fill='none' stroke='%23f4f0ea' stroke-width='2' /%3E%3C/svg%3E") center ~"/" 0.5rem auto no-repeat;
		}
	}
}
