/* ==========================================================================
   Cookies Banner
   ========================================================================== */

.cookies-banner {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  background: @black;
  padding: 3.6rem 0;
  color: @white;
}

.cookies-banner__content {
  @media screen and (min-width: @sm) {
    display: flex;
    align-items: flex-start;
  }
}

.cookies-banner__text {
  font-size: 1.6rem;
  line-height: 1.4;
  margin: 0;

  a {
    text-decoration: underline;
    opacity: 1;
    transition: opacity .2s ease;

    @media (hover: hover) {
      &:hover,
      &:focus-visible {
        opacity: .7;
      }
    }
  }
}

.cookies-banner__link {
  margin-top: 2rem;

  @media screen and (min-width: @sm) {
    margin-top: 0;
    margin-left: 5.5rem;
  }
}

body #CybotCookiebotDialog {
  @media screen and (max-width: 1279px) {
    transform: translateX(-50%);
    top: auto;
    bottom: 8px;
  }
}

#CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
  font-size: 12px !important;
}

// Hide "powered by cookiebot"
#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

// Mobile tweaks
@media screen and (max-width: (@sm - 1px)) {
  // Hide "show details" button (it's the same thing as the "customize" button)
  #CybotCookiebotDialogBodyEdgeMoreDetails {
    display: none !important;
  }

  // Tweak padding
  #CybotCookiebotDialogFooter {
    padding: 1em 1em 0.5em !important;

    // Make buttons horizontal
    #CybotCookiebotDialogBodyButtonsWrapper {
      flex-direction: row !important;
      gap: 6px;
    }
  }

  // Hide empty header bar
  #CybotCookiebotDialogHeader {
    display: none !important;
  }

  // Tweak padding
  #CybotCookiebotDialogBodyContent {
    padding: 0.8em .625em 0.625em !important;
  }
}
