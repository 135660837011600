/* Tudor Hero */
.hero--reg .tudor__hero-content {
	@media (max-width: @sm-neg) {
		padding-bottom: 0;
	}
}

.tudor__1-title {

	@media (max-width: @sm-neg) {
		font-size: 1.9rem;
		letter-spacing: -0.03rem;
		line-height: 2.8rem;
	}
}

.tudor__hero-btn {
	margin-top: 16.5px;

	&--desktop {
		display: none;
	}

	@media (min-width: @sm) {
		&--mobile {
			display: none;
		}

		&--desktop {
			display: inline-block;
		}
	}
}

/* Tudor 2 Collections slider */
.tudor__2-text {
	@media (max-width: @sm-neg) {
		margin-top: 0 !important;
	}

	@media (min-width: @sm) {
		padding-bottom: 4.5rem;
	}
}

.tudor__checkerboard-slider {

	.flickity-page-dots {
		bottom: var(--modulePadding);
		transform: translate(0, 50%);

		@media (min-width: @sm) {
			bottom: calc(var(--modulePadding) / 2);
			justify-content: flex-start;
			padding-left: var(--pagePadding);
			max-width: 50%;
		}

		@media (min-width: @md) {
			bottom: 6vw;
		}

		@media (min-width: @lg) {
			bottom: 8.7vw;
		}

		@media (min-width: 1295px) {
			padding-left: calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
		}
	}

	.flickity-page-dot {
		background: @gold;
		height: 8px;
		width: 8px;
		opacity: 0.3;
		margin: 4px;

		&.is-selected {
			opacity: 1;
		}

		&:focus {
			box-shadow: none;
		}

		&:focus-visible {
			box-shadow: 0 0 0 1px @black;
		}
	}
}

.tudor__checkerboard-img {
	@media (max-width: @sm-neg) {
		height: 77.2%;
		top: auto;
		bottom: 0;
	}
}

.tudor__checkerboard-watch {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 1;
	transform: translate(-50%, 0);
	height: 92%;
	width: auto;

	@media (min-width: @sm) {
		height: 267px;
		left: 0;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	@media (min-width: @md) {
		height: 85.4%;
	}
}

.tudor__checkerboard-img-wrapper {
	@media (max-width: @sm-neg) {
		background-color: transparent;
		&::after {
			padding-bottom: 101.9%;
		}
	}
}

.tudor__checkerboard-content {
	@media (max-width: @sm-neg) {
		padding-bottom: calc(var(--modulePadding) * 2);
	}

	@media (min-width: @sm) {
		padding-right: 10vw;

		.checkerboard__content-width {
			margin-left: 0;
			padding: 0;
		}
	}

	@media (min-width: @md) {
		padding: 10vw 12vw 10vw var(--pagePadding);
	}

	@media (min-width: @lg) {
		padding: 10vw 15vw 10vw var(--pagePadding);
	}

	@media (min-width: 1295px) {
		padding: 10vw 15vw 10vw calc( (100vw - (var(--pageWidth) + (var(--pagePadding) * 2))) / 2 + var(--pagePadding));
	}
}

/* Tudor Checkerboard Dark */
.tudor__4-text {
	@media (min-width: @sm) {
		padding-bottom: 4.5rem;
	}
}

/* Tudor Video */
.tudor__text-content {
	text-align: center;

	@media (min-width: @sm) {
		column-count: 1 !important;
	}

	@media (min-width: @md) {
		grid-column: 3 / 11;
	}
}

// The video embed customizations are done in the lite-youtube-embed file.
.tudor__video-wrapper {
	background: @black;
	position: relative;
	width: 100%;
}

/* Tudor Checkerboard Light */
.tudor__7-text {
	@media (min-width: @sm) {
		padding-bottom: 4.5rem;
	}
}

/* ====================
Tudor royalmount landing page
==================== */

// TODO: Might be a better idea to move this to a separate file
// and prune the landing page so it's only got exactly what it needs...

@tudor-title-font: 'ff-meta-headline-web-pro', sans-serif;

// TODO: These margins/breakpoints depend on the height/aspect ratio... ugh...
@tudorlp-desktop-margin: 43vw;
@tudorlp-breakpoint: 1100px;
@tudorlp-ratio: 1441/806;
.tudorlp {
	background-color: #E0E0E0;
	color: #2C2C2C;
	font-family: 'indivisible-variable', sans-serif;
	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		background-color: @black;
		color: @white;
	}
}

.tudorlp__content {
	position: relative;
	z-index: 1;

	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		padding-top: 8rem;
		margin-left: @tudorlp-desktop-margin;
	}

	& > .page-width {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.tudorlp__background {
	z-index: -1;
	img {
		@media screen and (min-width: @tudorlp-breakpoint)
			and (orientation: landscape) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top left;
			aspect-ratio: 1441/806;
		}
	}
}

.tudorlp__logos {
	display: flex;
	align-items: center;
	gap: 1rem;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		align-items: baseline;
	}

	&--desktop {
		display: none;
		@media screen and (min-width: @tudorlp-breakpoint)
			and (orientation: landscape) {
			display: flex;
			align-items: center;
		}

		img, svg {
			width: 50%;
		}
	}

	&--mobile {
		position: absolute;
		top: 50%;
		left: 67%;
		transform: translate(-50%, -50%);
		width: 40%;
		max-width: 30rem;

		img, svg {
			width: 100%;
		}

		@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
			display: none;
		}
	}

	img { flex-basis: 60%; }
	svg { flex-basis: 40%; }
}

.tudorlp__title {
	font-size: 3rem;
	line-height: 1.26;
	font-weight: 900;
	text-transform: uppercase;
	font-family: @tudor-title-font;
	margin-bottom: 1.3rem;

	&--first {
		margin-top: 4.6rem;
	}

	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		font-size: 3.8rem;
	}
}

.tudorlp__p {
	font-size: 1.7rem;
	line-height: 1.53;
	font-weight: 500;
	margin-bottom: 1.5rem;
	text-align: center;
	max-width: 48rem;

	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		margin-bottom: 2.5rem;
	}
}

.tudorlp__button {
	.btn-reset();
	color: @tudor-red;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.4rem;
	line-height: 1.2;
	letter-spacing: 0.07rem;

	background-color: @white;
	border: 1px solid @tudor-red;
	border-radius: 5rem;
	padding: 1.1rem 1.8rem 0.6rem 1.8rem;

	margin: 2rem 0 3rem 0;
}

.tudorlp__footer {
	position: relative;
	z-index: 1;
	margin-bottom: 5.2rem;
	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		margin-left: @tudorlp-desktop-margin;
		margin-bottom: 0;
	}

	& > .page-width {
		display: flex;
		gap: 2.4rem;
		flex-direction: column;
		font-size: 1.1rem;
		line-height: 1.33;
		justify-content: center;
		align-items: center;

		@media screen and (min-width: @tudorlp-breakpoint)
			and (orientation: landscape) {
			position: relative;
			font-size: 1.2rem;
			line-height: 1.67;
			padding-bottom: 8rem;
		}
	}
}

.tudorlp__copyright {
	display: flex;
	flex-direction: column;
	span {
		text-align: center;
	}
}

.tudorlp__policies {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
			gap: 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		gap: 1rem;
		@media screen and (min-width: @tudorlp-breakpoint)
			and (orientation: landscape) {
			gap: 2.5rem;
		}
	}
}

.tudorlp__policies-link {
	text-decoration: underline;
}

.tudorlp-form__form {
	@media (min-width: @sm) {
		grid-column: 2 / 12;
	}

	@media (min-width: @lg) {
		grid-column: 3 / 11;
	}
}

.tudorlp-form__wrapper {
	@media (min-width: @sm) {
		padding-left: calc(var(--pagePadding) - var(--pagePadding-sm));
		padding-right: calc(var(--pagePadding) - var(--pagePadding-sm));
		max-width: calc(var(--pageWidth) + ((var(--pagePadding) - var(--pagePadding-sm)) * 2));
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: 1.6rem;
	}
}

.modal[data-modal="tudor-landing"] {
	.modal__content {
		background-color: @white;
		color: @black;
	}

	.form__title {
		font-family: @tudor-title-font;
		font-size: 3.6rem;
		line-height: 1.25;
		font-weight: 900;
		letter-spacing: 0;
		color: #2C2C2C;
		text-transform: uppercase;

		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.form__input {
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			color: @black;
			-webkit-text-fill-color: @black;
		}

		input:not([type="checkbox"]):not([type="radio"]):not(.numInput), textarea, .input__select {
			background-color: fade(#8D8D8D, 10%);
			border-bottom: 1px solid @tudor-red;
			color: @black;

			&::-webkit-input-placeholder { /* Edge */
				color: @black;
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				color: @black;
			}

			&::placeholder {
				color: @black;
			}
		}

		// TODO: I tried copying the CSS for checkboxes and making it work with radio buttons but it's not really working great...
		// It seems to get stuck on the "checked" state...
		input[type="checkbox"],
		input[type="radio"] {
			appearance: none;
			-webkit-appearance: none;
			height: 2.4rem;
			width: 2.4rem;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;

			background-color: fade(#8D8D8D, 10%);
			background-repeat: no-repeat;
			background-size: 1.6116rem 1.3937rem;
			background-position: center;
			&:checked {
				background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716.116%27 height=%2713.937%27 viewBox=%270 0 16.116 13.937%27%3E%3Cpath id=%27Path_874%27 data-name=%27Path 874%27 d=%27M-7116.7-6094.073l4.641,5.656,9.939-11.729%27 transform=%27translate%287117.474 6100.792%29%27 fill=%27none%27 stroke=%27%23000%27 stroke-width=%272%27/%3E%3C/svg%3E");
			}

			&+label {
				padding-left: 3.7rem;
				margin: 0;

				& a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}

					&:focus-visible {
						text-decoration: none;
					}
				}
			}

			&~.pristine-error {
				padding-left: 3.7rem;
			}
		}
	}

	.tudorlp-form__field-label {
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 600;
		letter-spacing: 0;
		color: #2C2C2C;
		margin-top: 1rem;
	}

	.pristine-error {
		color: @tudor-red;
	}

	button[type="submit"]:disabled {
		background-color: fade(#8D8D8D, 50%);
		border-color: fade(#8D8D8D, 50%);
		color: @black;
	}
}

svg.Tudor_logo {
	.Tudor_text {
		fill: @white;
	}
}

svg.Raffi_Logo_SVG path {
	fill: @black;
	@media screen and (min-width: @tudorlp-breakpoint)
		and (orientation: landscape) {
		fill: @white;
	}
}
