/**
 * Rolex breakpoints:
 * small: 0-767
 * medium: 768-1024
 * large: 1025+
 */

/* === Vars === */

@rlxGreen: #127749;
@rlxDarkGreen: #006039;
@rlxLightGreen: #61BD93;
@rlxGrey: #D4D4D4;
@rlxDarkGrey: #767676;
@rlxBlack: #212121;
@rlxWhite: #FFF;
@rlxBrown: #452C1E;
@rlxBeige: #F4EFEA;
@rlxLightBeige: #F9F7F4;
@rlxError: #be0100;



// TO BE REMOVED
@rolexDarkGrey: #767676;
@rolexLightGrey: #F8F8F8;
@rolexNavGrey: #ECECEC;

@tablet: 768px;
@desktop: 1024px;
@rlxMd: 1440px;
@rlxMax: 1920px;
@rlxNavBreakpoint: 1630px;

/* === Typography === */

@rlxFont: 'Helvetica', 'Arial', sans-serif;

.rolex {
    font-family: @rlxFont;
    color: @rlxBlack;
    position: relative;

    .mobile-padding {
        padding: 0 7%;

        @media screen and (min-width: @tablet) {
            padding: 0 8%;
        }
    }

    .row--margin {
        margin-right: -0.6rem;
        margin-left: -0.6rem;

        & > [class^="col-"] {
            padding: 0 0.6rem;
        }

        @media screen and (min-width: @tablet) {
            margin-right: -.8rem;
            margin-left: -.8rem;

            & > [class^="col-"] {
                padding: 0 .8rem;
            }
        }
    }
}

.rlx-container {
    padding: 0 7%;
    width: 100%;

    @media screen and (min-width: @tablet) {
        padding: 0 8%;
    }
}

.rlx-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: .6rem;

    @media screen and (min-width: @tablet) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-column-gap: .8rem;
    }

    &--auto {
        grid-template-columns: 1fr;
        row-gap: 5rem;
        @media screen and (min-width: @tablet) {
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        }
    }
}

.rlx-section {
    padding: 7vh 0;
    padding: max(7vh, 6rem) 0;

    @media screen and (min-width: @tablet){
        padding: 10vh 0;
        padding: max(10vh, 9rem) 0;
    }

    // @media screen and (min-width: @tablet) and (min-height: 900px) {
    //     padding: 9rem 0;
    // }

    &--white {
        background: @rlxWhite;

        & + & {
            padding-top: 0;
        }
    }

    &--light-beige {
        background: @rlxLightBeige;

        &+& {
            padding-top: 0;
        }
    }

    &--beige {
        background: @rlxBeige;
        &+& {
            padding-top: 0;
        }
    }
}

.rlx-headline {
    font-family: @rlxFont;

    &--70 {
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 1.1;

        @media screen and (min-width: @lg) {
            font-size: 4.6rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 5.6rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 7rem;
        }
    }

    &--50 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.2;

        @media screen and (min-width: @lg) {
            font-size: 3.6rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 4.2rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 5rem;
        }
    }

    &--36 {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 1.2;

        @media screen and (min-width: @lg) {
            font-size: 2.8rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 3.2rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 3.6rem;
        }
    }

    &--30 {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.2;

        @media screen and (min-width: @lg) {
            font-size: 2.3rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 2.6rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 3rem;
        }
    }

    &--26 {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.2;

        @media screen and (min-width: @lg) {
            font-size: 2.3rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 2.5rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 2.6rem;
        }
    }

    &--24 {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.2;

        @media screen and (min-width: @lg) {
            font-size: 2rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 2.2rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 2.4rem;
        }
    }
}

.rlx-copy {

}

.rlx-body {
    font-family: @rlxFont;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.6;
    color: inherit;
    margin: 0;

    @media screen and (min-width: @desktop) {
        font-size: 1.9rem;
    }

    @media screen and (min-width: @rlxMax) {
        font-size: 2rem;
    }

    &--24 {
        font-size: 1.8rem;

        @media screen and (min-width: @desktop) {
            font-size: 2rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 2.2rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 2.4rem;
        }
    }

    &--20 {
        @media screen and (min-width: @desktop) {
            font-size: 1.9rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 2rem;
        }
    }

    &--bold {
        font-weight: 700;
    }

    & + &,
    p + p {
        margin-top: 2rem;
    }

    strong {
        font-weight: 700;
    }

    blockquote {
        margin: 7vh 0;
        margin: max(7vh, 6rem) 0;
        font-size: 3rem;
        font-family: 'Georgia', serif;
        font-weight: 400;
        // line-height: 1.6;
        // The docs say to use 1.6 but it is very clearly wrong, visually adjusted to match at 1.2
        line-height: 1.2;

        @media screen and (min-width: @tablet) {
            margin: 10vh 0;
            margin: max(10vh, 9rem) 0;
            font-size: 3.2rem;
        }

        @media screen and (min-width: @rlxMd) {
            font-size: 4rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 5rem;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        cite {
            display: block;
            margin-top: 1rem;
            font-family: @rlxFont;
            font-weight: 300;
            font-size: 1.8rem;
            font-style: normal;

            @media screen and (min-width: @tablet) {
                margin-top: 2rem;
            }

            @media screen and (min-width: @desktop) {
                font-size: 1.9rem;
            }

            @media screen and (min-width: @rlxMax) {
                font-size: 2rem;
            }
        }
    }
}

.rlx-legend {
    font-size: 1rem;
    line-height: 1.1;

    @media screen and (min-width: @tablet) {
        margin-top: 1.1rem;
    }

    @media screen and (min-width: @desktop) {
        font-size: 1.3rem;
    }

    @media screen and (min-width: @rlxMax) {
        font-size: 1.4rem;
    }

    &--16 {
        font-size: 1.2rem;

        @media screen and (min-width: @tablet) {
            margin-top: 1.3rem;
        }

        @media screen and (min-width: @desktop) {
            font-size: 1.5rem;
        }

        @media screen and (min-width: @rlxMax) {
            font-size: 1.6rem;
        }
    }

    &--bold {
        font-weight: 700;
    }
}

.rlx-fixed--14 {
    font-weight: 700;
	font-size: 1.4rem;
	line-height: 1.1;
}

.rlx-fixed--16 {
    font-weight: 700;
	font-size: 1.6rem;
	line-height: 1.1;
}

.rlx-fixed--22 {
	font-size: 2.2rem;
	line-height: 1.1;
}

.rlx-legend--14 {
    font-size: 1rem;
    line-height: 1.1;

    @media screen and (min-width: @tablet) {
        font-size: 1.4rem;
    }
}

.rlx-legend--16 {
    font-size: 1.2rem;
    line-height: 1.1;

    @media screen and (min-width: @tablet) {
        margin-top: 1.3rem;
    }

    @media screen and (min-width: @desktop) {
        font-size: 1.5rem;
    }

    @media screen and (min-width: @rlxMax) {
        font-size: 1.6rem;
    }
}



.rlx-quote {
  font-family: 'Georgia', serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1.6;

  @media screen and (min-width: @tablet) {
    font-size: 3.2rem;
  }

  @media screen and (min-width: @rlxMd) {
      font-size: 4rem;
  }

  @media screen and (min-width: @rlxMax) {
      font-size: 5rem;
  }
}

/* === Buttons === */

.rlx-btn {
    .btn-reset();
    font-family: @rlxFont;
    font-weight: 700;

    &--primary {
      background: @rlxGreen;
      color: @rlxWhite;
      font-size: 1.4rem;
      padding: 1.4rem 3rem;
      border-radius: 2.5rem;
      transition: background .2s ease, color .2s ease, border .2s ease;

      @media(hover: hover) and (pointer: fine) {
        &:hover {
          background: @rlxDarkGreen;
        }

        &:focus-visible {
          background: @rlxDarkGreen;
        }
      }
    }

    &--arrow {
      display: inline-flex;
      align-items: center;

      &:after {
        content: '';
        display: block;
        margin-left: .8rem;
        width: 1.2rem;
        height: 1.2rem;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 15'%3E%3Cpath d='M9 7.5 7.7 8.9 1.6 15 .1 13.5l6.1-6.1-6.2-6L1.5 0l6.1 6.1L9 7.5Z' style='fill:%23fff;stroke-width:0'/%3E%3C/svg%3E") center / contain no-repeat;
        transition: background .2s ease;
      }
    }

    &--secondary {
      color: @rlxGreen;
      font-size: 1.4rem;
      transition: color .2s ease;

      &.rlx-btn--arrow {
        &:after {
        margin-left: .6rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 15'%3E%3Cpath d='M9 7.5 7.7 8.9 1.6 15 .1 13.5l6.1-6.1-6.2-6L1.5 0l6.1 6.1L9 7.5Z' style='fill:%23127749;stroke-width:0'/%3E%3C/svg%3E");
        }
      }

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            color: @rlxBlack;
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 15'%3E%3Cpath d='M9 7.5 7.7 8.9 1.6 15 .1 13.5l6.1-6.1-6.2-6L1.5 0l6.1 6.1L9 7.5Z' style='fill:%23212121;stroke-width:0'/%3E%3C/svg%3E");
            }
        }

        &:focus-visible {
            color: @rlxBlack;
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 15'%3E%3Cpath d='M9 7.5 7.7 8.9 1.6 15 .1 13.5l6.1-6.1-6.2-6L1.5 0l6.1 6.1L9 7.5Z' style='fill:%23212121;stroke-width:0'/%3E%3C/svg%3E");
            }
        }
    }
    }

    &--download {
        display: inline-flex;
        align-items: center;

        &:before {
            content: '';
            display: block;
            margin-right: .8rem;
            width: 1.2rem;
            height: 1.2rem;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 15 15' viewBox='0 0 15 15' fill='%23127749'%3E%3Cpath d='M15 10v5H0v-5h2v3h11v-3h2zm-9.5-.5 2 2 2-2 2-2h-3V0h-2v7.5h-3l2 2z'/%3E%3C/svg%3E") center / contain no-repeat;
            transition: background-image .2s ease;
        }

        @media(hover: hover) and (pointer: fine) {
            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 15 15' viewBox='0 0 15 15' fill='%23212121'%3E%3Cpath d='M15 10v5H0v-5h2v3h11v-3h2zm-9.5-.5 2 2 2-2 2-2h-3V0h-2v7.5h-3l2 2z'/%3E%3C/svg%3E");
                }
            }

            &:focus-visible {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 15 15' viewBox='0 0 15 15' fill='%23212121'%3E%3Cpath d='M15 10v5H0v-5h2v3h11v-3h2zm-9.5-.5 2 2 2-2 2-2h-3V0h-2v7.5h-3l2 2z'/%3E%3C/svg%3E");
                }
            }
        }
    }

    &--tertiary {
        color: @rlxBrown;
        display: flex;
        align-items: center;
        transition: color .2s ease;

        .rlx-btn__icon {
            width: 3.6rem;
            height: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: @rlxLightBeige;
        }

        .rlx-btn__text {
            font-size: 1.2rem;
            font-weight: 700;
            margin-left: 0.8rem;
        }

        svg {
            fill: currentColor;
            height: 1rem;
            transition: fill .2s ease;
        }

        @media(hover: hover) and (pointer: fine) {
            &:hover {
              color: @rlxGreen;
            }

            &:focus-visible {
                color: @rlxGreen;
            }
        }
    }
}

.rlx-play-btn {
    .btn-reset();
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @rlxWhite;

    svg {
        display: block;
        width: 1.4rem;
        height: 1.4rem;
        fill: @rlxBrown;
        transition: fill .2s ease;
    }

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            svg {
                fill: @rlxGreen;
            }
        }

        &:focus-visible {
            svg {
                fill: @rlxGreen;
            }
        }
    }
}

.rlx-icon-btn {
    .btn-reset();
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @rlxGrey;
    color: @rlxBlack;
    transition: background .2s ease;

    svg {
        display: block;
        width: 1.2rem;
        height: auto;
        fill: currentColor;
        transition: fill .2s ease;
    }

    @media(hover: hover) and (pointer: fine) {
        &:hover,
        &:focus-visible {
            // background: @rlxGreen;

            // svg {
            //     fill: @rlxWhite;
            // }
        }
    }
}

.rlx-slider-btn {
    .btn-reset();
    width: 4.4rem;
    transition: opacity .2s ease;

    .rlx-icon {
        display: block;
    }

    .rlx-icon__bg {
        fill: fade(@rlxGrey, 30%);
        transition: fill .2s ease;
    }

    .rlx-icon__arrow {
        stroke: @rlxBrown;
        transition: stroke .2s ease;
    }

    @media(hover: hover) and (pointer: fine) {

        &:hover {
            .rlx-icon__bg {
                fill: fade(@rlxDarkGrey, 30%);
            }
        }

        &:focus-visible {
            .rlx-icon__bg {
                fill: fade(@rlxDarkGrey, 30%);
            }
        }
    }

    &--dark {
        .rlx-icon__bg {
            fill: fade(@rlxDarkGrey, 30%);
        }

        .rlx-icon__arrow {
            stroke: @rlxWhite;
        }

        @media(hover: hover) and (pointer: fine) {

            &:hover {
                .rlx-icon__bg {
                    fill: fade(@rlxBlack, 30%);
                }
            }

            &:focus-visible {
                .rlx-icon__bg {
                    fill: fade(@rlxBlack, 30%);
                }
            }
        }
    }

    &[disabled] {
        opacity: 0;
    }

}

.rlx-quote {
    blockquote {
        .rlx-copy;
        margin: 0;
    }

    figcaption {
        .rlx-copy;
        margin-top: 3rem;
    }
}

.rolex-blog__logo {
    width: 12.5rem;
    margin: 1.6rem auto;
    display: block;
}
