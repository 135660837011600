@off-text-colour: #7B7B7B;
@toggle-height: 4.4rem;
@icon-size: 2rem;
@pill-gap: 0.4rem;

// The background container
.radio-switcher {
  height: @toggle-height;
  background-color: @light-grey;
  border-radius: 0.8rem;
  display: inline-flex;
  gap: @pill-gap;
  justify-content: center;
  align-items: center;
  padding: @pill-gap;
}

// Hidden input
.radio-switcher__input {
  // TODO: make sr-only?
  display: none;
}

// The options themselves
.radio-switcher__label {
  height: 100%;
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1.1rem 1.1rem 0.9rem;
  color: #7B7B7B;

  // Checked state
  .radio-switcher__input:checked + & {
    background-color: @white;
    color: @slate;
    box-shadow: 0 0.1rem 0.3rem #00000029;
  }

  // Optional icon inside the label
  svg, img {
    height: @icon-size;
    width: @icon-size;
    object-fit: contain;
  }
}
