.drop-a-hint__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 6rem;

  @media screen and (min-width: @sm) {
    flex-direction: row-reverse;
    padding-top: 3.6rem;
    gap: 7.6rem;
  }
}

.drop-a-hint__form {
  width: 100%;
  @media screen and (min-width: @sm) {
    width: 50%;
  }
}

.drop-a-hint__note-wrapper {
  width: 100%;
  @media screen and (min-width: @sm) {
    width: 50%;
  }
}

.drop-a-hint__note {
  background-color: @white;
  border-radius: 0.4rem;
  padding: 2rem 1.6rem 3rem;

  text-align: center;
  color: @navy;
  letter-spacing: 0;
}

.hint-note__heading {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.28;
}

.hint-note__body {
  font-size: 1.8rem;
  line-height: 1.78;

  display: block;
  margin: 1rem 0;
}

.hint-note__footer {
  font-family: @scriptPrimaryFont;
  font-size: 1.4rem;
  font-style: italic;
  line-height: 2.14;
}

.js-recipient-name,
.js-your-name {
  // Add blank line if empty
  &:empty::after {
    content: '______________';
  }
}

.drop-a-hint__img {
  width: 40rem;
  max-width: 100%;
  margin: 0 auto;
}
