/* Algolia autocomplete variables */
:root {
	// This is @gold
	--aa-icon-color-rgb: 154, 107, 54;
	--aa-input-border-color-rgb: 15, 76, 192;
	--aa-input-border-color-alpha: 0;
	--aa-background-color-alpha: 0;
	--aa-panel-border-color-alpha: 0;

	--aa-selected-color-rgb: 250, 248, 245;
	--aa-selected-color-alpha: 1;
	--aa-panel-shadow: none;
	--aa-panel-max-height: 29rem;
}

#autocomplete {
	position: relative;
}

#autocomplete-panel {
	position: relative;
	height: 30rem;
}

.aa-Panel {
  z-index: 10;
	// Make it line up with the search input cause idk why Algolia sets its position weirdly
	top: 0 !important;
}

.aa-SubmitIcon {
	// Overrides --aa-primary-color-rgb since that affects the search input border as well
	color: @gold !important;
}

// Add inset underline when search input is not focused
.aa-Autocomplete:not(:focus-within)::after {
	@inset: 1.8rem;
	content: "";
	display: block;
	width: ~"calc(100% - @{inset} * 2)";
	height: 1px;
	background-color: @navy;
	position: relative;
	bottom: 0;
	left: @inset;
}

// Replace the focused border
.aa-Form:focus-within {
	border: 1px solid #0F4CC0;
	box-shadow: none;
	outline: none;
}
